export default {
  description: [
    { required: true, message: 'O campo de descrição é obrigatório', trigger: 'blur' },
    { min: 10, max: 190, message: 'O campo descrição deve ter entre 10 e 190 caracteres.', trigger: 'blur' }
  ],
  module_id: [
    { required: true, message: 'É obrigatório selecionar um módulo', trigger: 'blur' },
  ],
  content: [
    { required: true, message: 'O campo enunciado é obrigatório', trigger: 'blur' },
  ],
}
