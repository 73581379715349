<template>
  <el-form
    ref="form"
    v-model="form"
    :rules="rules"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :md="8">
        <el-form-item
          label="Escolas"
          prop="school_id"
        >
          <el-select
            v-model="form.school_id"
            placeholder="Escola"
            clearable
            multiple
            filterable 
            class="w-100"
          >
            <el-option
              v-for="school in schools.filter(s=>s.count_active_students > 0)"
              :key="school.id"
              :label="`${school.name} (${school.count_active_students} alunos)`"
              :value="school.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Séries"
          prop="grade_id"
        >
          <el-select
            v-model="form.grade_id"
            placeholder="Série"
            clearable
            multiple
            class="w-100"
          >
            <el-option
              v-for="grade in grades"
              :key="grade.id"
              :label="grade.name"
              :value="grade.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Ciclo"
          prop="cycle_config_id"
        >
          <el-select
            v-model="form.cycle_config_id"
            placeholder="Ciclo"
            clearable
            class="w-100"
          >
            <el-option
              v-for="cycle in cycle_configs.filter(c =>c.year >= 2023)"
              :key="cycle.id"
              :label="`${cycle.cycle_name} (${cycle.year})`"
              :value="cycle.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
     form: {
      cycle_config_id: '',
      grade_id: [],
      school_id: [],
     },
     rules: {
          cycle_config_id: [
            { required: true, message: 'Selecione um ciclo', trigger: 'blur' },
          ],
          grade_id: [
            { type: 'array', required: true, message: 'Selecione ao menos uma série', trigger: 'blur' },
          ],
          school_id: [
            { type: 'array', required: true, message: 'Selecione ao menos uma escola', trigger: 'blur' },
          ],
        }
      }
    },
  computed: {
    ...mapGetters(['student','students','schools','cycle_configs','grades']),
  },
  watch: {
    form: {
      handler(){
        if (this.form.cycle_config_id && this.form.grade_id.length && this.form.school_id.length) {
            let url = `&cycle_config_id=${this.form.cycle_config_id}`
            url += this.form.grade_id.map((g, key) => `&grade_ids[${key}]=${g}`).join("")
            url += this.form.school_id.map((s, key) => `&school_ids[${key}]=${s}`).join("")
            this.$emit('input', url)
          } else {
            this.$emit('input', '')
          }
      },
      deep: true,
    }
  },
  mounted(){
    if(this.schools.length == 0 ){
      this.getSchools('order_by=name&order_direction=asc&per_page=1000');
    }
    if(this.grades.length == 0 ){
      this.getGrades()
    }
    this.getCycleConfigs()
  },
  methods:{
    ...mapActions(['getSchools','getCycleConfigs','getGrades']),
    emitValue(){

    }
  },
}
</script>
