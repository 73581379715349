<template>
  <div class="mx--4">
    <el-skeleton
      :loading="isLoadingChallenges && challenges.length === 0"
      :rows="10"
      animated
    >
      <el-table
        :data="challenges"
        style="width: 100%"
      >
        <el-table-column
          label=""
          width="70"
        >
          <template slot-scope="{ row }">
            <el-checkbox
              v-model="row.selected"
              @change="handleSelectionChange(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="RA"
          prop="ra"
        />
        
        <el-table-column
          label="RE"
          prop="re"
        />

        <el-table-column
          label="Aluno"
          prop="user_name"
          width="250"
        />

        <el-table-column
          label="Ciclo"
          prop="cycle_name"
          width="150"
        >
          <template slot-scope="{row}">
            {{ row.cycle_name }} - {{ row.year }}
          </template>
        </el-table-column>
        <el-table-column
          label="Escola"
          prop="school_name"
          width="250"
        />
        <el-table-column
          label="Série"
          prop="grade_name"
        />
        <el-table-column
          label="Formato"
          prop="challenge_type"
        />
        <el-table-column
          label="Status"
          prop="status"
        />
        
        <el-table-column
          label="Ações"
          prop="actions"
        >
          <template slot-scope="{row}">
            <BtnDeleteChallenge :challenge="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import BtnDeleteChallenge from "@/admin/Pages/maintenance/challenge/components/BtnDeleteChallenge";

export default {
  components: { BtnDeleteChallenge},
  computed: {
    ...mapGetters(['challenges','isLoadingChallenges','challenge_selection_list']),
  },
  methods:{
    ...mapMutations(['SET_STUDENT','SET_CHALLENGE_SELECTION_LIST']),
    handleSelectionChange(row){
      if(row.selected){
        this.SET_CHALLENGE_SELECTION_LIST([...this.challenge_selection_list, row])
      }else {
        this.SET_CHALLENGE_SELECTION_LIST([...this.challenge_selection_list.filter(s=> s.id != row.id)])
      }
    }
  }
}
</script>

