<template>
  <div
    :style="
      `overflow-y: auto; overflow-x:hidden;max-height: ${defaultModalHeight}`
    "
    class="p-4 bg-white"
  >
    <div>
      <div>
        <p>{{ page.title }}</p>
      </div>
      <div class="modal-module-page">
        <ckeditor
          :value="page.content"
          :editor="editor"
          @ready="onEditorReady"
        />
      </div>
    </div>
  </div>
</template>
<script>
import editor from '@/admin/utils/Editor.js'

export default {
  props: {
    page: {
      required: true,
      type: [Array, Object],
      default: null
    }
  },
  data() {
    return {
      editor,
      defaultModalHeight: "60vh"
    }
  },
  methods: {
    onEditorReady(editor) {
      editor.isReadOnly = true
      const toolbarElement = editor.ui.view.toolbar.element
      toolbarElement.style.display = 'none'
    }
  }
}
</script>
<style>
.modal-module-page .ck-editor__main .ck.ck-editor__editable_inline {
  border: 0px !important;
  padding-left: 0 !important;
}
</style>
