
export default {
    state: {
        list: [],
        current: null,
        isLoadingSchoolCycleGradeConfigs: false,
    },
    actions: {
        getSchoolCycleGradeConfigs({commit, state}, {school_id, params = '' }) {
          state.isLoadingSchoolCycleGradeConfigs = true;
          return axios.get(`/admin/school/cycle-grade-config/${school_id}?${params}`)
          .then((response) => {
            const { data : { data : configs, count_students_without_modules ,count_students_without_themes, count_students_on_grade }} = response
            commit("SET_SCHOOL_CYCLE_GRADE_CONFIGS", { configs, count_students_without_modules ,count_students_without_themes, count_students_on_grade });
            return 
          })
          .finally(() => state.isLoadingSchoolCycleGradeConfigs = false);
        },
    },
    mutations: {
        SET_SCHOOL_CYCLE_GRADE_CONFIGS(state, { configs, count_students_without_modules ,count_students_without_themes, count_students_on_grade }) {
            state.list = configs;
            if(configs.length == 1) {
                state.current = {
                    ...configs[0],
                    count_students_without_modules,
                    count_students_without_themes,
                    count_students_on_grade,
                }
            }
        },
        SET_SCHOOL_CYCLE_GRADE_CONFIG(state, config){
            state.current = config
        }
    },
    getters:{
      school_cycle_grade_configs: state => state.list,
      school_cycle_grade_config: state => state.current,
      isLoadingSchoolCycleGradeConfigs: state => state.isLoadingSchoolCycleGradeConfigs,
    }
};
