export default {
  name: [
    { required: true, message: 'O nome é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O nome deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  ebook_id: [
    { required: true, message: 'O ebook é obrigatório', trigger: 'blur' },
  ],
  subject_id: [
    { required: true, message: 'A disciplina é obrigatória', trigger: 'blur' },
  ],
}
