<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Apagar o desafio do aluno"
    placement="top-center"
  >
    <el-button
      v-loading="isLoading"
      type="danger"
      icon="el-icon-delete"
      :disabled="!challenge"
      circle
      @click="onClick"
    />
  </el-tooltip>
</template>
<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
  props:{
      challenge: {
        type: Object,
        default: null,
        required: false,
      }
    },
    data(){
      return {
        isLoading: false
      }
    },
    computed :{
      ...mapGetters(['challenges']),
    },
    methods:{
      ...mapActions(['resetChallenge']),
      ...mapMutations(['SET_CHALLENGES']),
      onClick(){
        this.$confirm('Tem certeza que deseja apagar o desafio essa ação e irreversível. Continuar?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.onDelete()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
      },
      onDelete(){
        this.isLoading = true
        axios.delete(`/admin/maintenance/challenge/${this.challenge.id}`)
        .then(()=> {
          this.$emit('deleted', this.challenge)
          this.$message({
            message: 'Desafio apagado com sucesso',
            type: 'success'
          });
          this.SET_CHALLENGES(this.challenges.filter(c=>c.id != this.challenge.id))
        })
        .finally(()=>this.isLoading = false)
      },
    },
    
  }

</script>
