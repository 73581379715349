<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <PageHeader
          slot="header"
          :can-create="false"
        />

        <Filters v-model="filters" />

        <List @changed="getStudents(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('login-student')"
                size="mini"
                type="primary"
                icon="el-icon-user-solid"
                @click="onLogin(row)"
              >
                Entrar como aluno
              </el-button>
            </el-button-group>
          </template>
        </List>
        <div :class="`d-flex justify-content-${students_selection_list.length ? 'between': 'center'} p-2`">
          <el-pagination
            background
            :page-sizes="[10, 50, 100, 500]"
            layout="sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
          <div>
            <el-button
              v-show="students_selection_list.length"
              icon="el-icon-view"
              @click="showSelectionModal = true"
            >
              Visualizar seleção ({{ students_selection_list.length }})
            </el-button>
            <el-button
              v-show="students_selection_list.length"
              icon="el-icon-refresh-left"
              @click="CLEAR_SELECTION_LIST([]); "
            >
              Limpar seleção
            </el-button>
          </div>
        </div>
      </el-card>
      <el-dialog
        :title="`Alunos selecionados (${students_selection_list.length})`"
        :visible.sync="showSelectionModal"
        fullscreen
      >
        <el-row>
          <el-col>
            Ações
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <BtnReleaseCycle v-if="permissions.includes('manage-student')" />
          </el-col>
        </el-row>
        <el-table :data="students_selection_list">
          <el-table-column
            label="RA"
            prop="identifier"
            width="120"
          />
          <el-table-column
            label="Nome"
            prop="name"
          />
          <el-table-column
            label="Email"
            prop="email"
          />
          <el-table-column
            fixed="right"
            label="Ações"
            width="220"
          >
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="danger"
                @click="SET_SELECTION_LIST([...students_selection_list.filter(s=>s.id != row.id)])"
              >
                Remover
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/student/components/List"
import BtnReleaseCycle from "@/admin/Pages/student/components/BtnReleaseCycle"
import Filters from "@/admin/Pages/student/components/Filters"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { defaultLoadingConfig } from "@/admin/constants/general"
import { nitro_url } from "@/constants/general"
import {redirect} from "@/utils/misc";

export default{
    components: {
        List,
        Filters,
        BtnReleaseCycle,
    },
    data() {
        return {
          filters:'',
          registering: false,
          showing: false,
          meta : {
              current_page: 1,
              total: 10,
              per_page: 10,
          },
          showSelectionModal: false,
        }
    },
    computed:{
      ...mapGetters(['permissions','student','students','students_selection_list']),
      params(){
        return `${this.filters}&page=${this.meta.current_page}&per_page=${this.meta.per_page}&order_by=name&order_direction=asc`
      }
    },
    watch:{
      params: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getStudents(this.params)
                .then((r)=> {
                    const { data :{meta} } = r
                    this.meta.per_page = Number(meta.per_page);
                    this.meta.current_page = meta.current_page;
                    this.meta.total = meta.total;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getStudents','authUser.platformToken']),
        ...mapMutations(['SET_STUDENT','CLEAR_SELECTION_LIST','SET_SELECTION_LIST']),
        onShow(student){
          this.SET_STUDENT(student)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_STUDENT(null);
        },
        async onLogin(student){

          const url = `${this.isInDevelop ? 'http://nitro.test' : nitro_url}/api/signin`

          let bodyContent = new FormData();

          bodyContent.append("userID", student.userid);
          bodyContent.append("schoolId", student.schoolid);
          bodyContent.append("token", "'$r4ta1293jnjxjdjasd'");

          let headersList = { "Accept": "*/*" }

          let response = await fetch(url, {
            method: "POST",
            body: bodyContent,
            headers: headersList
          });

          let data = await response.text();
          redirect(JSON.parse(data).ResultAccessUrl[0], true, false)

        }
    },
}
</script>
