<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Email"
          class="w-100"
          prop="email"
        >
          <el-input
            v-model="form.email"
            clearable
            name="email"
            placeholder="Email"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Tipo de Usuário"
          class="w-100"
          prop="role_id"
        >
          <el-select
            v-model="form.role_id"
            class="w-100"
            placeholder="Selecionar tipo de usuário"
            filterable
            clearable
          >
            <el-option
              v-for="item in roles"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules
    }
  },
  computed: {
    ...mapGetters(['user','roles']),
    form: {
      get() {
        return this.user;
      },
      set(user) {
        this.SET_USER(user);
      }
    }
  },
  
  methods: {
    ...mapMutations(['SET_USER']),
    ...mapActions(['createUser','updateUser']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.user.id ? this.updateUser({data: this.form, id: this.user.id}) : this.createUser({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Cadastro de usuário salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
  },
}
</script>
