<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="`${ video ? video.title : ''}`"
          width="50%"
          class="with-border"
          :visible.sync="showing"
          center
          @closed="SET_VIDEO(null)"
        >
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              v-if="video"
              :src="video.content"
              :title="video.title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </el-dialog>

        <el-dialog
          :title="video && video.id ? 'Editar Vídeo' : 'Novo vídeo'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getVideos(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <FilterList v-model="search" />

        <List @changed="getVideos(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-video')"
                size="mini"
                icon="el-icon-edit"
                type="warning"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                v-if="permissions.includes('delete-video')"
                size="mini"
                icon="el-icon-delete"
                type="danger"
                @click="onDelete(row)"
              >
                Apagar
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-eye"
                @click="onShow(row)"
              >
                Ver
              </el-button>
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/video/components/List"
import Register from "@/admin/Pages/video/components/Register"
import FilterList from "@/admin/Pages/video/components/FilterList"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
        FilterList,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          used: null,
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','video','videos']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
                this.getVideos(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getVideos', 'deleteVideo']),
        ...mapMutations(['SET_VIDEO','SET_HISTORY']),
        onEdit(video){
          this.registering = true;
          this.SET_VIDEO(video)
        },
        onCreate(){
            this.SET_VIDEO({
              name: '',
              description: '',
              file: null
            });
            this.registering = true;
        },
        onShow(video){
          this.SET_VIDEO(video)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_VIDEO(null);
        },
        onDelete(video){
          this.$confirm('Tem certeza que deseja apagar o video ?', 'Atenção', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning'
          })
          .then(() => this.deleteVideo(video))
          .then(() => this.getVideos(this.filters))
          .catch(() => {
            this.$message({
                type: 'info',
                message: 'Ação cancelada!'
            });
          });
        },
    },
}
</script>
