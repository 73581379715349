<template>
  <div v-if="student_by_cycle.has_selected_themes">
    <el-alert
      class="my-2"
      title="Atenção"
      type="warning"
      :closable="false"
      show-icon
    >
      <p>
        Você está prestes a salvar a atribuição dos conteúdos escolhidos para os alunos no {{ user.cycle_grade_config.cycle_name }}.
        <br>A atribuição não poderá ser desfeita. 
        <br> <span class="font-weight-bold">Deseja continuar?</span>
      </p>
    </el-alert>

    
    <el-button
      type="primary"
      class="w-100 ml-0 mb-2"
      @click="setupModules"
    >
      Atribuir módulos de desempenho
    </el-button>
    <el-button
      class="w-100 ml-0"
      @click="$emit('onCancel')"
    >
      Cancelar
    </el-button>
  </div>
  <div v-else>
    <el-alert
      class="my-2"
      title="Atenção"
      :closable="false"
      type="warning"
      show-icon
    >
      <p>
        Você está prestes a salvar a atribuição dos conteúdos escolhidos para os alunos no {{ user.cycle_grade_config.cycle_name }}.
        <br>A atribuição não poderá ser desfeita. 
        <br> <span class="font-weight-bold">Deseja continuar?</span>
      </p>
    </el-alert>
    <div>
      <el-select
        v-model="trail_id"
        class="w-100 mb-1"
        placeholder="Carreira"
      >
        <el-option
          v-for="item in trails"
          :key="item.trail_id"
          :label="item.name"
          :value="item.trail_id"
        />
      </el-select>
      <el-select
        v-if="trail"
        v-model="blocks"
        multiple
        class="w-100 mb-1"
        placeholder="Blocos temáticos"
        :multiple-limit="student_by_cycle.cycle_grade_config.blocks_qty"
      >
        <el-option
          v-for="item in trail_blocks"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      
      <el-button 
        class="w-100 mb-1"
        type="danger"
        :disabled="blocks.length != student_by_cycle.expected_selected_themes_qty"
        @click="setupThemes"
      >
        Atribuir blocos temáticos
      </el-button>
      <el-button
        class="w-100 ml-0"
        @click="$emit('onCancel')"
      >
        Cancelar
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    props:{
      user: {
        type: Object,
        required: true,
      },
    },
    data(){
        return {
            blocks: [],
            trail_id: null,
        }
    },
    computed:{
        ...mapGetters(['students_by_cycle','student_by_cycle','schools','school_cycle_grade_config']),
        school(){
          return this.schools.find(s => s.id == this.user.school_id)
        },
        trails(){
          return this.school.trails.filter(t => t.trail_id > 0)
        },
        trail(){
          return this.trails.find(t => t.trail_id == this.trail_id && t.trail_id > 0)
        },
        trail_blocks(){
          return  this.trail ? this.student_by_cycle.blocks.filter(b => b.trail_id == this.trail_id) : []
        }
    },
    watch:{
      user(){
        this.blocks = []
        this.trail_id = null
      }
    },
    methods:{
        ...mapMutations(['SET_STUDENTS_BY_CYCLE','SET_STUDENT_CYCLE','SET_SCHOOL_CYCLE_GRADE_CONFIG']),
        setupThemes()
        {
            this.$loading({
               text : `Atribuindo blocos temáticos ${this.user.name} no ${this.user.cycle_grade_config.cycle_name}...`,
            });
            axios.post('/admin/maintenance/setup-themes-for-student', {
                blocks: this.blocks,
                user_id: this.student_by_cycle.user_id,
                cycle_config_id: this.student_by_cycle.cycle_config_id,
            })
            .then(({data: {data: user_cycle }})=> {
              this.$message({
                message: 'Blocos temáticos definidos com sucesso',
                type: 'success'
              })
              this.SET_SCHOOL_CYCLE_GRADE_CONFIG({
                ...this.school_cycle_grade_config,
                count_students_without_themes: this.school_cycle_grade_config.count_students_without_themes - 1  ,
              })
               this.onChange(user_cycle)
               if(this.school_cycle_grade_config.is_generating_modules){
                this.$emit('onCancel')
               }
            })
            .finally(() => {
              this.$loading().close();
            })
        },
        setupModules(){
            this.$loading({
               text : `Atribuindo módulos para ${this.user.name} no ${this.user.cycle_grade_config.cycle_name}...`,
            });
            axios.post('/admin/maintenance/setup-modules-for-student', {
                user_id: this.student_by_cycle.user_id,
                cycle_config_id: this.student_by_cycle.cycle_config_id,
            })
            .then(({data: {data: user_cycle }})=> {
              this.$message({
                message: 'Módulos definidos com sucesso',
                type: 'success'
              })
              this.SET_SCHOOL_CYCLE_GRADE_CONFIG({
                ...this.school_cycle_grade_config,
                count_students_without_modules: this.school_cycle_grade_config.count_students_without_modules - 1  ,
              })
              this.onChange(user_cycle)
              this.$emit('finished')
            })
            .finally(() => {
              this.$loading().close();
            })
        },
        onChange(user_cycle)
        {
          this.SET_STUDENT_CYCLE(user_cycle)
          this.SET_STUDENTS_BY_CYCLE(this.students_by_cycle.map(uc => {
              if(uc.user_id == user_cycle.user_id && uc.cycle_config_id == user_cycle.cycle_config_id)
              {
                  return user_cycle
              }else{
                  return uc
              }
          }))
          
        }
    }
}
</script>