<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />
        
        <FilterList
          v-model="search"
          @deleted="handleChange"
        />

        <List @changed="getChallenges(filters)">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-challenge')"
                icon="el-icon-edit"
                size="mini"
                type="primary"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>

        <div :class="`d-flex justify-content-${challenge_selection_list.length ? 'between': 'center'} p-2`">
          <el-pagination
            background
            :page-sizes="[10, 30, 50, 100, 200, 500]"
            layout="sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
          <div>
            <el-button
              v-show="challenge_selection_list.length"
              icon="el-icon-view"
              @click="showSelectionModal = true"
            >
              Visualizar seleção ({{ challenge_selection_list.length }})
            </el-button>
            <el-button
              v-show="challenge_selection_list.length"
              icon="el-icon-refresh-left"
              @click="CLEAR_CHALLENGE_SELECTION_LIST([]); "
            >
              Limpar seleção
            </el-button>
          </div>
          <el-dialog
            :title="`Desafios selecionados (${challenge_selection_list.length})`"
            :visible.sync="showSelectionModal"
            fullscreen
          >
            <el-row>
              <el-col>
                <el-alert
                  title="ATENÇÃO"
                  type="error"
                  description="Essa ação é irreversível"
                  show-icon
                  class="mb-3"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-button 
                  type="danger"
                  icon="el-icon-delete"
                  @click="onConfirmDelete"
                >
                  Apagar desafios
                </el-button>
              </el-col>
            </el-row>
            <el-table :data="challenge_selection_list">
              <el-table-column
                label="RA"
                prop="ra"
              />
        
              <el-table-column
                label="RE"
                prop="re"
              />

              <el-table-column
                label="Aluno"
                prop="user_name"
                width="250"
              />

              <el-table-column
                label="Ciclo"
                prop="cycle_name"
                width="150"
              >
                <template slot-scope="{row}">
                  {{ row.cycle_name }} - {{ row.year }}
                </template>
              </el-table-column>
              <el-table-column
                label="Escola"
                prop="school_name"
                width="250"
              />
              <el-table-column
                label="Série"
                prop="grade_name"
              />
              <el-table-column
                label="Formato"
                prop="challenge_type"
              />
              <el-table-column
                label="Status"
                prop="status"
              />
              <el-table-column
                fixed="right"
                label="Ações"
                width="220"
              >
                <template slot-scope="{ row }">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="SET_CHALLENGE_SELECTION_LIST([...challenge_selection_list.filter(s=>s.id != row.id)])"
                  >
                    Remover da lista
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-dialog>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/maintenance/challenge/components/List"
import FilterList from "@/admin/Pages/maintenance/challenge/components/FilterList"
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default {
  components: {
    List,
    FilterList
  },
  data() {
    return {
      registering: false,
      search: '',
      showSelectionModal:false,
      meta: {
        current_page: 1,
        total: 10,
        per_page: 50,
      },
    }
  },
  computed: {
    ...mapGetters(['permissions','challenge_selection_list']),
    filters() {
      return `${this.search}&page=${this.meta.current_page}&per_page=${this.meta.per_page}`
    },
  },
  watch: {
    filters: {
      handler() {
        this.handleChange()
      },
      immediate: true,
    },

  },
  methods: {
    ...mapActions(['getChallenges','updateChallenge','deleteChallengesByIds']),
    ...mapMutations(['CLEAR_CHALLENGE_SELECTION_LIST','SET_CHALLENGE_SELECTION_LIST']),
    handleChange(){
      clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$loading(defaultLoadingConfig);
          this.getChallenges(this.filters)
            .then((r) => {
              this.meta = {
                ...r.data.meta,
                per_page: Number(r.data.meta.per_page)
              };
            })
            .finally(() => this.$loading().close())
        }, 500)
      },
      onConfirmDelete(){
        this.$loading(defaultLoadingConfig);
        this.deleteChallengesByIds({challenge_ids: this.challenge_selection_list.map(c=>c.id)})
        .then(()=> {
          this.CLEAR_CHALLENGE_SELECTION_LIST();
            this.$message({
              type: 'success',
              message: 'Desafios apagados com sucesso.',
            })
          this.getChallenges(this.filters)
            .then((r) => {
              this.meta = {
                ...r.data.meta,
                per_page: Number(r.data.meta.per_page)
              };
              this.showSelectionModal = false;
            })
            .catch(() => {
              this.$message({
                type: 'erro',
                message: 'Erro ao apagar desafios.',
              })
            })
            .finally(() => this.$loading().close())
        })
      }
  },
}
</script>