export default {
  state: {
    list: [],
    types: [],
    current: null,
    isLoadingTickets: false
  },
  actions: {
    getTickets({commit, state}, params = "") {
      state.isLoadingTickets = true;
      return axios.get(`/admin/ticket?${params}`)
        .then(r => {
          commit("SET_TICKETS", r.data.data);
          commit("SET_TICKET_TYPES", r.data.types);
          return r;
        }).finally(() => state.isLoadingTickets = false);
    },
    updateTicket({commit}, params) {
      return axios.put(`/admin/ticket/${params.id}`, params);
    },

    deleteTicket({commit}, {id}) {
      return axios.delete(`/admin/ticket/${id}`);
    },
  },
  mutations: {
    SET_TICKETS(state, payload) {
      state.list = payload;
    },
    SET_TICKET_TYPES(state, payload) {
      state.types = payload;
    },
  },
  getters: {
    tickets: state => state.list,
    ticket_types: state => state.types,
    isLoadingTickets: state => state.isLoadingTickets,
  }
};
