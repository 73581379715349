<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    class="container-fluid mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Link do video"
          class="w-100"
          prop="content"
          :error="errors.content"
        >
          <el-input
            v-model="form.content"
            clearable
            name="embed"
            show-word-limit
            maxlength="190"
            placeholder="www.linkdovideo.com/nome-do-video"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import FormRules from "./FormRules";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      FormRules
    }
  },
  computed: {
    ...mapGetters(['video']),
    form: {
      get() {
        return this.$store.state.video.current;
      },
      set(video) {
        this.SET_VIDEO(video);
      }
    }
  },
  methods: {
    ...mapActions(['getVideo','createVideo','updateVideo']),
    onSubmit() {

      this.$refs['form'].validate((valid) => {
        if (valid) {

          const action = this.video.id ? this.updateVideo({data: this.form, id: this.video.id}) : this.createVideo({data: this.form});
          this.$loading();
          action
            .then(() => {
              this.$message({
                message: 'Video salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=>this.$loading().close());
          } else {
          return false;
        }
      });
    },
    setForm(video) {
      this.form = video;
    },
  },
}
</script>
