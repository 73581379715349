<template>
  <el-card
    v-loading="isLoading"
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="24"
          :lg="24"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            {{ type == 'challenge' ? 'TOTAL DE ALUNOS QUE REALIZOU TODOS OS DESAFIOS' : 'TOTAL DE ALUNOS QUE ESCOLHEU MÓDULOS E REALIZOU TODOS OS DESAFIOS' }}
          </p>
          <p class="mb-0 font-weight-light text-sm">
            {{ type == 'challenge' ? 'Alunos que realizaram todos os desafios acumulados nos ciclos' : 'Alunos que escolheram módulos e realizaram todos os desafios acumulados nos ciclos' }}
          </p>
        </el-col>

      </el-row>
      <el-row>
        <el-col :md="6"
          :lg="8">
          <el-radio-group
            v-model="challenge_type"
            size="mini"
          >
            <el-radio-button label="print">
              Impresso
            </el-radio-button>
            <el-radio-button label="online">
              Online
            </el-radio-button>
            <el-radio-button label="all">
              Todos
            </el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col
          :md="6"
          :lg="8"
        >
          <el-button-group>
            <el-button
              size="mini"
              :type="avg ? 'primary' : 'default'"
              @click="avg = true"
            >
              Porcentagem
            </el-button>
            <el-button
              size="mini"
              :type="!avg ? 'primary' : 'default'"
              @click="avg = false"
            >
              Total somado
            </el-button>
          </el-button-group>
        </el-col>
        <el-col
          :md="6"
          :lg="8"
        >
          <div class="d-flex">
            <el-select
              v-model="school_id"
              filterable
              clearable
              size="mini"
              placeholder="Selecionar uma escola"
              class="w-100"
            >
              <el-option
                key="todas"
                label="Escolas: Todas com alunos ativos"
                :value="null"
              />
              <el-option
                v-for="item in schools"
                :key="item.value"
                :label="`${item.name} (${item.count_active_students})`"
                :value="item.id"
              />
            </el-select>
            <el-button
              class="ml-3"
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="$emit('add')"
            />
            <el-button
              v-if="componentKey>0"
              icon="el-icon-close"
              size="mini"
              @click="$emit('remove', componentKey)"
            />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <highcharts
            v-if="cycles.length"
            ref="chart"
            class="hc"
            :options="options"
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>


import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props:{
    componentKey: {
      type:Number,
      default: 0
    },
    type: {
        type: String,
        required: true,
        validation(value){
          return ['cycle','challenge'].includes(value)
        }
      },
  },
  data() {
    return {
      challenge_type:'all',
      avg: true,
      graphic_type: 'area',
      cycles: [],
      school_id: null,
      isLoading: true
    }
  },

  computed: {
    ...mapGetters(['schools','isLoadingSchools']),
    dataOptions(){
      return this.cycles.map(c => {
        return {
          name: c.cycle_name,
          color: '#060181',
          y: this.getY(c),
          z: this.getZ(c)
        };
      });
    },
    pointFormat(){
      return this.avg ? `<b/>{point.y}%</b> dos  alunos ativos  que ${this.type == 'challenge' ? 'fizeram todos os desafios': 'completaram todos os ciclos'} até aqui.` :`<b/>{point.y}</b> de <b>{point.z}</b> alunos ativos fizeram todos os desafios até aqui.`
    },
    options() {

      const component = this;

      return {
        chart: {
          type: this.graphic_type,
          title: 'Carreiras',
          backgroundColor: 'transparent',
        },
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '',
          pointFormat: this.pointFormat
        },
        yAxis: {
          title: {
            useHTML: true,
            text: this.avg ? 'Porcentagem': 'Total somado'
          }
        },
        xAxis: {
          title: {
            useHTML: true,
            text: 'Ciclos'
          },
          labels: {
            enabled: true,
            formatter: function() {
              return component.dataOptions[this.value].name
            },
        }
        },
        series: [{
          minPointSize: 1,
          innerSize: '20%',
          zMin: 100,
          yMin: 100,
          data: this.dataOptions,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          }
        }],
        credits: {
          enabled: false,
        }
      };

    },
    filters(){
      return `${this.school_id ? `&school_id=${this.school_id}`:''}${this.challenge_type!='all' ? `&challenge_type=${this.challenge_type}`:''}`
    }
  },
  watch:{
    filters: {
      handler(){
        this.isLoading = true;
        this.getFullProgressThroughCycles()
        .then(response=>{
          this.cycles = response.data.data
        })
        .finally(()=>this.isLoading = false)
    },
    immediate: true,
  },
},
  created(){
    if(!this.isLoadingSchools){
      this.getSchools(`&order_by=name&order_direction=asc`).then(()=> this.SET_SCHOOLS(this.schools.filter(s=>s.count_active_students>0)));
    }
  },
  methods: {
    ...mapActions(['getSchools']),
    ...mapMutations(['SET_SCHOOLS']),
    getFullProgressThroughCycles(){
      return axios(`/admin/report/full-progress-through-cycles?${this.filters}`)
    },
    getY(cycle){
      if(this.avg  &&  this.type == 'challenge')
        return cycle.avg_hit_full_challenge_progress_until_cycle_end
      if(!this.avg && this.type == 'challenge')
       return  cycle.count_hit_full_challenge_progress_until_cycle_end
      if(this.avg  &&  this.type == 'cycle')
        return cycle.avg_hit_full_cycle_progress_until_cycle_end
      if(!this.avg && this.type == 'cycle')
        return cycle.count_hit_full_cycle_progress_until_cycle_end
    },
    getZ(cycle){
      return this.school_id ? cycle.count_school_active_students : cycle.count_active_students
    }
  },

}
  </script>

