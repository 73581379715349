export function showDefaultErrorMessages(errorRequest){
  this.errors = {};

  if (errorRequest.response && Number(errorRequest.response.status) === 500) {
      this.$message({
          showClose: true,
          message: "Erro no servidor",
          type: "error"
      });
  } else if(errorRequest.response && Number(errorRequest.response.status) === 403) {
      this.$message({
          showClose: true,
          message: "Erro de autorização",
          type: "error"
      });
  } else if(errorRequest.response && Number(errorRequest.response.status) === 405) {
      this.$message({
          showClose: true,
          message: "Erro de autorização",
          type: "error"
      });
  } else {
      let message;

      if(errorRequest.response){
          const errors = errorRequest.response.data.errors;
          for (let index in errors) {
            if(!message) {
              message = errors[index][0]
            }
            this.errors[index] = errors[index][0];
          }
          message = message ? message : "Erro de validação";
      } else {
          message = errorRequest
      }
      this.$message({
          showClose: true,
          message: message,
          type: "error"
      });
  }
}

export default {
  data(){
    return {
      errors: {},
    }
  },
  methods: {
    showDefaultErrorMessages,
  }
};
