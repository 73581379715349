<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never" class="p-2">
        <PageHeader 
          slot="header" 
        >
          <!-- <template #right>
            <el-button
              type="primary"
              :loading="is_exporting"
              @click="exportData()"
            >
              Baixar relatório <i class="fa fa-download" />
            </el-button>
          </template>   -->
        </PageHeader>
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="Engajamento por escola, série e ciclo"
            name="engagement"
          >
            <SchoolCycleGradeEngagementTab />
          </el-tab-pane>
          <el-tab-pane
            label="Engajamento ciclo"
            name="cycle"
          >
            <CycleEngagementTab />
          </el-tab-pane>
          <el-tab-pane
            label="Engajamento por série"
            name="grade"
          >
            <GradeEngagementTab />
          </el-tab-pane>
          <el-tab-pane
            label="Engajamento por escola"
            name="school"
          >
            <SchoolEngagementTab />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import CycleEngagementTab from './CycleEngagementTab.vue';
import GradeEngagementTab from './GradeEngagementTab.vue';
import SchoolEngagementTab from './SchoolEngagementTab.vue';
import SchoolCycleGradeEngagementTab from './SchoolCycleGradeEngagementTab.vue';

  export default {
    components: { 
      CycleEngagementTab , 
      GradeEngagementTab,
      SchoolCycleGradeEngagementTab,
      SchoolEngagementTab
    },
    data() {
      return {
        activeName: 'engagement'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>