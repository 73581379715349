
export default {
    state: {
        list: [],
        selection_list: [],
        current: null,
        isLoadingStudents: false,
    },
    actions: {
        getStudentsByCycle({commit, state}, params = '') {
            state.isLoadingStudents = true;
            return axios.get(`/admin/student-cycle?${params}`)
            .then(r => {
                const  {data: { data: students}} = r
                commit("SET_STUDENTS_BY_CYCLE", students);
                return r
            })
            .finally(() => state.isLoadingStudents = false);
        },
        getStudentByCycle({commit}, params) {
            return axios.get(`/admin/student/${params.data.id}`)
            .then(r => {
                commit("SET_STUDENT_CYCLE", r.data.data);
                return r
            });
        },
    },
    mutations: {
        SET_STUDENT_CYCLE(state, payload) {
            state.current = payload;
        },
        SET_STUDENTS_BY_CYCLE(state, payload) {
            state.list = payload
        },
    },
    getters:{
      students_by_cycle: state => state.list,
      student_by_cycle: state => state.current,
    }
};
