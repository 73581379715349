export default {
    state: {
        list: [],
        current: null,
        isLoadingUsers: false,
    },
    actions: {
        getUsers({commit, state}, params = '') {
            state.isLoadingUsers = true;
            return axios.get(`/admin/user?${params}`).then(r => {
                commit("SET_USERS", r.data.data);
                return r;
            })
                .finally(() => state.isLoadingUsers = false);
        },
        getUser({commit}, params) {
            return axios.get(`/admin/user/${params.data.id}`).then(r => {
                commit("SET_USER", r.data.data);
            })
        },
        createUser({commit}, params) {
            return axios.post(`/admin/user/`, params.data);
        },
        updateUser({commit}, params) {
            return axios.put(`/admin/user/${params.id}`, params.data);
        },
        deleteUser({commit}, params) {
            return axios.delete(`/admin/user/${params.id}`);
        }
    },
    mutations: {
        SET_USERS(state, payload) {
            state.list = payload;
        },
        SET_USER(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        users: state => state.list,
        user: state  => state.current,
        isLoadingUsers: state => state.isLoadingUsers
    }

};
