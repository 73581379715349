<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="permissions.includes('create-challenge-question')"
          @onCreate="onCreate" 
        />
        <div>
          <el-dialog
            title="Editar questão"
            :visible.sync="editing"
            center
          >
            <RegisterChallenge
              v-if="challenge_question && editing"
              :challenge-question="challenge_question"
              @saved="getChallengeQuestions(); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
          <el-dialog
            title="Cadastrar questão"
            :visible.sync="creating"
            center
          >
            <RegisterChallenge
              @saved="getChallengeQuestions(); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
          <el-dialog
            :title="`Informação da Questão ${challenge_question ?challenge_question.description: ''}`"
            width="30%"
            class="with-border"
            :visible.sync="showing"
            center
          >
            <ShowChallenge
              v-if="challenge_question"
              :challenge="challenge_question"
            />
          </el-dialog>
          <FilterList v-model="search" />
        </div>

        <challenge-list @changed="getChallengeQuestions(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-challenge-question')"
                size="mini"
                icon="el-icon-edit"
                :disabled="!!row.invalidated_at"
                :title="row.invalidated_at ? 'Não é possível editar uma questão anulada' : 'Editar questão'"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-view"
                @click="onShow(row)"
              >
                Visualizar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </challenge-list>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import ChallengeList from "@/admin/Pages/challenge-question/components/ChallengeList"
import RegisterChallenge from "@/admin/Pages/challenge-question/components/RegisterChallenge.vue"
import ShowChallenge from "@/admin/Pages/challenge-question/components/ShowChallenge.vue"
import FilterList from "@/admin/Pages/challenge-question/components/FilterList.vue"
import { mapActions, mapMutations,  mapGetters } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        ChallengeList,
        RegisterChallenge,
        FilterList,
        ShowChallenge
    },
    data() {
        return {
          editing: false,
          showing: false,
          creating: false,
          search:'',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','challenge_question']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getChallengeQuestions(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      }
    },
    methods:{
        ...mapActions(['getChallengeQuestions']),
        ...mapMutations(['SET_CHALLENGE_QUESTION']),
        onEdit(challenge){
            this.SET_CHALLENGE_QUESTION(challenge);
            this.editing = true;
        },
        onCreate(){
            this.SET_CHALLENGE_QUESTION(null);
            this.creating = true;
        },
        onShow(challenge){
            this.showing = true;
            this.SET_CHALLENGE_QUESTION(challenge);
        },
        onCancel(){
            this.editing = false;
            this.creating = false;
            this.SET_CHALLENGE_QUESTION(null);
        },
    },
}
</script>
