<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingQuestions"
      animated
    >
      <el-table
        :data="questions"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <question-details :question="props.row" />
              <el-button-group>
                <!-- <el-button
                  size="mini"
                  type="info"
                  @click="$loading();getQuestionLogs(props.row).then(()=>$loading().close())"
                >
                  Histórico de mudanças
                </el-button> -->
                <el-button
                  v-if="permissions.includes('delete-question')"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="100"
        />
        <el-table-column
          label="Descrição"
          prop="description"
        />
        <el-table-column
          label="Módulo"
          prop="module_name"
        />
        <el-table-column
          label="Disciplina"
          prop="subject_name"
        />
        <el-table-column
          label="Respostas"
          prop="count_choices"
        />

        <el-table-column
          label="Respostas corretas"
          prop="count_correct_choices"
        />

        <el-table-column
          label="Respostas erradas"
          prop="count_wrong_choices"
        />

        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import QuestionDetails from "@/admin/Pages/question/components/QuestionDetails";
export default {
  components:{
    QuestionDetails,
  },
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters(['questions','permissions']),
    ...mapState({
      isLoadingQuestions : state => state.question.isLoadingQuestions,
    })
  },
  methods:{
    ...mapMutations(['SET_HISTORY','SET_QUESTION']),
    ...mapActions(['deleteQuestion','getQuestionLogs']),
    onDelete(question){
      this.$confirm('Tem certeza que deseja apagar a questão ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() =>{
        this.$loading();
        this.deleteQuestion(question)
        .then(()=>{
          this.$message({
              type: 'success',
              message: 'Questão apagada com sucesso!'
          });
          this.$emit('changed')
        })
        .finally(()=> this.$loading().close())
      })
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

