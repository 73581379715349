<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="container-fluid mb-2"
    label-position="top"
  >
    <div class="row">
      <div class="col-12">
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </div>
      <div class="col-lg-12">
        <el-form-item
          label="Conteúdo"
          class="w-100"
          prop="content"
          :error="errors.content"
        >
          <p class="text-danger small">
            * Tamanho máximo de upload de imagem é 2048 KB
          </p>
          <ckeditor
            v-model="form.content"
            :editor="editor"
          />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item
          class="w-100"
          label="Início"
          :error="errors.begin"
          prop="begin"
        >
          <el-date-picker
            v-model="form.begin"
            :disabled="false"
            :clearable="true"
            name="begin"
            class="w-100"
            type="datetime"
            placeholder="Data de início do Alerta"
            format="dd/MM/yyyy HH:mm:ss"
            :picker-options="datePickerOptionsInitial"
          />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item
          class="w-100"
          label="Fim"
          :error="errors.end"
          prop="end"
        >
          <el-date-picker
            v-model="form.end"
            :disabled="false"
            :clearable="true"
            name="end"
            class="w-100"
            type="datetime"
            placeholder="Data de fim do Alerta"
            format="dd/MM/yyyy HH:mm:ss"
            :picker-options="datePickerOptionsEnd"
          />
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
          label="Link de Redirecionamento"
          class="w-100"
          prop="redirect_link"
          :error="errors.redirect_link"
        >
          <el-input
            v-model="form.redirect_link"
            clearable
            name="redirect_link"
            show-word-limit
            maxlength="190"
            placeholder="www.linkderedirect.com/link-redirect"
          />
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
          v-if="showButtonLinkName"
          label="Nome do botão do link de redirecionamento"
          class="w-100"
          prop="button_link_name"
          :error="errors.button_link_name"
        >
          <el-input
            v-model="form.button_link_name"
            clearable
            name="button_link_name"
            show-word-limit
            maxlength="20"
            placeholder="www.linkderedirect.com/link-redirect"
          />
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
          label="Tipo de Usuário"
          class="w-100"
          prop="role_id"
          :error="errors.role_id"
        >
          <el-select
            v-model="form.role_id"
            class="w-100"
            placeholder="Selecionar o perfil de usuário alvo para aparecer o alerta"
            name="role_id"
            filterable
            clearable
          >
            <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <p class="text-danger small">
          * Um <b>Alerta Persistente </b> é um alerta que é SEMPRE mostrado para o usuário quando ele acessa a plataforma
        </p>
        <el-switch
          v-model="form.is_persistent"
          active-text="Alerta Persistente"
          inactive-text="Alerta Não Persistente"
        />
      </div>
    </div>
    <div 
      v-if="form.is_persistent"
      class="row pt-3" 
    >
      <div class="col-12">
        <p class="text-danger small">
          * Um <b>Alerta Persistente Bloqueante</b> é um alerta que não é possível fechar, além de bloquear todo o acesso a plataforma
        </p>
        <el-switch
          v-model="form.is_blocking"
          active-text="Alerta Persistente Bloqueante"
          inactive-text="Alerta Persistente Não Bloqueante"
        />
      </div>
    </div>
    <div class="row mt-5 mb-2">
      <div class="col-12">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="onCancel"
        >
          Cancelar
        </el-button>
      </div>
    </div>
  </el-form>
</template>
<script>
import {mapActions, mapState} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import editor from "@/admin/utils/Editor";

export default {
  mixins:[ RequestErrors ],
  props: {
    warning: {
      type: Object,
      default:null,
    },
  },
  data() {
    return {
      editor,
      form: {
        title: '',
        content: '',
        begin: '',
        end: '',
        role_id: '',
        button_link_name: '',
        is_persistent: false,
        is_blocking: false,
        redirect_link: ''
      },
      rules,
      datePickerOptionsInitial: {
        disabledDate: this.disabledDueDateInitial
      },
      datePickerOptionsEnd: {
        disabledDate: this.disabledDueDateEnd
      },
      roles:[
        {
          id: 2,
          name: 'Gestor',
        },
        {
          id: 3,
          name: 'Aluno',
        },
        {
          id: 4,
          name: 'Professor',
        },
      ]
    }
  },
  computed: {
    showButtonLinkName() {
      return this.form.redirect_link && !!this.form.redirect_link.length;
    }
  },
  watch: {
    showButtonLinkName(val) {
      if(val) {
        this.form.button_link_name = 'Acessar';
      } else {
        this.form.button_link_name = '';
      }
    },
    "form.is_persistent": {
      handler(val) {
        if(!val) {
          this.form.is_blocking = false;
        }
      }
    }
  },
  mounted() {
    if (this.warning) {
      setTimeout(() => {
        this.form = this.warning;
         this.form.is_persistent = !!this.warning.is_persistent;
      this.form.is_blocking = !!this.warning.is_blocking;
      }, 500);
    }
  },
  methods: {
    ...mapActions([ 'createWarning', 'updateWarning']),
    onEditorReady(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading({text: 'Salvando ...'});
          const action = this.warning ? this.updateWarning({
            data: {...this.form},
            id: this.warning.id
          }) : this.createWarning({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Aviso salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave');
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(() => {
              this.$loading().close();
              this.form = {
                title: '',
                content: '',
                begin: '',
                end: '',
                role_id: '',
                button_link_name: '',
                is_persistent: false,
                is_blocking: false,
                redirect_link: ''
              };
            });
        }else {
          return false;
        }
      });
    },
    onCancel(){
      this.$emit('cancel');
    },
    disabledDueDateInitial(date) {
      if (!this.warningId) {
          return ((date < new Date(this.form.begin)) || (date > new Date(this.form.end)) );
      }
    },
    disabledDueDateEnd(date) {
      if (!this.warningId) {
          return ((date < new Date(this.form.begin)) || (date > new Date(this.form.end)));
      }
    },
  },
}
</script>
