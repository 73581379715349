import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import auth from "./modules/auth";
import cycleConfig from "./modules/cycle-config";
import challenge_question from "./modules/challenge-question";
import challenge from "./modules/challenge";
import file from "./modules/file";
import grade from "./modules/grade";
import ebook from "./modules/ebook";
import link from "./modules/link";
import module from "./modules/module";
import pre_cycle_module from "./modules/pre_cycle_module";
import network from "./modules/network";
import page from "./modules/page";
import permission from "./modules/permission";
import question from "./modules/question";
import role from "./modules/role";
import school from "./modules/school";
import scorm from "./modules/scorm";
import student from "./modules/student";
import subject from "./modules/subject";
import subtopic from "./modules/subtopic";
import theme_block from "./modules/theme_block";
import theme from "./modules/theme.js";
import ticket from "./modules/ticket";
import trail from "./modules/trail";
import user from "./modules/user";
import video from "./modules/video";
import week from "./modules/week";
import quiz from "./modules/quiz";
import warning from "./modules/warning";
import manager from "./modules/manager";
import repeated_user from "./modules/repeated_user";
import process_package from "./modules/process_package";
import students_by_cycle from "./modules/student_by_cycle";
import school_cycle_grade_config from "./modules/school_cycle_grade_config";
import moment from "moment";

// Graphic Reports

import report_global_cycle_progress from "./modules/reports/global_cycle_progress";
import count_schools_without_modules_on_cycle from "./modules/reports/count_schools_without_modules_on_cycle";
import count_students_with_finished_challenge_in_current_and_next_cycle from "./modules/reports/count_students_with_finished_challenge_in_current_and_next_cycle";
import logins_per_week from "./modules/reports/logins_per_week";


export default new Vuex.Store({
  state: {
    contents: [],
    history: '',
    linkContent: '',
    modalTitle: '',
    logins_per_cycle: [],
    loadingLoginsPerCycle: false,
  },
  mutations: {
    SET_HISTORY(state, payload = {}) {
      const history = { title: '', items: [] }
      history.title = payload.title;
      history.items = payload.items;
      state.history = history;
    },
    SET_LOGINS_PER_CYCLE(state, payload){
      state.logins_per_cycle = payload
    }
  },
  actions: {
    getLoginsPerCycle({commit, state}){
      state.loadingLoginsPerCycle = true
      return axios.get('/admin/report/global-logins-per-cycle')
      .then(response => {
        const total_logins_in_all_cycles =  response.data.data.map(d=>d.count_logins_on_cycle).reduce((a,b) => a+b)
        commit('SET_LOGINS_PER_CYCLE', response.data.data.map(c => ({
          ...c,
          percentage:Math.trunc((c.count_active_students * 100) / c.count_logins),
          total_logins_in_all_cycles,
          begin_formatted : moment(c.begin).format('DD/MM/YYYY hh:mm'),
          end_formatted: moment(c.end).format('DD/MM/YYYY hh:mm'),
        })))
      })
      .finally(()=> state.loadingLoginsPerCycle = false)
    },
  },
  getters: {
    modules_and_themes(state){
      return state.module.list.concat(state.theme.list).concat(state.pre_cycle_module.list)
    },
    logins_per_cycle(state){
      return state.logins_per_cycle
    },
    loading_logins_per_cycle(state){
      return state.loadingLoginsPerCycle
    },
  },
  modules: {
    trail,
    ebook,
    link,
    module,
    pre_cycle_module,
    question,
    video,
    scorm,
    subject,
    cycleConfig,
    subtopic,
    student,
    grade,
    user,
    challenge_question,
    challenge,
    theme,
    network,
    week,
    warning,
    manager,
    role,
    permission,
    page,
    ticket,
    quiz,
    school,
    theme_block,
    file,
    auth,
    repeated_user,
    //Graphic Reports
    report_global_cycle_progress,
    count_schools_without_modules_on_cycle,
    count_students_with_finished_challenge_in_current_and_next_cycle,
    logins_per_week,
    process_package,
    students_by_cycle,
    school_cycle_grade_config,
  },
});
