
export default {
    state: {
        list: [],
        current: null,
        isLoadingManagers: false,
    },
    actions: {
        getManagers({commit, state}, params = '') {
            state.isLoadingManagers = true;
            return axios.get(`/admin/manager?${params}`)
            .then(r => {
                const  {data: { data: managers}} = r
                commit("SET_MANAGERS", managers);
                return r
            })
            .finally(() => state.isLoadingManagers = false);
        },
    },
    mutations: {
        SET_MANAGER(state, payload) {
            state.current = payload;
        },
        SET_MANAGERS(state, payload) {
            state.list = payload
        }
    },
    getters:{
      managers: state => state.list,
      isLoadingManagers: state => state.isLoadingManagers,
    }
};
