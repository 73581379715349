export default {
    state: {
        list: [],
        current: null,
        isLoadingEbooks: false,
        isLoadingEbook: false,
    },
    actions: {
        getEbooks({commit, state}, params = '') {
            state.isLoadingEbooks = true;
            return axios.get(`/admin/ebook?${params}`)
            .then(r => {
                const { data: {data: ebooks }} = r
                commit("SET_EBOOKS", ebooks);
                return r;
            })
            .finally(() => state.isLoadingEbooks = false);
        },
        getEbook({commit, state}, params) {
            state.isLoadingEbook = true;
            return axios.get(`/admin/ebook/${params.data.id}`,).then(r => {
                commit("SET_EBOOK", r.data.data);
            })
            .finally(() => state.isLoadingEbook = false);
        },
        createEbook({commit}, params) {
            return axios.post(`/admin/ebook`, params.data, {
                headers: {
                    "Content-Type": `multipart/form-data`,
                }
            });
        },
        updateEbook({commit}, params) {
            return axios.post(`/admin/ebook/${params.id}`, params.data, {
                headers: {
                    "Content-Type": `multipart/form-data`,
                }
            });
        },
        deleteEbook({commit}, params) {
            return axios.delete(`/admin/ebook/${params.id}`);
        }
    },
    mutations: {
        SET_EBOOKS(state, payload) {
            state.list = payload;
        },
        SET_EBOOK(state, payload) {
            state.current = payload;
        }
    },
    getters:{
        ebooks(state){
            return state.list
        },
        ebook(state){
            return state.current
        },
        isLoadingEbooks(state){
            return state.isLoadingEbooks
        }

    }

};
