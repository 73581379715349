<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="role && role.id ? 'Editar perfil' : 'Cadastrar perfil'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getRoles(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <el-row :gutter="4">
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
        </el-row>

        <List @changed="getRoles(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-role')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/role/components/List"
import Register from "@/admin/Pages/role/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','role']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getRoles(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    mounted() {
      this.getRoles();
      this.getPermissions();
    },
    methods:{
        ...mapActions(['getRoles','getRole','getRoles','getPermissions']),
        ...mapMutations(['SET_ROLE']),
        onEdit(page){
          this.registering = true;
          this.SET_ROLE(page)
        },
        onCreate(){
            this.SET_ROLE({
              name: '',
              email: '',
              role_id: ''
            });
            this.registering = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_ROLE(null);
        },
    },
}
</script>
