<template>
  <div>
    <p>
      <span class="font-weight-bold">Descrição:</span> {{ question.description }}
    </p>
    <p>
      <span class="font-weight-bold">Disciplina:</span> {{ question.subject_name }}
    </p>
    <p>
      <span class="font-weight-bold">Módulo:</span> {{ question.module_name }}
    </p>
  </div>
</template>
<script>
export default {
    props:{
      question: {
          type:Object,
          required: true,
      }
    },
}
</script>
