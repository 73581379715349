export default {
  theme_config_id: [
    { required: true, message: 'A escolha da data de configuração é obrigatória.', trigger: 'blur' },
  ],
  name: [
    { required: true, message: 'O nome é obrigatório.', trigger: 'blur' },
    {
      min: 5,
      max: 190,
      message: 'O nome deve ter entre 5 e 190 caracteres.',
      trigger: 'blur'
    }
  ],
  description: [
    { required: true, message: 'A descrição é obrigatória.', trigger: 'blur' },
    {
      min: 5,
      max: 190,
      message: 'A descrição deve ter entre 5 e 190 caracteres.',
      trigger: 'blur'
    }
  ],
  trail_id: [
    { required: true, message: 'O campo trilha é obrigatório.', trigger: 'blur' }
],
}
