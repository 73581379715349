export default {
    state: {
        list: [],
        current: null,
        isLoadingLinks: false,
    },
    actions: {
        getLinks({commit, state}, params = '') {
            state.isLoadingLinks = true;
            return axios.get(`/admin/link?${params}`).then(r => {
                commit("SET_LINKS", r.data.data);
                return r;
            })
                .finally(() => state.isLoadingLinks = false);
        },
        getLink({commit}, params) {
            return axios.get(`/admin/link/${params.data.id}`,)
            .then(r => {
                commit("SET_LINK", r.data.data);
            });
        },
        createLink({commit}, params) {
            return axios.post(`/admin/link/`, params.data);
        },
        updateLink({commit}, params) {
            return axios.put(`/admin/link/${params.id}`, params.data);
        },
        deleteLink({commit}, params) {
            return axios.delete(`/admin/link/${params.id}`);
        }
    },
    mutations: {
        SET_LINKS(state, payload) {
            state.list = payload;
        },
        SET_LINK(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        links: state => state.list,
        link: state => state.current,
        isLoadingLinks: state => state.isLoadingLinks,
    }
};
