<template>
  <div class="mx-4">
    <el-row>
      <el-col>
        <el-skeleton
          :rows="10"
          :loading="is_loading"
          animated
          class="mx--4"
        >
          <el-table
            :data="cycles"
            style="width: 100%"
          >
            <el-table-column
              label="Ciclo"
              prop="cycle_name"
              width="100"
            />
            <el-table-column
              label="Módulos finalizados"
              prop="count_finished_modules"
              width="200"
            />
            <el-table-column
              label="Módulos selecionados"
              prop="count_selected_modules"
              width="200"
            />
            <el-table-column
              label="Qty de módulos esperada"
              prop="expected_total_selected_modules_on_grade_and_cycle"
              width="300"
            />
            <el-table-column
              label="% por total esperado"
              prop="percentage_modules_finished_by_expected_selections"
              width="300"
            />
            <el-table-column
              label="% pela seleção feita"
              prop="percentage_modules_finished_by_modules_selected"
              width="300"
            />
            <el-table-column
              label="Alunos ativos"
              prop="count_active_students"
              width="300"
            />
            <el-table-column
              label="Desafios finalizados"
              prop="count_finished_challenges"
              width="300"
            />
            <el-table-column
              label="% Desafios finalizados por alunos ativos"
              prop="percentage_challenges_finished_by_expected_finished_challenges"
              width="300"
            />
          </el-table>
        </el-skeleton>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  
  export default{
      data() {
          return {
           cycles:[],
           is_loading: false,
          }
      },
      created(){
        this.getCycleReports()
      },
      methods:{
        getCycleReports(){
          this.is_loading = true
          axios.get(`/admin/report/cycle-engagement`)
          .then(({data: { data: cycles ,  }}) => {
            this.cycles = cycles
          })
          .finally(() => this.is_loading = false)
        },
      },
  }
  </script>
  