<template>
  <el-menu
    :default-openeds="default_opened"
    class="border-0"
    :router="true"
    :text-color="nitro_color"
    :active-text-color="theme_color"
    :default-active="default_active"
  >
    <el-menu-item 
      index="0"
      :route="dashboard_route"
    >
      <i
        :class="dashboard_route.meta.iconClass"
        :style="{color: nitro_color}"
      />
      <span>{{ dashboard_route.meta.label }}</span>
    </el-menu-item>

    <el-submenu index="0">
      <template slot="title">
        <i class="fas fa-chart-pie" />
        <span slot="title">Manutenção</span>
      </template>
      <!-- Menu de manutenção -->
      <el-menu-item 
        v-for="(menu, index) in maintenance_menu"
        :key="index"
        :index="`0-${index}`"
        :route="menu"
      >
        <i
          :class="menu.meta.iconClass"
          :style="{color: nitro_color}"
        />
        <span>{{ menu.meta.label }}</span>
      </el-menu-item>
    </el-submenu>

    <el-submenu index="1">
      <template slot="title">
        <i class="fas el-icon-location" />
        <span slot="title">Conteúdos</span>
      </template>
      <el-menu-item 
        v-for="(menu, index) in content_menu"
        :key="index"
        :index="`1-${index}`"
        :route="menu"
      >
        <i
          :class="menu.meta.iconClass"
          :style="{color: nitro_color}"
        />
        <span>{{ menu.meta.label }}</span>
      </el-menu-item>
    </el-submenu>

    <el-submenu index="3">
      <template slot="title">
        <i class="fas fa-chart-pie" />
        <span slot="title">Relatórios</span>
      </template>
      <el-menu-item 
        v-for="(menu, index) in report_menu"
        :key="index"
        :index="`3-${index}`"
        :route="menu"
      >
        <i
          :class="menu.meta.iconClass"
          :style="{color: nitro_color}"
        />
        <span>{{ menu.meta.label }}</span>
      </el-menu-item>
    </el-submenu>
    <el-menu-item
      index="0"
      :route="subject_route"
    >
      <i
        :class="subject_route.meta.iconClass"
        :style="{color: nitro_color}"
      />
      <span>{{ subject_route.meta.label }}</span>
    </el-menu-item>
    <el-menu-item
      v-for="(menu, index) in default_menu"
      :key="index"
      :index="`2-${index}`"
      :route="menu"
      @select="default_menu = `2-${index}`"
    >
      <i
        :class="menu.meta.iconClass"
        :style="{color: nitro_color}"
      />
      <span>{{ menu.meta.label }}</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
import { nitro_color, theme_color } from '@/constants/colors'
import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        nitro_color,
        theme_color,
        default_active: '0',

      }
    },
    computed: {
      ...mapGetters(['permissions']),
      routes(){
        return this.$router.options.routes[1].children
      },
      dashboard_route(){
        return this.routes.find(r=>r.name == 'view-dashboard')
      },
      subject_route() {
        return this.routes.find(r => r.name == 'view-subject')
      },
      default_menu(){
        return this.routes.filter(r => this.permissions.includes(r.name) && !r.meta.category && r.name != 'view-dashboard')
      },
      content_menu(){
        return this.routes.filter(r=>  this.permissions.includes(r.name) && r.meta.category == 'content')
      },
      maintenance_menu(){
        return this.routes
        .filter(r=>  r.meta.category == 'maintenance')
        .filter(r => this.permissions.includes(r.name) || r.name.includes('maintenance'))
        
      },
      report_menu(){
        return this.routes.filter(r=>  this.permissions.includes('view-report') && r.meta.category == 'report')
      },
      default_opened() {
        return this.content_menu.map(r=>r.name).includes(this.$route.name) ? ['1'] : this.report_menu.map(r=>r.name).includes(this.$route.name)? ['3'] : []
      }
    }
  };
</script>