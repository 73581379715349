<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="nome"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="Descrição"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="descrição"
            show-word-limit
            maxlength="190"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="Trilha"
          prop="trail_id"
          :error="errors.trail_id"
        >
          <el-select
            v-model="form.trail_id"
            filterable
            placeholder="Selecione a área de interesse"
            class="w-100"
          >
            <el-option
              v-for="item in active_trails"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="form.id">
      <el-col>
        <el-form-item
          label="Ordenação dos módulos de carreira"
          prop="theme_order"
          :error="errors.theme_order"
        >
          <ModulesOrder v-if="theme_block.themes.length" />
          <div v-else>
            Não há módulos a serem ordenados para esse bloco
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import ModulesOrder from './ModulesOrder.vue';

export default {
  components:{ ModulesOrder },
  mixins:[ RequestErrors ],
  data() {
    return {
      rules
    }
  },
  computed: {
    ...mapGetters(['theme_block','active_trails']),
    form: {
      get() {
        return this.theme_block;
      },
      set(theme_block) {
        this.SET_THEME_BLOCK(theme_block);
      }
    }
  },
  mounted() {
    if(this.active_trails.length == 0){
      this.getTrails();
    }
  },
  methods: {
    ...mapMutations(['SET_THEME_BLOCK']),
    ...mapActions(['createThemeBlock','updateThemeBlock','getTrails']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const data = {
            trail_id: this.form.trail_id,
            name: this.form.name,
            description: this.form.description,
            theme_order: this.theme_block ? this.form.themes.map((t,i)=> {
              return {
                id: t.id,
                order: i,
              }
            }) : []
          }
          const action = this.theme_block && this.theme_block.id ? this.updateThemeBlock({data, id: this.theme_block.id}) : this.createThemeBlock({data});
          action
            .then(() => {
              this.$message({
                message: 'Bloco temático salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
  },
}
</script>
