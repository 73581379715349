import { Message } from 'element-ui';
import { Loading } from 'element-ui';

export default {
    state: {
        user: null,
        isLoadingAuthUser: false,
        isLoggingOut: false,
        isLoggingIn: false,
    },
    actions: {
        getAuthUser({commit, state}) {
            state.isLoadingAuthUser = true
            return axios.get(`/admin/me`)
            .then(r => {
                commit("SET_AUTH_USER", r.data.data);
                return r
            })
            .finally(()=> state.isLoadingAuthUser = true);
        },
        logout({commit, state}){
            const loading = Loading.service({
                text: 'Saindo',
                fullscreen: true,
            });
            state.isLoggingOut = true
            return axios.get(`/admin/logout`)
            .then(r => {
                commit("SET_AUTH_USER", null);
                return r
            })
            .finally(()=> {
                state.isLoggingOut = false
                loading.close();
            });
        },
        login({commit, state}, {data}){
            const loading = Loading.service({
                text: 'Logando',
                fullscreen: 'true',
            });
            state.isLoggingIn = true
            return axios.post(`/admin/login`, data)
            .then(r => {
                commit("SET_AUTH_USER", r.data.data);
                Message({
                    type: 'success',
                    message: 'Login realizado com sucesso'
                })
                return r
            })
            .finally(()=> {
                state.isLoggingIn = false
                loading.close();
            });
        }
    },
    mutations: {
        SET_AUTH_USER(state, payload) {
            state.user = payload;
        },
    },
    getters: {
        authUser: state => state.user,
        is_on_semester_gap: state => state.user.is_on_semester_gap,
        permissions: state =>  state.user ? state.user.permissions : [],
    }
};
