export default {
    title: [
      { required: true, message: 'O título é obrigatório.', trigger: 'blur' },
      { min: 5, max: 100, message: 'O título deve ter entre 5 e 100 caracteres.', trigger: 'blur' }
    ],
    url: [
      { required: true, message: 'O campo url é obrigatório.', trigger: 'blur' },
      { min: 10, max: 190, message: 'A url deve ter entre 10 e 190 caracteres.', trigger: 'blur' }
    ],
    trail_id: [
      { required: true, message: 'O campo trilha é obrigatório.', trigger: 'blur' },
    ],
    module_id: [
      { required: true, message: 'O campo módulo é obrigatório.', trigger: 'blur' },
    ],
    description: [
      { max: 325, message: 'O campo deve ter no máximo 325 caracteres.', trigger: 'blur' },
    ]
  }
  