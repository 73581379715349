<template>
  <div>
    <p>
      <span class="font-weight-bold">Descrição:</span> {{ question.description }}
    </p>
    <p>
      <span class="font-weight-bold">Disciplina:</span> {{ question.subject }}
    </p>
    <p>
      <span class="font-weight-bold">Módulo:</span> {{ question.module }}
    </p>
    <p>
      <span class="font-weight-bold">Questões disponíveis no módulo da questão:</span> {{ question.count_module_available_challenge_questions }}
      <span :class="`small ${ hasMinimumQuestions ? 'text-success' : 'text-warning'}`"> 
        O módulo desta questão tem {{ `${question.count_module_available_challenge_questions} / ${question.default_student_challenge_questions_per_module_qty}` }}  
        questões de desafio e {{ hasMinimumQuestions ? '': 'não ' }} poderá ser sugerido para o aluno.
      </span>
    </p>
    <p>
      <span class="font-weight-bold">Alunos vinculados a questão:</span> {{ question.count_students }}
    </p>
              
    <p>
      <span class="font-weight-bold">Status:</span> {{ question.status }}
    </p>
  </div>
</template>
<script>
export default {
    props:{
        question: {
            type:Object,
            required: true,
        }
    },
    computed:{
      hasMinimumQuestions(){
        return !this.question.disabled && (this.question.count_module_available_challenge_questions >= this.question.default_student_challenge_questions_per_module_qty)
      }
    }
}
</script>