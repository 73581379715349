<template>
  <a
    class="btn btn-link dropdown-item text-primary"
    @click="setData"
  >
    <slot>Ver Histórico de Alteração</slot>
  </a>
</template>

<script>

import {mapMutations} from "vuex";

export default {
    props: {
      items:{
        type: Array,
        required:true,
      },
      title:{
        type:String,
        required:true,
      }
    },
    methods:{
      ...mapMutations([
        "SET_HISTORY"
      ]),
      setData(){
        this.SET_HISTORY({items:this.items,title:this.title});
      }
    }
}
</script>
