<template>
  <el-dialog
    :title="history.title"
    :visible.sync="open"
    width="50%"
    :before-close="handleClose"
  >
    <div>
      <el-timeline v-if="history.items && history.items.length">
        <el-timeline-item
          v-for="(activity, index) in history.items"
          :key="index"
          :type="activity.type"
          :timestamp="activity.created_at"
        >
          <span v-html="activity.title" />
        </el-timeline-item>
      </el-timeline>
      <div
        v-else
        class="text-center"
      >
        <h3><span class="badge bg-warning text-dark p-2">Não há Histórico de Alterações</span></h3>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data(){
    return {
      open:false,
    }
  },
    computed:{
      history() {
        return this.$store.state.history;
      }
    },
    watch: {
      history(){
        this.open = !this.open
      }
    },
    methods:{
      handleClose(){
        this.$store.state.history = ''
      }
    }
}
</script>
