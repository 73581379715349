<template>
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="mb-0">
      <slot name="title">
        {{ $route.meta.label }}
      </slot>
    </h3>
    <slot name="right">
      <el-button
        v-if="canCreate || permissions.includes(`create-${$route.name.split('-')[1]}`)"
        icon="fa fa-plus-circle"
        type="primary"
        @click.prevent="$emit('onCreate')"
      />
    </slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    canCreate:{
      type:Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters(['permissions']),
  }
}
</script>