export default {
  description: [
    { required: true, message: 'O campo de descrição é obrigatório', trigger: 'blur' },
    { min: 1, max: 190, message: 'O campo descrição deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  module_id: [
    { required: true, message: 'É obrigatório selecionar um módulo', trigger: 'blur' },
  ],
  order: [
    { required: true, message: 'É obrigatório selecionar a ordem em que a questão aparece', trigger: 'blur' },
  ],
  type: [
    { required: true, message: 'O campo tipo é obrigatório', trigger: 'blur' },
  ],
}
