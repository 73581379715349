<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingRoles && roles.length == 0"
      animated
    >
      <el-table
        :data="roles"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <p>
                O {{ props.row.name }} pode :
                <el-table
                  :data="props.row.permissions.filter(data => !search || data.label.toLowerCase().includes(search.toLowerCase()))"
                  :default-sort="{prop: 'label', order: 'ascending'}"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="label"
                    label="Ação"
                    sortable
                  />
                  <el-table-column
                    align="right"
                  >
                    <template
                      slot="header"
                      slot-scope="scope"
                    >
                      <el-input
                        v-model="search"
                        size="mini"
                        placeholder="Pesquisar"
                        clearable
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="Descrição"
                  />
                </el-table>
              </p>
              <el-button-group>
                <el-button
                  v-if="permissions.can_delete_role"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="100"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  data(){
    return { search: ''}
  },
  computed:{
    ...mapGetters(['permissions','roles','isLoadingRoles']),
  },
  methods:{
    ...mapActions(['deleteRole']),
    onDelete(Role){
      this.$confirm('Tem certeza que deseja apagar o cadastro de função ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteRole(Role)
      .then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

