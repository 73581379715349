import moment from "moment";

export default {
    state: {
        list: [],
        isLoadingGlobalCycleProgress: false
    },
    actions: {
        getGlobalCycleProgress({commit, state}) {
            state.isLoadingLoginsPerCycle = true
            return axios.get(`/admin/report/global-cycle-progress`)
            .then(response => {1

              commit('SET_GLOBAL_CYCLE_PROGRESS_LIST', response.data.data.map(c => ({
                ...c,
                begin_formatted : moment(c.begin).format('DD/MM/YYYY hh:mm'),
                end_formatted: moment(c.end).format('DD/MM/YYYY hh:mm'),
              })))

              return response

            })
            .finally(()=> state.isLoadingLoginsPerCycle = true);
        },

    },
    mutations: {
        SET_GLOBAL_CYCLE_PROGRESS_LIST(state, payload) {
            state.list = payload;
        },
    },
    getters: {
        global_cycle_progress_list: state => state.list,
        is_loading_global_cycle_progress: state => state.isLoadingGlobalCycleProgress,
    }
};
