<template>
  <div>
    <el-skeleton
      :rows="10"
      :loading="isLoadingCycleConfigs && cycle_configs.length == 0"
      animated
    >
      <el-table
        :data="cycle_configs"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <el-button-group>
                <!-- <el-button
                  size="mini"
                  type="info"
                  @click="$loading(); getPageLogs(props.row).then(()=>$loading().close())"
                >
                  Histórico de mudanças
                </el-button> -->
                <el-button
                  v-if="permissions.includes('delete-cycle')"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Início"
          prop="begin_formatted"
        />
        <el-table-column
          label="Fim"
          prop="end_formatted"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permissions','cycle_configs','isLoadingCycleConfigs']),
  },
  methods:{
    ...mapMutations(['SET_HISTORY', 'SET_CYCLE_CONFIG']),
    ...mapActions(['deleteCycleConfig']),
    onDelete(cycle_config){
      this.$confirm('Tem certeza que deseja apagar o ciclo ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteCycleConfig(cycle_config)
      .then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

