<template>
  <el-form
    ref="form"
    :model="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :md="4">
        <el-form-item label="Aluno">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar por aluno"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="3">
        <el-form-item label="Escola">
          <el-select
            v-model="form.school_id"
            placeholder="Escola"
            clearable
            filterable
            class="w-100"
          >
            <el-option
              v-for="school in schools"
              :key="school.id"
              :label="`${school.name} (${school.count_active_students} alunos)`"
              :value="school.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Ciclo">
          <el-select
            v-model="form.cycle_config_id"
            placeholder="Ciclo"
            clearable
            class="w-100"
          >
            <el-option
              v-for="cycle in cycle_configs"
              :key="cycle.id"
              :label="`${cycle.cycle_name} (${cycle.year})`"
              :value="cycle.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Série">
          <el-select
            v-model="form.grade_id"
            placeholder="Série"
            clearable
          >
            <el-option
              v-for="grade in grades"
              :key="grade.id"
              :label="grade.name"
              :value="grade.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="5">
        <el-form-item label="Tipo">
          <el-radio-group v-model="form.challenge_type">
            <el-radio label="print">Impresso</el-radio>
            <el-radio label="online">Online</el-radio>
            <el-radio label="">Todos</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :md="7">
        <el-form-item label="Estado">
          <el-radio-group v-model="form.finished">
            <el-radio label="unfinished">Não Finalizados</el-radio>
            <el-radio label="finished">Finalizados</el-radio>
            <el-radio label="">Todos</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search: '',
        cycle_config_id: '',
        grade_id: '',
        school_id: '',
        challenge_type : '',
        finished: null
      },
    }
  },
  computed: {
    ...mapGetters(['student','students','schools','cycle_configs','grades']),
    filters() {
      let filters = `&search=${this.form.search}&cycle_config_id=${this.form.cycle_config_id}&school_id=${this.form.school_id}&grade_id=${this.form.grade_id}`
      filters += ['print','online'].includes(this.form.challenge_type) ? `&challenge_type=${this.form.challenge_type}`:''
      filters += this.form.finished == 'finished' ? `&finished`:''
      filters += this.form.finished == 'unfinished' ? `&unfinished`:''
      return filters;
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted(){
    if(this.schools.length == 0 ){
      this.getSchools('order_by=name&order_direction=asc&per_page=1000');
    }
    if(this.grades.length == 0 ){
      this.getGrades()
    }
    this.getCycleConfigs()
  },
  methods:{
    ...mapActions(['getSchools','getCycleConfigs','getGrades']),
  },
}
</script>
