<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :md="6">
        <el-form-item label="Nome">
          <el-input
            v-model="form.search"
            placeholder="Digite o nome do módulo ou disciplina"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Disciplinas">
          <el-select
            v-model="form.subject_id"
            placeholder="Selecione a disciplina"
            clearable
          >
            <el-option
              v-for="(s, index) in subjects"
              :key="index"
              :label="s.name"
              :value="s.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="Status">
          <el-radio-group
            v-model="form.published"
            size="medium"
          >
            <el-radio label="published">
              Habilitado
            </el-radio>
            <el-radio label="unpublished">
              Desabilitado
            </el-radio>
            <el-radio label="">
              Todos
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search:'',
        subject_id: '',
        published: ''
      },
    }
  },
  computed:{
    ...mapGetters(['subjects']),
    filters(){
      return `&search=${this.form.search}&subject_id=${this.form.subject_id}&published=${this.form.published}`
    }
  },
  watch:{
    filters: {
      handler(){
       this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted(){
    if(this.subjects.length == 0)
      this.getSubjects();
  },
  methods:{
    ...mapActions(['getSubjects',]),
  }
}

</script>

