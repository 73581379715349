export default {
    begin: [
      { required: true, message: 'A data início é obrigatória.', trigger: 'blur' },
    ],
    end: [
      { required: true, message: 'A data fim é obrigatória.', trigger: 'blur' },
    ],
    year: [
      { required: true, message: 'O ano é obrigatório.', trigger: 'submit' },
    ],
    order: [
      { required: true, message: 'Selecione o ciclo', trigger: 'submit' },
    ],
    module_qty: [
      { required: true, message: 'Selecione a quantidade padrão de módulos no ciclo', trigger: 'submit' },
    ],
    begin_challenge_apply: [
      { required: true, trigger: 'submit', message: 'Escolha uma data padrão de início para a janela de aplicação',},
    ],
    end_challenge_apply: [
      { required: true, trigger: 'submit', message: 'Escolha uma data padrão de fim para a janela de aplicação',},
    ],
    show_challenge_result: [
      { required: true, trigger: 'submit', message: 'Escolha quando o desafio será exibido',},
    ],
    challenge_type: [
      { required: true, trigger: 'submit', message: 'Escolha o tipo padrão de desafio para o ciclo.',},
    ],
}
