<template>
  <el-button
    v-loading="isLoading"
    type="danger"
    icon="el-icon-refresh-left"
    circle
    :disabled="!processPackage || !permissions.includes('delete-package') || processPackage.status == 'challenge_processed'"
    @click="onClick"
  />
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
  props:{
    processPackage: {
        type: Object,
        default: null,
        required: false,
      }
    },
    data(){
      return {
        isLoading: false
      }
    },
    computed :{
      ...mapGetters(['process_packages','permissions']),
    },
    methods:{
      ...mapMutations(['SET_PROCESS_PACKAGES']),
      onClick(){
        this.$confirm('Essa ação vai modificar o status do pacote para que apenas os arquivos sejam reprocessados. Essa ação não impacta os módulos escolhidos. Deseja continuar ?', 'Warning', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.onRestart()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
      },
      onRestart(){
        this.isLoading = true
        axios.post(`/admin/maintenance/restart-process-package/${this.processPackage.id}`)
        .then(()=> {
          this.$emit('changed', this.processPackage)
          this.$message({
            message: 'Pacote reiniciado com sucesso',
            type: 'success'
          });
          this.SET_PROCESS_PACKAGES(this.process_packages.map(p=>{
            if(p.id == this.processPackage.id){
              p.status = 'challenge_processed'
            }
            return p
          }))
        })
        .finally(()=>this.isLoading = false)
      },
    },
    
  }

</script>
