<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Descrição/Título"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="description"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>
      <el-col v-if="permissions.includes('update_order-challenge_question')">
        <el-form-item
          label="Ordem"
          prop="order"
        >
          <el-row :gutter="20">
            <el-col :md="20">
              <el-select
                v-model="form.order"
                filterable
                clearable
                placeholder="Selecione a ordem"
                class="w-100"
              >
                <el-option
                  v-for="item in [1,2,3,4,5,6]"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-col>
            <el-col :md="4">
              <el-select
                v-model="form.level"
                clearable
                placeholder="Selecione o nível"
                filterable
              >
                <el-option
                  v-for="i in level"
                  :key="i.value+1"
                  :label="i.name"
                  :value="i.value"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Módulo"
          prop="module_id"
          :error="errors.module_id"
        >
          <el-radio
            v-model="showModulesType"
            label=""
          >
            Todos
          </el-radio>
          <el-radio
            v-model="showModulesType"
            label="pre_cycle"
          >
            Módulos pré-ciclo
          </el-radio>
          <el-radio
            v-model="showModulesType"
            label="theme"
          >
            Módulos de carreira
          </el-radio>
          <el-radio
            v-model="showModulesType"
            label="default"
          >
            Módulos de desempenho
          </el-radio>
          <el-select
            v-model="form.module_id"
            filterable
            placeholder="Módulo"
            class="w-100"
          >
            <el-option
              v-for="item in module_list"
              :key="item.id"
              :label="`${item.name} (${item.type == 'default' ? 'desempenho' : item.type == 'pre_cycle' ? 'pre-ciclo' : 'carreira'})`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Enunciado"
          class="w-100"
        >
          <p class="text-danger small">
            * Tamanho máximo de upload de imagem é 2048 KB
          </p>
          <ckeditor
            v-model="form.content"
            :editor="editor"
          />
          <!-- <vue-editor id="editor" v-model="form.content"></vue-editor> -->
        </el-form-item>
        <p
          v-if="errors.content"
          class="text-danger small"
        >
          {{ errors.content }}
        </p>
      </el-col>

      <el-col class="py-2">
        <span class="text-danger small">* </span>Resposta correta:
        <el-radio-group v-model="form.answer">
          <el-radio
            v-for="answer in answers"
            :key="answer"
            :label="answer"
          >
            {{ answer.toUpperCase() }}
          </el-radio>
        </el-radio-group>
        <p
          v-if="errors.answer"
          class="text-danger small"
        >
          {{ errors.answer }}
        </p>
      </el-col>
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="onCancel"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import RequestErrors from '@/admin/utils/RequestErrors'
import FormRules from './FormRulesChallenge'
import editor from '@/admin/utils/Editor'

export default {
  mixins: [RequestErrors],
  props: {
    challengeQuestion: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {

    return {
      answers: ['a', 'b', 'c', 'd', 'e'],
      editor,
      checked: false,
      FormRules,
      form: null,
      showModulesType: '',
      CyclesEnabledChangeOrder: [4, 5, 8, 1],

      level: [
        { value: 1, name: 'Difícil' },
        { value: 2, name: 'Médio' },
        { value: 3, name: 'Fácil' }
      ]
    }
  },

  computed: {
    ...mapGetters(['modules_and_themes', 'modules', 'themes', 'challenge_questions', 'challenge_question', 'cycle_configs', 'permissions', 'is_on_semester_gap','pre_cycle_modules']),
    module_list(){
      if(this.showModulesType == 'theme'){
        return this.themes
      }
      if(this.showModulesType == 'pre_cycle'){
        return this.pre_cycle_modules
      }
      if(this.showModulesType == 'default'){
        return this.modules
      }

      return this.modules_and_themes
    }
  },
  created() {
    this.setFormDefault()
  },
  mounted() {
    if (this.challengeQuestion) {
      setTimeout(() => {
        this.form = this.challengeQuestion
      }, 500)
    }
    if (this.modules.length <= 15) {
      this.getModules()
    }
    if (this.themes.length <= 15) {
      this.getThemes()
    }
    this.getCycleConfigs()
  },
  methods: {
    ...mapActions([
      'getModules',
      'getThemes',
      'createChallengeQuestion',
      'updateChallengeQuestion',
      'getCycleConfigs'
    ]),
    setFormDefault() {
      this.form = {
        description: '',
        order: '',
        content: '',
        module_id: '',
        answer: 'a'
      }
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {

        if (valid) {
          this.$loading({
            text: 'Salvando ...'
          })
          const action = this.challengeQuestion ? this.updateChallengeQuestion({
            data: { ...this.form, checked: this.checked },
            id: this.challengeQuestion.id
          }) : this.createChallengeQuestion({ data: this.form })
          action
            .then(() => {
              this.$message({
                message: 'Questão salva com sucesso.',
                type: 'success'
              })
              this.$loading()
              this.setFormDefault()
              this.$emit('saved')
            })
            .catch(e => this.showDefaultErrorMessages(e))
            .finally(() => this.$loading().close())
        } else {
          return false
        }
      })
    },
    onCancel() {
      this.setFormDefault()
      this.$emit('cancel')
    }
  }
}
</script>
