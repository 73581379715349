<template>
  <div :style="`min-height: ${minHeight}`">
    <iframe
      v-if="type === 'scorm' || type === 'video'"
      :src="contentUrl"
      :style="`
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  min-height: ${minHeight};
                `"
      class="top-0 bottom-0 w-100 h-100 left-0 right-0 h-100 border-0"
    />
    <ModuleLinkComponent
      v-if="type === 'link'"
      :link="content.link_content"
    />
    <ModuleQuestionsComponent
      v-if="type === 'quiz'"
      :questions="content.quiz_content"
    />
    <ModulePageComponent
      v-if="type === 'page'"
      :page="content.page"
    />
  </div>
</template>
<script>
import ModuleQuestionsComponent from './ModuleQuestionComponent'
import ModulePageComponent from './ModulePageComponent'
import ModuleLinkComponent from './ModuleLinkComponent'

export default {
  components: {
    ModuleQuestionsComponent,
    ModuleLinkComponent,
    ModulePageComponent
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    minHeight() {
      return "60vh"
    },
    contentUrl() {
      return this.type === 'video'
        ? this.content.video.content
        : this.content.scorm.url
    },
    type() {
      if (this.content.is_video) return 'video'
      if (this.content.is_page) return 'page'
      if (this.content.is_scorm) return 'scorm'
      if (this.content.is_link) {
        return 'link'
      } else {
        return 'quiz'
      }
    }
  }
}
</script>
