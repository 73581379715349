<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <PageHeader
          slot="header"
          :can-create="false"
        />
        <el-alert
          title="Aviso"
          type="info"
          description="Caso seja salvo um nova ordenação ou salvo a ordenação atual o sistema sempre irá reprocessar a atribuição das respostas e nota do desafio de acordo com o cartão resposta para desafios impressos que tenham um cartão upado."
          show-icon
        />
        <Filters v-model="filters" />

        <el-skeleton
          :rows="10"
          :loading="isLoadingStudents && students.length == 0"
          animated
        >
          <el-table
            ref="tableData"
            :data="students"
            style="width: 100%"
            row-key="id"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="p-4">
                  <el-row>
                    <el-col>
                      <OrderEditor 
                        :cycle-config-id="props.row.selected_modules[0].cycle_config_id"
                        :user-id="props.row.id"
                        :modules="props.row.selected_modules"
                      />
                    </el-col>
                  </el-row>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="RA"
              prop="identifier"
              width="120"
            />
            <el-table-column
              label="ID"
              prop="id"
            />
            <el-table-column
              label="Nome"
              prop="name"
            />
            <el-table-column
              label="Email"
              prop="email"
            />
            <el-table-column
              label="Série"
              prop="grade_name"
            />
            <el-table-column
              label="Escola"
              prop="school_name"
            />
            <el-table-column
              label="Tipo de desafio"
              prop="cycle_grade_config.challenge_type"
            />
            <el-table-column
              fixed="right"
              label="Ações"
              width="220"
            >
              <template slot-scope="{ row }">
                <el-button-group>
                  <el-button
                    size="mini"
                    :type="isDetached(row.selected_modules) ? 'danger': 'primary'"
                    @click="$refs.tableData.toggleRowExpansion(row)"
                  >
                    Ver módulos
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>
        
        <div :class="`d-flex justify-content-${students_selection_list.length ? 'between': 'center'} p-2`">
          <el-pagination
            background
            :page-sizes="[10, 50, 100, 500]"
            layout="sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import Filters from "@/admin/Pages/maintenance/student_module_order/components/Filters"
import OrderEditor from "@/admin/Pages/maintenance/student_module_order/components/OrderEditor"
import { mapActions, mapGetters } from 'vuex'
import { defaultLoadingConfig } from "@/admin/constants/general"
import RequestErrors from "@/admin/utils/RequestErrors";

export default {
  components: {
    Filters,
    OrderEditor,
  },
  mixins:[RequestErrors],
    data() {
        return {
          filters:'',
          registering: false,
          showing: false,
          meta : {
              current_page: 1,
              total: 10,
              per_page: 10,
          },
          showSelectionModal: false,
        }
    },
    computed:{
      ...mapGetters(['permissions','student','students','students_selection_list','isLoadingStudents']),
      params(){
        return `${this.filters}&page=${this.meta.current_page}&per_page=${this.meta.per_page}&order_by=name&order_direction=asc`
      }
    },
    watch:{
      params: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getStudents(this.params)
                .then((r)=> {
                    const { data :{meta} } = r
                    this.meta.per_page = Number(meta.per_page);
                    this.meta.current_page = meta.current_page;
                    this.meta.total = meta.total;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
      },
    },
    methods:{
        ...mapActions(['getStudents']),
        isDetached(modules){
          let m = modules.filter(m => !m.block_id) 
          return m.filter(m=>m.knowledge_area_id == 1).length > 1
          || m.filter(m=>m.knowledge_area_id == 2).length > 1
          || m.filter(m=>m.knowledge_area_id == 3).length > 1
          || m.filter(m=>m.knowledge_area_id == 4).length > 1
        }
    },
}
</script>
