<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingSchools && schools.length == 0"
      animated
    >
      <el-table
        ref="multipleTable"
        :data="schools"
        style="width: 100%"
        row-key="id"
      >
        <el-table-column
          label="id"
          prop="id"
          width="120"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Total alunos"
          prop="count_students"
          width="130"
        />
        <el-table-column
          label="Alunos ativos"
          prop="count_active_students"
          width="130"
        />
        <el-table-column
          label="Alunos inativos"
          prop="count_deactivated_students"
          width="130"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StudentListComponent',
  computed:{
    ...mapGetters(['permissions','schools','isLoadingSchools']),
  },
}
</script>

