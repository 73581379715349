<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Resetar o arquivos de desafio do aluno"
    placement="top-center"
  >
    <el-button
      v-loading="isLoading"
      type="danger"
      icon="el-icon-refresh-left"
      :disabled="challenge.challenge_type != 'print' || !!challenge.finished_at "
      circle
      @click="onClick"
    />
  </el-tooltip>
</template>
<script>
  import {mapActions} from 'vuex'

  export default {
  props:{
      challenge: {
        type: Object,
        required: true,
      }
    },
    data(){
      return {
        isLoading: false
      }
    },
    methods:{
      ...mapActions(['resetChallenge']),
      onClick(){
        this.isLoading = true
        this.resetChallenge({data: {
          challenge_id: this.challenge.id
        }})
        .then(()=> {
          this.$message({
            message: 'Desafio reiniciado com sucesso',
            type: 'success'
          });
        })
        .finally(()=>this.isLoading = false)
      }
    }
  }

</script>
