<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            clearable
            name="description"
            show-word-limit
            maxlength="190"
            placeholder="Descrição"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Arquivo"
          class="w-100"
          prop="file"
          :error="errors.file"
        >
          <input
            id="file_field"
            ref="file"
            type="file"
            @change="onChangeFile"
          >
        </el-form-item>
        <a
          v-if="form.id"
          :href="form.file.url"
          target="_blank"
        >{{ form.file.original_name }}
        </a>
      </el-col>
    </el-row>
    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules,
    }
  },
  computed: {
    ...mapGetters(['ebook']),
    form: {
      get() {
        return this.$store.state.ebook.current;
      },
      set(ebook) {
        this.SET_EBOOK(ebook);
      }
    }
  },
  methods: {
    ...mapMutations(['SET_EBOOK']),
    ...mapActions(['createEbook','updateEbook']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.ebook.id ? this.updateEbook({data: this.getData(), id: this.ebook.id}) : this.createEbook({data: this.getData()});
          action
            .then(() => {
              this.$message({
                message: 'Ebook salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
    getData() {
      let formData = new FormData();
      formData.append('name', this.form.name)
      formData.append('description', this.form.description)
      if(this.form.file)
        formData.append('file', this.form.file)
      if(this.form.id)
        formData.append('_method','put');

      return formData;
    },
    onChangeFile(e){
      this.form.file = e.target.files ? e.target.files[0] : '';
    },
  },
}
</script>
