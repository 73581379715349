export const nitro = '#060181';
export const nitro_color = nitro;
export const success_color = "#01A75A";
export const danger_color = "#B20000";
export const challenge_color = '#43CBDE';
export const theme_color = '#f74a4b';
export const contrast_light_color = "#fff"
export const contrast_dark_color = "#5e5669ad"
export const module_color = nitro
export const ligthen_color = '#FFF9DB';


export default {
  live: '#00DDE7',
  challenge: challenge_color,
  module: module_color,
  extra_content: '#00B2FF',
  nitro
}

export const solidgauge_fill_bg = '#ebebeb';
