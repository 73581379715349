<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :md="6">
        <el-form-item label="Nome">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label="Área de interesse">
          <el-select
            v-model="form.trail_id"
            clearable
            placeholder="Selecione a área de interesse"
            filterable
          >
            <el-option
              v-for="(trail, index) in trails"
              :key="index"
              :label="`${trail.name} ${trail.active ? '' : '(inativa)'}`"
              :value="trail.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label="Blocos temáticos">
          <el-select
            v-model="form.block_id"
            clearable
            placeholder="Filtro por bloco"
            filterable
          >
            <el-option
              v-for="(block, index) in theme_blocks"
              :key="index"
              :label="`${block.name} - ${ block.trail.name } (${block.count_themes}) (${block.cycle_percentage_completion}%)`"
              :value="block.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label="Ordenação">
          <el-select
            v-model="form.order"
            clearable
            placeholder="Filtro por ordenação"
            filterable
          >
            <el-option
              v-for="i in [0,1,2,3]"
              :key="i"
              :label="`Ordem ${i +1 }`"
              :value="i"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search:'',
        trail_id: '',
        block_id: '',
        order: '',
      },
    }
  },
  computed:{
    ...mapGetters(['theme_blocks', 'trails']),
    filters(){
      return `&search=${this.form.search}&trail_id=${this.form.trail_id}&block_id=${this.form.block_id}&order=${this.form.order}`
    }
  },
  watch:{
    filters: {
      handler(){
       this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted(){
    this.getTrails();
    this.getThemeBlocks();
  },
  methods:{
    ...mapActions(['getTrails','getThemeBlocks']),
  }
}

</script>

