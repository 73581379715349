<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader
          slot="header"
        >
          <template #right>
            <el-button
              type="primary"
              :loading="is_exporting"
              @click="exportData()"
            >
              Baixar relatório <i class="fa fa-download" />
            </el-button>
          </template>
        </PageHeader>

        <el-form
          :model="form"
          class="container-fluid"
          label-width="120px"
          label-position="top"
        >
          <el-row :gutter="20">
            <el-col :md="12">
              <el-form-item label="Pesquisar">
                <el-input
                  v-model="form.search"
                  clearable
                  :disabled="is_loading"
                  placeholder="Pesquisar por nome aluno, RA, Id"
                />
              </el-form-item>
            </el-col>
            <el-col :md="3">
              <el-form-item label="Escolas">
                <el-select
                  v-model="form.school_id"
                  placeholder="Selecionar escolas"
                  :disabled="is_loading"
                  collapse-tags
                  filterable
                  multiple
                >
                  <el-option
                    v-for="(loop_school, key) in schools.filter(s=>s.count_active_students >0)"
                    :key="key"
                    :label="loop_school.search"
                    :value="loop_school.id"
                  >
                    {{ `${loop_school.name} (${loop_school.count_active_students} alunos)` }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4">
              <el-form-item label="Ciclo">
                <el-select
                  v-model="form.cycle_config_id"
                  placeholder="Selecionar um ciclo"
                  :disabled="is_loading"
                  collapse-tags
                  multiple
                >
                  <el-option
                    v-for="cycle in cycle_configs"
                    :key="cycle.id"
                    :label="cycle.name"
                    :value="cycle.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="3">
              <el-button
                type="primary"
                icon="el-icon-search"
                style="margin-top:58px"
                @click="getReports()"
              >
                Pesquisar
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-skeleton
          :rows="meta.per_page"
          :loading="is_loading"
          animated
          class="mx--4"
        >
          <el-table
            :data="students"
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="p-4">
                  <p>Quantidade de logins no ciclo : {{ props.row.count_logins_on_cycle }}</p>
                  <p>Módulos selecionados no ciclo {{ props.row.cycle }} : {{ props.row.count_selected_modules }}</p>
                  <p>Módulos iniciados no ciclo {{ props.row.cycle }} : {{ props.row.count_started_modules }}</p>
                  <p>Módulos finalizados no ciclo {{ props.row.cycle }} : {{ props.row.count_finished_modules }}</p>
                  <p>Desafio feito no ciclo {{ props.row.cycle }} ? {{ props.row.count_finished_challenges > 0 ? "Sim" : "Não" }}</p>
                  <p>Quantidade de temas iniciados: {{ props.row.cycle }} : {{ props.row.count_started_themes }}</p>
                  <p>Quantidade de temas finalizados: {{ props.row.cycle }} : {{ props.row.count_finished_themes }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Id"
              prop="id"
              width="80"
            />
            <el-table-column
              label="RA"
              prop="identifier"
              width="110"
            />
            <el-table-column
              label="RE"
              prop="re"
              width="110"
            />
            <el-table-column
              label="Nome"
              prop="name"
              width="300"
            />
            <el-table-column
              label="Id escola"
              prop="schoolid"
              width="80"
            />
            <el-table-column
              label="Escola"
              prop="school_name"
            />
            <el-table-column
              label="Série"
              prop="grade_name"
              width="150"
            />
            <el-table-column
              label="Ciclo"
              prop="cycle_name"
              width="150"
            />
          </el-table>
          <div 
            v-if="students.length>0"
            class="d-flex justify-content-center p-2"
          >
            <el-pagination
              background
              layout="prev, pager, next"
              :total="meta.total"
              :current-page="meta.current_page"
              :page-size.sync="meta.per_page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-skeleton>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import downloadFile from "@/utils/downloadFile.js"
import { mapActions, mapGetters } from 'vuex'

export default{
    data() {
        return {
          is_loading: false,
          is_exporting: false,
          form: {
            cycle_config_id: [],
            school_id: [],
            search: ''
          },
          meta : {
            current_page: 1,
            per_page: 50,
            total: 0
          },
          students: [],
        }
    },
    computed:{
      ...mapGetters(['cycle_configs','schools']),
      filters() {
        let filters =  `&search=${this.form.search}&page=${this.meta.current_page}`
        this.form.cycle_config_id.forEach((c,index)=>filters+=`&cycle_config_id[${index}]=${c}`)
        this.form.school_id.forEach((c,index)=>filters+=`&school_id[${index}]=${c}`)
        return filters
      }
    },
    created(){
      if(this.cycle_configs.length == 0){
        this.getCycleConfigs(`&year=2024`)
      }
      if(this.schools.length == 0){
        this.getSchools(`&year=2024`)
      }
    },
    methods:{
      ...mapActions(['getCycleConfigs','getSchools']),
      handleCurrentChange(page) {
        this.meta.current_page = page;
        this.is_loading = true;
        this.getReports();
      },
      getReports(){
        this.is_loading = true
        axios.get(`/admin/report/student-global?${this.filters}`)
        .then(({data: { data: students , meta }}) => {
          this.students = students.map(s=> {
            s.trail_name = s.trail_name ? s.trail_name : 'Sem trilha';
            return s;
          });
          this.meta.per_page = Number(meta.per_page);
          this.meta.current_page = meta.current_page;
          this.meta.total = meta.total;
          this.is_loading = false;
        });
      },
      exportData(){
        if(this.is_exporting){
          this.$message({ message: 'Em download, aguarde.', type: 'warning', showClose: true})
        } else {
          this.is_exporting = true;
          downloadFile(`/admin/report/student-global/export?${this.filters}`, this.$moment() + "-relatorio-geral-alunos.xlsx")
          .finally(() => this.is_exporting = false)
        }
      }
    },
}
</script>
