<template>
  <transition-group class="transition">
    <li
      v-for="(content, index) in contents"
      :key="content.title"
      class="alert list-group-item list-group-item-light"
    >
      <div class="d-flex w-80 justify-content-between">
        <div class="text-center">
          {{ content.title | subStr(100) }}
          <span class="badge badge-danger ml-2">{{ index + 1 }}°</span>
        </div>
        <div class="d-flex align-self-baseline">
          <div>
            <span
              v-if="content.is_video"
              class="badge badge-info"
            >Vídeo</span>
            <span
              v-if="content.is_scorm"
              class="badge badge-warning"
            >Scorm</span>
            <span
              v-if="content.is_quiz"
              class="badge badge-danger"
            >Quiz</span>
            <span
              v-if="content.is_page"
              class="badge badge-secondary"
            >Página</span>
          </div>
        </div>
      </div>
    </li>
  </transition-group>
</template>
<script>
export default {
  props: {
    contents: {
      type: Array,
      required: true
    }
  }
}
</script>
