<template>
  <el-card
    shadow="never"
    class="mt-4"
  >
    <div slot="header">
      <span>Edicão da janela aplicação da escola <span class="font-weight-bold">{{ config.school_name }}</span> -
        <span class="font-weight-bold">{{ config.grade_name }}</span> - <span class="font-weight-bold">{{ config.cycle_name }}</span>
        de {{ $moment(config.begin).format('DD/MM/YY HH:mm:ss') }} a {{ $moment(config.end).format('DD/MM/YY HH:mm:ss') }}
      </span>
    </div>
    <el-form
      ref="form"
      v-model="form"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :md="24">
          <el-form-item
            label="Tipo de desafio"
            prop="challenge_type"
          >
            <el-radio
              v-model="form.challenge_type"
              label="online"
            >
              online
            </el-radio>
            <el-radio
              v-model="form.challenge_type"
              label="print"
            >
              impresso
            </el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Início janela de aplicação"
            prop="begin_challenge_apply"
            :error="errors.begin_challenge_apply"
          >
            <el-date-picker
              v-model="form.begin_challenge_apply"
              type="datetime"
              placeholder="Início"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="false"
              class="w-100"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Fim janela de aplicação"
            prop="end_challenge_apply"
            :error="errors.end_challenge_apply"
          >
            <el-date-picker
              v-model="form.end_challenge_apply"
              type="datetime"
              placeholder="Início"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="false"
              class="w-100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Liberação de acesso ao desafio"
            prop="release_challenge"
          >
            <el-select
              v-model="form.release_challenge"
              placeholder="Selecione a opção de liberação de acesso ao desafio"
              class="w-100"
            >
              <el-option
                v-for="option in (form.challenge_type == 'print' ? release_challenge_options.filter(o=>o.value != 'after_finish_modules'): release_challenge_options)"
                :key="option.value"
                :label="option.title"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Liberação do resultado"
            prop="show_challenge_result"
          >
            <el-select
              v-model="form.show_challenge_result"
              placeholder="Escolha uma opção"
              class="w-100"
            >
              <el-option
                v-for="option in (form.challenge_type == 'print' ? challenge_release_options.filter(o=>o.value != 'after_finish'): challenge_release_options)"
                :key="option.value"
                :label="option.name"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="form.show_challenge_result == 'after_date'"
          :md="12"
        >
          <el-form-item
            label="Data de liberação"
            prop="release_challenge_result_at"
            :error="errors.release_challenge_result_at"
          >
            <el-date-picker
              v-model="form.release_challenge_result_at"
              type="datetime"
              format="dd/MM/yyyy HH:mm:ss"
              class="w-100"
              :clearable="false"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col cols="24">
          <h3>Janela padrão aplicação de módulos</h3>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Data início módulo 1"
            prop="module_one_begin"
            :error="errors.module_one_begin"
          >
            <el-date-picker
              v-model="form.module_one_begin"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data início módulo 1"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate"
              class="w-100"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Data fim módulo 1"
            prop="module_one_end"
            :error="errors.module_one_end"
          >
            <el-date-picker
              v-model="form.module_one_end"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data fim módulo 1"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate || !form.module_one_begin"
              class="w-100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Data início módulo 2"
            prop="module_two_begin"
            :error="errors.module_two_begin"
          >
            <el-date-picker
              v-model="form.module_two_begin"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data início módulo 2"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate"
              class="w-100"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Data fim módulo 2"
            prop="module_two_end"
            :error="errors.module_two_end"
          >
            <el-date-picker
              v-model="form.module_two_end"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data fim módulo 2"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate || !form.module_two_begin"
              class="w-100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Data início módulo 3"
            prop="module_three_begin"
            :error="errors.module_three_begin"
          >
            <el-date-picker
              v-model="form.module_three_begin"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data início módulo 3"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate"
              class="w-100"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Data fim módulo 3"
            prop="module_three_end"
            :error="errors.module_three_end"
          >
            <el-date-picker
              v-model="form.module_three_end"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data fim módulo 3"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate || !form.module_three_begin"
              class="w-100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item
            label="Data início módulo 4"
            prop="module_four_begin"
            :error="errors.module_four_begin"
          >
            <el-date-picker
              v-model="form.module_four_begin"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data início módulo 4"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate"
              class="w-100"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            label="Data fim módulo 4"
            prop="module_four_end"
            :error="errors.module_four_end"
          >
            <el-date-picker
              v-model="form.module_four_end"
              :picker-options="{ disabledDate:disableModuleDate }"
              type="datetime"
              placeholder="Data fim módulo 4"
              format="dd/MM/yyyy HH:mm:ss"
              :clearable="true"
              :disabled="disableEditModuleDate || !form.module_four_begin"
              class="w-100"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          v-if="config.count_challenges>0"
          :md="24"
        >
          <p class="text-warning">
            * Não é possível editar a janela de aplicação da escola quando já foram gerados desafios para essa série e ciclo.
            <br> A escola {{ config.school_name }} possui {{ config.count_challenges }} desafio gerados em {{ config.grade_name }} no  {{ config.cycle_name }}
          </p>
        </el-col>
        <el-col :md="24">
          <el-form-item label="">
            <el-button
              icon="fa fa-save"
              type="primary"
              :disabled="config.count_challenges>0"
              @click="onSave"
            >
              Salvar
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import RequestErrors from "@/admin/utils/RequestErrors";

export default {
  mixins:[RequestErrors],
  props:{
    config: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
     form: {},
     release_challenge_options:[
      {title: 'Com conclusão dos módulos', value: 'after_finish_modules'},
      {title: 'Sem conclusão dos módulos' ,value:'after_begin_application_dates'}
     ],
     challenge_release_options: [
        { name: 'Primeira data após o encerramento do ciclo', value:'after_cycle'},
        { name: 'Ao final do desafio', value: 'after_finish', disabled: true, active: false},
        { name: 'Data personalizada', value: 'after_date'}
      ],
     rules: {
          cycle_config_id: [
            { required: true, message: 'Selecione um ciclo', trigger: 'blur' },
          ],
          grade_id: [
            { type: 'number', required: true, message: 'Selecione ao menos uma série', trigger: 'blur' },
          ],
          school_id: [
            { type: 'number', required: true, message: 'Selecione ao menos uma escola', trigger: 'blur' },
          ],
          release_challenge_result_at: [
            { required: true},
          ],
          begin_challenge_apply: [
            { required: true, trigger: 'submit'},
          ],
          end_challenge_apply: [
            { required: true, trigger: 'submit'},
          ],
        }
      }
    },
  computed:{
    disableEditModuleDate(){
      return !this.form.year || !this.form.begin || !this.form.end
    },
  },
  watch:{
    "form.challenge_type" : function(value){
      if(value == 'print' ){
        if(this.form.show_challenge_result == 'after_finish'){
          this.form.show_challenge_result = ''
        }
        this.form.release_challenge = 'after_begin_application_dates'
      }
    }
  },

  created(){
    this.form = {
      ...this.config,
      release_challenge: this.config.challenge_type == 'print' ? 'after_begin_application_dates' : this.config.release_challenge,
    };
  },
  methods:{
    disabledDate(date){
      const is_on_selected_year = this.$moment(date).isSameOrAfter(`${this.form.year}-01-01`,'year') && this.$moment(date).isSameOrBefore(`${this.form.year}-01-12`,'year')
      return !this.form.year || !is_on_selected_year
    },
    disableModuleDate(date){
      return !this.form.year || !this.$moment(date).isBetween(this.form.begin, this.form.end)
    },
    onSave(){
      this.$loading({
        text: 'Salvando ...'
      });
      axios.post('/admin/school/application-period', this.form)
      .then(()=>{
        this.errors = {}
        this.$message({
          message: 'Configuração atualizada com sucesso.',
          type: 'success',
        });
      })
      .catch(e=> this.showDefaultErrorMessages(e))
      .finally(()=> this.$loading().close())
    }
  },
}
</script>
