export default {
    state: {
        list: [],
        current: null,
        isLoadingTrails:false,
    },
    actions: {
        getTrails({commit,state}, params = '') {
            state.isLoadingTrails = true;
            return axios.get(`/admin/trail?${params}`)
            .then(r => {
                commit("SET_TRAILS", r.data.data);
                return r;
            })
              .finally(()=>state.isLoadingTrails = false);
        },
        getTrail({commit}, params) {
            return axios.get(`/admin/trail/${params.data.id}`)
            .then(r => {
                commit("SET_TRAIL", r.data.data);
                return r
            });
        },
        createTrail({commit}, params) {
            return axios.post(`/admin/trail/`, params.data, {
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
        },
        updateTrail({commit}, params) {
            return axios.post(`/admin/trail/${params.id}`, params.data,{
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
        },
    },
    mutations: {
        SET_TRAILS(state, payload) {
            state.list = payload;
        },
        SET_TRAIL(state, payload) {
            state.current = payload;
        }
    },
    getters:{
        trails: state => state.list,
        trail: state => state.current,
        isLoadingTrails: state => state.isLoadingTrails,
        active_trails: state => state.list.filter(t => t.active),
    }

};
