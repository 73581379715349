<template>
  <div>
    <p>Respostas:</p>

    <el-row v-show="showEditor">
      <el-col :md="22">
        <p class="text-danger small-size">
          * Tamanho máximo de upload de imagem é 2048 KB
        </p>
        <ckeditor
          ref="editor"
          v-model="answer.content"
          :editor="editor"
        />
      </el-col>
      <el-col class="d-flex align-items-baseline">
        <input
          id="choice"
          v-model="answer.correct"
          :disabled="editing"
          class="mr-2"
          type="checkbox"
          name="choice"
        >
        <label for="choice"> Correta</label>
      </el-col>
      <el-col>
        <el-button
          type="primary"
          :disabled="disabled || loading"
          :loading="loading"
          class="mt-3"
          @click="editing ? saveAnswer() : addAnswer()"
        >
          {{ editing ? 'Concluir' : 'Adicionar' }}
        </el-button>
      </el-col>
    </el-row>


    <div
      v-if="question.choices.length"
      class="my-3"
    >
      <el-alert
        v-for="(choice, key) in question.choices"
        :key="key"
        :title="`Opção ${choice.correct ? 'CORRETA' : 'ERRADA'}`"
        :type="choice.correct ? 'success' : 'error'"
        class="mt-2"
        show-icon
        :closable="false"
      >
        <el-row>
          <el-col :span="20">
            <div
              class="answer-item"
              v-html="choice.content"
            />
          </el-col>
          <el-col
            v-if="dinamic"
            class="flex-column justify-content-center"
          >
            <el-button
              size="mini"
              type="warning"
              icon="fa fa-times"
              @click="editing ? editAnswer(choice) : removeAnswer(choice.content)"
            >
              {{ editing ? 'Editar' : 'Remover' }}
            </el-button>
          </el-col>
        </el-row>
      </el-alert>
    </div>
    <div v-else>
      <el-alert
        title="Nenhuma resposta adicionada"
        type="error"
      />
    </div>
  </div>
</template>
<script>
import editor from "@/admin/utils/Editor";
import { mapGetters, mapMutations } from 'vuex';
import { clone } from "lodash"

export default {
  props:{
    editing: {
      type: Boolean,
      default: true,
    },
    dinamic: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      editor,
      disabled: true,
      loading: false,
      answer: {
        content: '',
        correct: true,
      },
    }
  },
  computed:{
    ...mapGetters(['question']),
    choices(){
      return this.$store.state.question.choices;
    },
    showEditor(){
      return this.dinamic && (this.editing ? this.answer.id ? true : false : true) ? true : false
    },
  },
  watch: {
    "answer.content": {
      handler(val, old) {
          this.loading = true;
          var ocorrenceImgTag = val.split('src').length - 1;
          var ocorrenceSrcTag = val.split('img').length - 1;
          if((ocorrenceImgTag == ocorrenceSrcTag) && (val)) {
            this.disabled = false;
            this.loading = false;
          } else if((ocorrenceImgTag == ocorrenceSrcTag) && (!val))  {
            this.disabled = true;
            this.loading = false;
          } else {
            this.disabled = true;
          }
      },
    }
  },

  methods: {
    ...mapMutations(['SET_QUESTION']),
    onEditorReady( editor )  {
        // Insert the toolbar before the editable area.
          editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader)
          };
    },
    addAnswer() {
      this.SET_QUESTION({...this.question, choices: [
        clone(this.answer),
        ...this.question.choices,
      ]});
      this.answer.content = '';
    },
    editAnswer(answer) {
      this.answer = clone(answer)
    },
    removeAnswer(content) {
      this.SET_QUESTION({...this.question, choices: this.question.choices.filter(a => a.content !== content) })
      this.$emit('change')
    },
    saveAnswer(){
      this.SET_QUESTION({...this.question, choices: this.question.choices.map(a => a.id == this.answer.id ? clone(this.answer) : a ) })
      this.answer = {
        content: '',
        correct: true,
      }
      this.$emit('change')
    }
  },

}
</script>
<style>
.answer-item img {
  max-width: 100%;
  height: auto;
}
.small-size {
  font-size: 70%;
  font-weight: 400;
}
</style>
