import VueI18n from "vue-i18n";
import messages from "../constants/messages";

import Vue from 'vue';
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'ptBr', // set locale
    messages, // set locale messages
});

export default i18n;
