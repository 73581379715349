export default {
  methods: {
    addContent(content) {
      let founded = false
      if (content.is_scorm) {
        founded = this.form.contents.find(m => m.scorm_id === content.id)
      }
      if (content.is_video) {
        founded = this.form.contents.find(m => m.video_id === content.id)
      }
      if (content.is_quiz) {
        founded = this.form.contents.find(m => m.quiz_id === content.id)
      }
      if (content.is_page) {
        founded = this.form.contents.find(m => m.page_id === content.id)
      }
      if (!founded) {
        this.form.contents.push(content)
      } else {
        this.$message({
          message: 'Esse item já existe na sua listagem',
          type: 'error',
          showClose: true
        })
      }
    },
    removeContent(content) {
      if (content.is_scorm) {
        this.form.contents = this.form.contents.filter(a => a.scorm_id !== content.id);
      }
      if (content.is_video) {
        this.form.contents = this.form.contents.filter(a => a.video_id !== content.id);
      }
      if (content.is_quiz) {
        this.form.contents = this.form.contents.filter(a => a.quiz_id !== content.quiz_id);
      }
      if (content.is_page) {
        this.form.contents = this.form.contents.filter(a => a.page_id !== content.id);
      }
    },
  }
}