<template>
  <div>
    <el-skeleton
      :rows="10"
      :loading="isLoadingStudents && students.length == 0"
      animated
    >
      <el-table
        ref="multipleTable"
        :data="students"
        style="width: 100%"
        row-key="id"
      >
        <el-table-column
          label=""
          width="70"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <el-popconfirm
              confirm-button-text="Sim"
              cancel-button-text="Não, Obrigado"
              icon="el-icon-info"
              icon-color="red"
              :title="`Tem certeza que deseja adicionar os ${students.length} items a seleção?`"
              @confirm="onSelectAll"
            >
              <i
                slot="reference"
                class="el-icon-circle-check"
                style="cursor:pointer"
              />
            </el-popconfirm>
          </template>
          <template slot-scope="{ row }">
            <el-checkbox
              v-model="row.selected"
              :disabled="!row.active"
              @change="handleSelectionChange(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="RA"
          prop="identifier"
          width="120"
        />
        <el-table-column
          label="RE"
          prop="re"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Email"
          prop="email"
        />
        <el-table-column
          label="Série"
          prop="grade_name"
        />

        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  name: 'StudentListComponent',
  mixins: [RequestErrors],
  data(){
    return {
      check_all:false,
    }
  },
  computed:{
    ...mapGetters(['permissions','students','isLoadingStudents','students_selection_list']),
  },
  methods:{
    ...mapMutations(['SET_HISTORY', 'SET_STUDENT','SET_SELECTION_LIST','SET_STUDENTS']),
    onSelectAll(){
      this.students.forEach(student => {
          this.SET_SELECTION_LIST([...this.students_selection_list.filter(s=> s.id != student.id), student])
        });
      this.SET_STUDENTS([...this.students.map(s=> {
        s.selected = 1
        return s
      })])

    },
    handleSelectionChange(row){
      if(row.selected){
        this.SET_SELECTION_LIST([...this.students_selection_list, row])
      }else {
        this.SET_SELECTION_LIST([...this.students_selection_list.filter(s=>s.id != row.id)])
      }
    }
  }
}
</script>

