import { Loading } from 'element-ui';
class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
      let loadingInstance = Loading.service({
        message:'Enviando arquivo'
      });
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                let formData = new FormData();
                formData.append('image', file);

                if(file.size > 2048000) {
                    reject( "Tamanho máximo de upload é 2048 KB" );
                }

                return axios.post(`/admin/upload/`, formData, {
                    headers: {
                        "Content-Type": `multipart/form-data`,
                    }
                }).then(r => {
                    resolve({
                        default: r.data.data.url
                    });
                });
            })).finally(()=>loadingInstance.close());
    }
}

export function myCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
    };
}
