<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="font-weight-bold">
            Ciclos
          </p>
          <el-button
            v-if="permissions.includes('create-cycle')"
            type="primary"
            @click.prevent="onCreate()"
          >
            <i class="fa fa-plus-circle" />
          </el-button>
        </div>

        <el-dialog
          :title="cycle_config && cycle_config.id ? 'Editar ciclo' : 'Cadastrar ciclo'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getCycleConfigs(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <el-row
          :gutter="4"
          class="px-4 mt-4"
        >
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
          <el-col :md="3">
            <el-select
              v-model="year"
              placeholder="Filtro: Ano"
              clearable
            >
              <el-option
                v-for="item in [2021,2022,2023, 2024,2025]"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-col>
          <el-col :md="3">
            <el-select
              v-model="order"
              placeholder="Filtro: Ciclo"
              clearable
            >
              <el-option
                v-for="item in [0,1,2,3,4,5,6,7,8]"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-col>
        </el-row>

        <List @changed="getCycleConfigs(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-cycle')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>


import List from "@/admin/Pages/cycle_config/components/List"
import Register from "@/admin/Pages/cycle_config/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          year: '',
          order: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','cycle_config']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}&year=${this.year}&order=${this.order}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getCycleConfigs(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getCycleConfigs','getPage','getCycleConfig']),
        ...mapMutations(['SET_CYCLE_CONFIG']),
        onEdit(cycle){
          this.getCycleConfig({data: {id: cycle.id}})
          .then(() =>   this.registering = true)
        },
        onCreate(){
            this.SET_CYCLE_CONFIG({
              begin:"",
              end:"",
              order:"",
              year:"",
              begin_challenge_apply:"",
              end_challenge_apply:"",
              challenge_type:"online",
              show_challenge_result:"after_finish",
              modules_qty:4,
            }
              );
            this.registering = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_CYCLE_CONFIG(null);
        },
    },
}
</script>
