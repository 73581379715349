<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Abreviação"
          class="w-100"
          prop="abbreviation"
          :error="errors.abbreviation"
        >
          <el-input
            v-model="form.abbreviation"
            clearable
            name="abbreviation"
            show-word-limit
            maxlength="190"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Acrônimo"
          class="w-100"
          prop="acronym"
          :error="errors.acronym"
        >
          <el-input
            v-model="form.acronym"
            clearable
            name="acronym"
            show-word-limit
            maxlength="190"
            placeholder="ABR"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Descrição"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="description"
            show-word-limit
            maxlength="1000"
            placeholder="Descrição"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Link do video"
          class="w-100"
          prop="video"
          :error="errors.video"
        >
          <el-input
            v-model="form.video"
            clearable
            name="embed"
            show-word-limit
            maxlength="1000"
          />
        </el-form-item>

        <div
          style="margin-bottom:40px; width:50%; height:50%;"
          v-html="form.video"
        />
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Cor"
          class="w-100"
        >
          <el-color-picker
            v-model="form.color"
            clearable
            name="color"
          />
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Imagem Thumbnail"
          class="w-100"
        >
          <input
            id="file_thumb"
            name="thumbnail"
            type="file"
          >
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Imagem Icon"
          class="w-100"
          prop="icon"
          :error="errors.icon"
        >
          <input
            id="file_icon"
            ref="file_icon"
            type="file"
            @change="onChangeIcon"
          >
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-row class="mt-5 mb-2">
        <el-col>
          <el-button
            type="primary"
            @click="onSubmit"
          >
            Salvar
          </el-button>
          <el-button
            type="warning"
            @click="$emit('cancel')"
          >
            Cancelar
          </el-button>
        </el-col>
      </el-row>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import editor from "@/admin/utils/Editor";

export default {
  mixins:[ RequestErrors ],
  props: {
    pageId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editor,
      rules
    }
  },
  computed: {
    ...mapGetters(['trail']),
    form: {
      get() {
        return this.$store.state.trail.current;
      },
      set(page) {
        this.SET_TRAIL(page);
      }
    }
  },
  mounted() {
    if (this.trail.id) {
      this.rules.icon[0].required = false;
    }
  },
  methods: {
    ...mapMutations(['SET_TRAIL']),
    ...mapActions(['createTrail','updateTrail']),
    onChangeIcon(e){
      this.form.icon = e.target.value ? 'icon' : ''
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.trail.id ? this.updateTrail({data: this.getData(), id: this.trail.id}) : this.createTrail({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Página salva com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
    getData() {
      let formData = new FormData();
      formData.append('name', this.form.name)
      formData.append('abbreviation', this.form.abbreviation)
      formData.append('acronym', this.form.acronym)
      formData.append('description', this.form.description)
      formData.append('color', this.form.color)
      formData.append('video', this.form.video)
      formData.append('thumbnail', document.getElementById('file_thumb').files[0])
      formData.append('icon', document.getElementById('file_icon').files[0])
      if(this.trail.id)
        formData.append('_method','put');

      return formData;
    }
  },
}
</script>
