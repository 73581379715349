import { mapActions, mapState } from 'vuex'
import { redirect } from '@/utils/misc'
import RequestErrors from '@/admin/utils/RequestErrors'
import Contents from '@/admin/Pages/module/components/Contents.vue'
import AddContents from "@/admin/components/contents/AddContents"
import ManageContentsMixin from '@/admin/Pages/module/components/ManageContentsMixin.js'
export default {
  components: {
    AddContents,
    Contents,
  },
  mixins: [RequestErrors, ManageContentsMixin],
  computed: {
    ...mapState({
      form: state => state.module.form,
      ebooks: state => state.ebook.list,
      module: state => state.module.current,
      subjects: state => state.subject.list,
      isLoadingEbooks: state => state.ebook.isLoadingEbooks
    }),
    form: {
      get() {
        return this.$store.state.module.form
      },
      set(value) {
        this.$store.state.module.form = value
      }
    }
  },
  methods: {
    ...mapActions([
      'getSubjects',
      'createModule',
      'updateModule',
      'getModule',
      'getSubtopics',
      'getEbooks'
    ]),
    redirect,
  }
}
