<template>
  <el-row class="mx-5">
    <el-col>
      <el-card>
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <h3 class="mb-0">
            Blocos temáticos
          </h3>
          <el-button
            v-if="permissions.includes('create-block')"
            icon="fa fa-plus-circle"
            type="primary"
            @click.prevent="onCreate()"
          />
        </div>
        <el-dialog
          :title="theme_block ? 'Editar bloco temático' : 'Cadastrar bloco temático'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            @onSave="getThemeBlocks(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <FilterList v-model="search" />

        <div class="mx--4">
          <List @changed="getThemeBlocks(filters); onCancel()">
            <template slot-scope="{row}">
              <el-button-group>
                <el-button
                  v-if="permissions.includes('update-block')"
                  size="mini"
                  icon="el-icon-edit"
                  @click="onEdit(row)"
                >
                  Editar
                </el-button>
                <el-button-group />
              </el-button-group>
            </template>
          </List>
        </div> 


        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/theme_block/components/List"
import Register from "@/admin/Pages/theme_block/components/Register.vue"
import FilterList from "@/admin/Pages/theme_block/components/FilterList.vue"
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { defaultLoadingConfig } from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
        FilterList,
    },
    data() {
        return {
          registering: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions', 'theme_blocks','theme_block']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getThemeBlocks(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getThemeBlocks','getThemeBlock']),
        ...mapMutations(['SET_THEME_BLOCK']),
        onEdit(theme_block){
          this.SET_THEME_BLOCK(theme_block)
          this.registering = true
        },
        onCreate(){
            this.SET_THEME_BLOCK();
            this.registering = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_THEME_BLOCK();
        },
    },
}
</script>
