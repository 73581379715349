<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2>Enunciado:</h2>
        <div
          class="question-content"
          v-html="challenge.content"
        />
        <p class="h2">
          Resposta: {{ challenge.answer.toUpperCase() }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props:{
    challenge:{
      type: Object,
      required: true,
    }
  }
}
</script>
<style lang="scss">
.question-content{
  img{
    max-width: 100%;
    width: 100%;
  }
}
</style>
