<template>
  <div>
    <el-row>
      <el-col>
        <el-row>
          <el-col :md="2">
            <p>Ordem</p>
          </el-col>
          <el-col :md="2">
            <p title="ID Área do conhecimento">
              ID_AC
            </p>
          </el-col>
          <el-col :md="4">
            Disciplina
          </el-col>
          <el-col :md="6">
            Módulo
          </el-col>
        </el-row>
        <draggable :list="editModules">
          <transition-group class="transition">
            <el-row
              v-for="(module, index) in editModules"
              :key="module.module_student_id"
              :class="`border p-2 pointer-on-hover ${ isDetached(module) ? 'border-danger':''}`"
            >
              <el-col :md="2">
                <el-tag size="medium">
                  {{ index + 1 }}°
                </el-tag>
              </el-col>
              <el-col :md="2">
                {{ module.knowledge_area_id }}
              </el-col>
              <el-col :md="4">
                <p :title="`id area do conhecimento: ${module.knowledge_area_id}`">
                  {{ module.subject_name | subStr(100) }}
                </p>
              </el-col>
              <el-col :md="6">
                {{ module.name | subStr(100) }}
              </el-col>
              <el-col
                :md="4"
                class="d-flex-justify-content-center"
              >
                <el-tag :type="module.challenge_id ? 'success': 'info'">
                  Desafio atribuído<i :class="module.challenge_id ? 'fa fa-check':'fa fa-ban' " />
                </el-tag>
              </el-col>
            </el-row>
          </transition-group>
        </draggable>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col>
        <p>Parâmetros a serem enviados:</p>
      <code>
        {{ orderData }}
      </code>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col class="p-2 d-flex justify-content-end">
        <el-button
          type="primary"
          size="mini"
          icon="fa fa-save"
          :disabled="disableSave"
          @click="onSave"
        >
          Salvar
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  props:{
    cycleConfigId:{
      required: true,
      type:Number
    },
    userId:{
      required: true,
      type:Number
    },
    modules:{
      type:Array,
      required: true,
    }
  },
  data(){
    return {
      editModules:[]
    }
  },
  computed:{
    has_repeated_knowledge_area_id(){
      let modules = this.modules.filter(m => !m.block_id) 
      return modules.filter(m=>m.knowledge_area_id == 1).length > 1
      || modules.filter(m=>m.knowledge_area_id == 2).length > 1
      || modules.filter(m=>m.knowledge_area_id == 3).length > 1
      || modules.filter(m=>m.knowledge_area_id == 4).length > 1
    },
    disableSave(){
      return this.modules.length < 2
    },
    orderData(){
      return this.editModules.map((m, index)=>({
        module_student_id: m.module_student_id,
        module_order: index+1,
      }))
    }
  },
  created(){
    this.editModules = this.modules;
  },
  methods:{
    onSave(){
      axios.post('/admin/maintenance/reorder-module-student', { 
        module_student: this.orderData, user_id: this.userId, cycle_config_id: this.cycleConfigId })
        .then(()=> {
          this.$message({
                message: 'Salvo com sucesso.',
                type: 'success',
              });
        })
        .catch(e=> this.showDefaultErrorMessages(e))
        .finally(()=> this.$loading().close())
    },
    isDetached(module){
      return this.has_repeated_knowledge_area_id && !module.block_id && module.challenge_id
    }
  }
}
</script>
