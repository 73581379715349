<template>
  <div>
    <p>
      <span class="font-weight-bold">Nome:</span> {{ module.name }}
    </p>
    <p>
      <span class="font-weight-bold">Disciplina:</span> {{ module.subject.name }}
    </p>
    <p>
      <span class="font-weight-bold">Status:</span> {{ module.published ? 'Habilitado' : 'Desabilitado' }}
    </p>
    <p>
      <span class="font-weight-bold"> Alunos que escolheram:</span> {{ module.count_selections }}
      <span class="small"> (Não é possível apagar ou desabilitar módulos que foram selecionados)</span>
    </p>
    <p><span class="font-weight-bold">Questões de desafio: </span> {{ module.count_challenge_questions }}</p>
    <p><span class="font-weight-bold">Questões de desafio habilitadas: </span> {{ module.count_enabled_challenge_questions }}</p>
    <p><span class="font-weight-bold">Gerenciável: </span> {{ module.manageable ? 'Sim' : 'Não' }}</p>
  </div>
</template>
<script>
  export default {
    props:{
      module: {
        type: Object,
        required: true,
      }
    }
  }
</script>

