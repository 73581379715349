<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="100"
            placeholder="Nome do perfil"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-collapse>
      <el-collapse-item>
        <template slot="title">
          <h5>Gerenciar permissões</h5>
        </template>
        <el-row
          v-for="(group,index) in permissionGroups"
          :key="`group-${index}`"
        >
          <el-col>
            <el-divider content-position="left">
              <span class="text-uppercase ">
                {{ translateType(group[0].type) }}
              </span>
            </el-divider>

            <!-- <el-checkbox
            v-model="selectedPermissions"
            :indeterminate="true"
            @change="checkGroup(group[0].type)"
          >
            Marcar todos
          </el-checkbox> -->

            <el-checkbox-group
              v-model="selectedPermissions"
            >
              <el-checkbox
                v-for="permission in group"
                :key="permission.id"
                :label="permission.id"
              >
                {{ permission.label }} {{ group.value }}
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>

    <el-row class="mt-3">
      <el-col>
        <el-button
          ref="saveButton"
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import types from "./types"

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules,
      selectedPermissions: [],
    }
  },
  computed: {
    ...mapGetters(['role','roles', 'permission_list']),
    form: {
      get() {
        return this.role;
      },
      set(role) {
        this.SET_ROLE(role);
      }
    },
    permissionGroups(){
      return _.groupBy(this.permission_list, 'type')
    }
  },
  mounted() {
      this.selectedPermissions = this.role.permissions.map(p=>p.id)
  },
  methods: {
    ...mapMutations(['SET_ROLE']),
    ...mapActions(['createRole','updateRole']),
    translateType(type){ return types[type]},
    onSubmit() {
      this.$refs['form'].validate((valid) => {

        if (valid) {
          this.$loading();
          
          const request = this.role.id ? this.updateRole({
            data: {
              name: this.form.name,
              permissions: this.selectedPermissions,
              method: "put"
            },
            id: this.role.id
          }) : this.createRole({data: {
              name:this.form.name,
              permissions: this.selectedPermissions
          }})

          request.then(() => {
            this.$message({
              message: `Permissões de ${this.role.name} salvas com sucesso.`,
              type: 'success'
            });
            this.$emit('onSave');
          })
          .catch(e=>this.showDefaultErrorMessages(e))
          .finally(() => this.$loading().close());

        } else {
            return false;
        }
      });
    },
  }
}
</script>
