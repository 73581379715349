<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :md="4">
        <el-form-item
          class="w-100"
          label="Ano do ciclo"
          prop="year"
          :error="errors.year"
        >
          <el-input
            v-model="form.year"
            :clearable="false"
            type="number"
            placeholder="Ano do ciclo"
          />
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item
          class="w-100"
          prop="order"
          label="Ciclo"
          :error="errors.order"
        >
          <el-select
            v-model="form.order"
            placeholder="Selecionar o ciclo"
          >
            <el-option
              v-for="item in [0,1,2,3,4,5,6,7,8]"
              :key="item"
              :label="`Ciclo ${item}`"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          class="w-100"
          label="Início"
          prop="begin"
          :error="errors.begin"
        >
          <el-date-picker
            v-model="form.begin"
            :clearable="false"
            :disabled="!form.year"
            name="begin"
            class="w-100"
            type="datetime"
            placeholder="Data de início do Ciclo"
            format="dd/MM/yyyy HH:mm:ss"
            :picker-options="{ disabledDate:disabledDate }"
          />
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          class="w-100"
          label="Fim"
          prop="end"
          :error="errors.end"
        >
          <el-date-picker
            v-model="form.end"
            :clearable="false"
            :disabled="!form.year"
            name="end"
            class="w-100"
            type="datetime"
            placeholder="Data de fim do Ciclo"
            format="dd/MM/yyyy HH:mm:ss"
            :picker-options="{ disabledDate:disabledDate }"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="24">
        <el-form-item
          label="Tipo de desafio"
          prop="challenge_type"
        >
          <el-radio
            v-model="form.challenge_type"
            label="online"
          >
            online
          </el-radio>
          <el-radio
            v-model="form.challenge_type"
            label="print"
          >
            impresso
          </el-radio>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col cols="24">
        <h3>Janela padrão de aplicação do desafio</h3>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-form-item
          label="Data início padrão da janela de aplicação"
          prop="begin_challenge_apply"
          :error="errors.begin_challenge_apply"
        >
          <el-date-picker
            v-model="form.begin_challenge_apply"
            :picker-options="{ disabledDate:disabledDate }"
            type="datetime"
            placeholder="Data início padrão da janela de aplicação"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="!form.year"
            class="w-100"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Data fim padrão da janela de aplicação"
          prop="end_challenge_apply"
          :error="errors.end_challenge_apply"
        >
          <el-date-picker
            v-model="form.end_challenge_apply"
            :picker-options="{ disabledDate:disabledDate }"
            type="datetime"
            placeholder="Data fim padrão da janela de aplicação"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="!form.year"
            class="w-100"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      v-if="form.challenge_type"
      :gutter="10"
    >
      <el-col :md="12">
        <el-form-item
          label="Opção padrão liberação do resultado"
          prop="show_challenge_result"
        >
          <el-select
            v-model="form.show_challenge_result"
            placeholder="Escolha uma opção"
            class="w-100"
          >
            <el-option
              v-for="option in (form.challenge_type == 'print' ? challenge_release_options.filter(o=>o.value != 'after_finish'): challenge_release_options)"
              :key="option.value"
              :label="option.name"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Liberação de acesso ao desafio"
          prop="release_challenge"
        >
          <el-select
            v-model="form.release_challenge"
            placeholder="Selecione a opção de liberação de acesso ao desafio"
            class="w-100"
          >
            <el-option
              v-for="option in (form.challenge_type == 'print' ? release_challenge_options.filter(o=>o.value != 'after_finish_modules'): release_challenge_options)"
              :key="option.value"
              :label="option.title"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="4">
        <el-form-item
          class="w-100"
          prop="modules_qty"
          label="Quantidade de módulos de desempenho"
          :error="errors.modules_qty"
        >
          <el-select
            v-model="form.modules_qty"
            placeholder="Quantidade de módulos de desempenho"
          >
            <el-option
              v-for="item in [1,2,3,4]"
              :key="item"
              :label="`${item} módulos`"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col cols="24">
        <h3>Janela padrão aplicação de módulos</h3>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-form-item
          label="Data início módulo 1"
          prop="module_one_begin"
          :error="errors.module_one_begin"
        >
          <el-date-picker
            v-model="form.module_one_begin"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data início módulo 1"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate"
            class="w-100"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Data fim módulo 1"
          prop="module_one_end"
          :error="errors.module_one_end"
        >
          <el-date-picker
            v-model="form.module_one_end"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data fim módulo 1"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate || !form.module_one_begin"
            class="w-100"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-form-item
          label="Data início módulo 2"
          prop="module_two_begin"
          :error="errors.module_two_begin"
        >
          <el-date-picker
            v-model="form.module_two_begin"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data início módulo 2"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate"
            class="w-100"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Data fim módulo 2"
          prop="module_two_end"
          :error="errors.module_two_end"
        >
          <el-date-picker
            v-model="form.module_two_end"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data fim módulo 2"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate || !form.module_two_begin"
            class="w-100"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-form-item
          label="Data início módulo 3"
          prop="module_three_begin"
          :error="errors.module_three_begin"
        >
          <el-date-picker
            v-model="form.module_three_begin"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data início módulo 3"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate"
            class="w-100"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Data fim módulo 3"
          prop="module_three_end"
          :error="errors.module_three_end"
        >
          <el-date-picker
            v-model="form.module_three_end"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data fim módulo 3"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate || !form.module_three_begin"
            class="w-100"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :md="12">
        <el-form-item
          label="Data início módulo 4"
          prop="module_four_begin"
          :error="errors.module_four_begin"
        >
          <el-date-picker
            v-model="form.module_four_begin"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data início módulo 4"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate"
            class="w-100"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          label="Data fim módulo 4"
          prop="module_four_end"
          :error="errors.module_four_end"
        >
          <el-date-picker
            v-model="form.module_four_end"
            :picker-options="{ disabledDate:disableModuleDate }"
            type="datetime"
            placeholder="Data fim módulo 4"
            format="dd/MM/yyyy HH:mm:ss"
            :clearable="false"
            :disabled="disableEditModuleDate || !form.module_four_begin"
            class="w-100"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules,
      challenge_release_options: [
        { name: 'Primeira data após o encerramento do ciclo', value:'after_cycle'},
        { name: 'Ao final do desafio', value: 'after_finish', disabled: true, active: false},
      ],
      release_challenge_options:[
      {title: 'Com conclusão dos módulos', value: 'after_finish_modules'},
      {title: 'Sem conclusão dos módulos' ,value:'after_begin_application_dates'}
     ],
    }
  },
  computed: {
    disableEditModuleDate(){
      return !this.form.year || !this.form.begin || !this.form.end
    },
    console: () => console,
    ...mapGetters(['cycle_config']),
    form: {
      get() {
        return this.cycle_config;
      },
      set() {
        this.SET_CYCLE_CONFIG({
          ...this.form,
          release_challenge: this.form.challenge_type == 'print' ? 'after_begin_application_dates' : this.form.release_challenge,
        });
      }
    }
  },
  watch:{
    "form.challenge_type" : function(value){
      if(value == 'print' ){
        this.form.release_challenge = 'after_begin_application_dates'
      }
    }
  },
  methods: {
    disabledDate(date){
      const is_on_selected_year = this.$moment(date).isSameOrAfter(`${this.form.year}-01-01`,'year') && this.$moment(date).isSameOrBefore(`${this.form.year}-01-12`,'year')
      return !this.form.year || !is_on_selected_year
    },
    disableModuleDate(date){
      return !this.form.year || !this.$moment(date).isBetween(this.form.begin, this.form.end)
    },
    ...mapMutations(['SET_CYCLE_CONFIG']),
    ...mapActions(['createCycleConfig','updateCycleConfig']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {

          this.$loading();

          const action = this.cycle_config.id ? this.updateCycleConfig({data: this.form, id: this.cycle_config.id}) : this.createCycleConfig({data: this.form});

          action
            .then((r) => {
              this.$message({
                message: 'Cadastro de ciclo salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
  },
}
</script>
