<template>
  <el-button
    v-loading="isLoading"
    type="danger"
    icon="el-icon-delete"
    :disabled="!processPackage || !permissions.includes('delete-package')"
    circle
    @click="onClick"
  />
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
  props:{
    processPackage: {
        type: Object,
        default: null,
        required: false,
      }
    },
    data(){
      return {
        isLoading: false
      }
    },
    computed :{
      ...mapGetters(['process_packages','permissions']),
    },
    methods:{
      ...mapMutations(['SET_PROCESS_PACKAGES']),
      onClick(){
        this.$confirm(`Essa ação irá apagar o pacote assim apagando os ${this.processPackage.count_process} desafios dos alunos gerados nesse pacote, Tem certeza que deseja apagar o pacote ? Essa ação e irreversível. Continuar?`, 'Warning', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.onDelete()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
      },
      onDelete(){
        this.isLoading = true
        axios.delete(`/admin/maintenance/process-package/${this.processPackage.id}`)
        .then(()=> {
          this.$emit('deleted', this.processPackage)
          this.$message({
            message: 'Pacote apagado com sucesso',
            type: 'success'
          });
          this.SET_PROCESS_PACKAGES(this.process_packages.filter(p=>p.id != this.processPackage.id))
        })
        .finally(()=>this.isLoading = false)
      },
    },
    
  }

</script>
