<template>
  <div class="mx--4">
    <el-skeleton
      :loading="isLoadingChallenges && challenges.length === 0"
      :rows="10"
      animated
    >
      <el-table
        :data="challenges"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <Details :challenge="props.row" />
          </template>
        </el-table-column>

        <el-table-column
          label="RA"
          prop="ra"
        />
        
        <el-table-column
          label="RE"
          prop="re"
        />

        <el-table-column
          label="Aluno"
          prop="user_name"
          width="250"
        />

        <el-table-column
          label="Ciclo"
          prop="cycle_name"
          width="150"
        >
          <template slot-scope="{row}">
            {{ row.cycle_name }} - {{ row.year }}
          </template>
        </el-table-column>
        <el-table-column
          label="Escola"
          prop="school_name"
          width="250"
        />
        <el-table-column
          label="Série"
          prop="grade_name"
        />
        <el-table-column
          label="Ações"
          prop="actions"
        >
          <template slot-scope="{row}">
            <BtnResetChallenge :challenge="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Details from "@/admin/Pages/challenge/components/Details";
import BtnResetChallenge from "@/admin/Pages/challenge/components/BtnResetChallenge";

export default {
  components: {Details, BtnResetChallenge},
  computed: {
    ...mapGetters(['challenges','isLoadingChallenges']),
  },
}
</script>

