export default {
  one_module_points: [
    { required: true, message: 'A pontuação é obrigatório', trigger: 'blur' }
  ],
  two_module_points: [
    { required: true, message: 'A pontuação é obrigatório', trigger: 'blur' },
  ],
  three_module_points: [
    { required: true, message: 'A pontuação é obrigatório', trigger: 'blur' },
  ],
  four_module_points: [
    { required: true, message: 'A pontuação é obrigatório', trigger: 'blur' },
  ],
  begin: [
    { required: true, message: 'A data de Início é obrigatório', trigger: 'blur' },
  ],
  end: [
    { required: true, message: 'A data de Fim é obrigatório', trigger: 'blur' },
  ],
}
