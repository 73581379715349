<template>
  <el-card
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="15"
          :lg="15"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            CONTAGEM DE ESCOLAS SEM MÓDULOS POR CICLO
          </p>
          <p class="mb-0 font-weight-light text-sm">
            Conta quantas escolas (que possuem alunos ativos) não tiveram módulos selecionados no ciclo
          </p>
        </el-col>
        <el-col
          :md="9"
          :lg="9"
        >
          <el-button-group>
            <el-button
              size="mini"
              :type="avg ? 'primary' : 'default'"
              @click="avg = true"
            >
              Porcentagem
            </el-button>
            <el-button
              size="mini"
              :type="!avg ? 'primary' : 'default'"
              @click="avg = false"
            >
              Total somado
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <div class="p-3">
      <el-row
        v-for="cycle in count_schools_without_modules_on_cycle_list"
        :key="cycle.id"
        :gutter="10"
      >
        <el-col :span="3">
          <el-tooltip
            :content="`${cycle.begin_formatted} - ${cycle.end_formatted}`"
            placement="top"
            effect="light"
          >
            <span class="pointer-on-hover font-weight-bold">{{ cycle.cycle_name }}</span>
          </el-tooltip>
        </el-col>
        <el-col
          :span="17"
          class="pt-1"
        >
          <el-progress
            :text-inside="false"
            :show-text="false"
            :stroke-width="16"
            :percentage="avg ? cycle.percentage : getPercentage(cycle.percentage, greater_percentage)"
          />
        </el-col>
        <el-col :span="4">
          <p
            class="text-right"
            @click="onShowModal(cycle)"
          >
            <span v-if="avg">
              <el-tooltip
                content="Porcentagem de escolas (com alunos ativos) sem módulos selecionados no ciclo"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ Number(cycle.percentage).toFixed(2) }}</span>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                content="Escolas sem módulos selecionados no ciclo"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_schools_without_modules_on_cycle }}</span>
              </el-tooltip> /
              <el-tooltip
                content="Total de escolas com alunos ativos"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_schools_with_ative_students }}</span>
              </el-tooltip>
            </span>
          </p>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :title="`Escolas sem módulos selecionados no ciclo ${selected_cycle ? `${selected_cycle.cycle_name} (${selected_cycle.schools.length})` : ''}`"
      :visible.sync="showModal"
      width="70%"
    >
      <el-table
        v-if="selected_cycle"
        :data="selected_cycle.schools"
        height="500"
        style="width: 100%"
      >
        <el-table-column
          prop="school_name"
          label="Escola"
          sortable
        />
        <el-table-column
          prop="count_active_students"
          label="Alunos ativos"
          sortable
        />
      </el-table>
    </el-dialog>
  </el-card>
</template>
<script>


import { mapActions, mapGetters } from 'vuex';
import getPercentage from '@/admin/utils/getPercentageGreater'


export default {
  data(){
    return {
      avg: true,
      showModal: false,
      selected_cycle: null
    }
  },

  computed: {
    ...mapGetters(['count_schools_without_modules_on_cycle_list','is_loading_count_schools_without_modules_on_cycle',]),
    greater_percentage() {
      return _.orderBy(this.count_schools_without_modules_on_cycle_list, [this.avg ? 'percentage' : 'count_schools_without_modules_on_cycle' ], ['desc'])[0][this.avg ? 'percentage' : 'count_schools_without_modules_on_cycle' ];
    },
  },
  created(){
    if(this.count_schools_without_modules_on_cycle_list.length == 0) {
      this.getCountSchoolsWithoutModulesOnCycle()
    }
  },
    methods: {
    ...mapActions(['getCountSchoolsWithoutModulesOnCycle']),
    getPercentage,
    onShowModal(cycle){
      this.showModal = true;
      this.selected_cycle = cycle;
    }
  },

  }
  </script>

