<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingLinks && links.length == 0"
      animated
    >
      <el-table
        :data="links"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <el-button-group>
                <!-- <el-button
                  size="mini"
                  type="info"
                  @click="$loading(); getLinkLogs(props.row).then(()=>$loading().close())"
                >
                  Histórico de mudanças
                </el-button> -->
                <el-button
                  v-if="permissions.includes('delete-link')"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="100"
        />
        <el-table-column
          label="Titulo"
          prop="title"
        />
        <el-table-column
          label="Área de interesse"
          prop="trail.name"
        />
        <el-table-column
          label="Módulo"
          prop="module.name"
        />
        <el-table-column
          label="Endereço"
          prop="url"
          width="100"
        >
          <template slot-scope="{ row }">
            <a
              :href="row.url"
              target="_blank"
            >Visitar
              <i class="fa fa-external-link-alt" />
            </a>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permissions','links','isLoadingLinks']),
  },
  methods:{
    ...mapActions(['deleteLink']),
    onDelete(user){
      this.$confirm('Tem certeza que deseja apagar o cadastro de usuário ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteLink(user)
      .then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

