<template>
  <div class='mx--4'>
    <el-skeleton
      :rows='10'
      :loading='isLoadingChallengeQuestions'
      animated
    >
      <el-table
        :data='challenge_questions'
        style='width: 100%'
      >
        <el-table-column type='expand'>
          <template slot-scope='props'>
            <div class='p-4'>
              <question-details :question='props.row' />
              <el-button-group>
                <el-button
                  size='mini'
                  type='info'
                  @click='$loading();getChallengeLogs(props.row).then(()=>$loading().close())'
                >
                  Histórico de mudanças
                </el-button>
                <el-button
                  v-if="permissions.includes('delete-challenge-question')"
                  size='mini'
                  type='danger'
                  icon='el-icon-delete'
                  :disabled='props.row.count_selections > 0'
                  @click='onDelete(props.row)'
                >
                  Apagar
                </el-button>
                <el-button
                  v-if="permissions.includes('update-challenge-question') && props.row.disabled && !props.row.invalidated_at"
                  size='mini'
                  type='success'
                  icon='el-icon-unlock'
                  width='110'
                  @click='onEnable(props.row)'
                >
                  Habilitar
                </el-button>
                <el-button
                  v-if="permissions.includes('update-challenge-question') && !props.row.disabled"
                  size='mini'
                  width='110'
                  type='warning'
                  icon='el-icon-lock'
                  @click='onDisable(props.row)'
                >
                  Desabilitar
                </el-button>
                <el-button
                  v-if="permissions.includes('update-challenge-question') && props.row.disabled_at"
                  size='mini'
                  width='110'
                  type='warning'
                  icon='el-icon-lock'
                  :disabled='!!props.row.invalidated_at'
                  @click='onInvalidate(props.row)'
                >
                  {{ props.row.invalidated_at ? 'Anulada' : 'Anular' }}
                </el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label='Id'
          prop='id'
          width='100'
        />
        <el-table-column
          label='Descrição'
          prop='description'
        />
        <el-table-column
          label='Ordem'
          prop='order'
        />
        <el-table-column
          label='Módulo'
          prop='module'
        />
        <el-table-column
          label='Disciplina'
          prop='subject'
        />
        <el-table-column
          label='Tipo'
          width='100'
          prop='module_type'
        />
        <el-table-column
          label='Status'
          prop='status'
          width='226'
        />
        <el-table-column
          label="Gabarito"
          prop="answer"
        />
        <el-table-column
          label='Nível'
          prop='level'
          :formatter="formatLevel"
        />
        <el-table-column
          fixed='right'
          label='Ações'
          width='220'
        >
          <template slot-scope='{ row }'>
            <slot :row='row' />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import QuestionDetails from '@/admin/Pages/challenge-question/components/QuestionDetails'

export default {
  components: {
    QuestionDetails
  },
  data() {
    return {
      level: [
        { value: 1, name: 'Difícil' },
        { value: 2, name: 'Médio' },
        { value: 3, name: 'Fácil' }
      ]
    }
  },
  computed: {
    ...mapGetters(['permissions', 'challenge_questions', 'isLoadingChallengeQuestions'])
  },
  methods: {
    formatLevel(row) {
      if (row.level === 1) {
        return 'Difícil'
      }
      if (row.level === 2) {
        return 'Médio'
      }
      if (row.level === 3) {
        return 'Fácil'
      }
      return row.level
    },
    ...mapMutations(['SET_HISTORY', 'SET_CHALLENGE_QUESTION']),
    ...mapActions(['deleteChallengeQuestion', 'disableChallengeQuestion', 'enableChallengeQuestion', 'getChallengeLogs', 'invalidateChallengeQuestion']),
    onDelete(challenge) {
      this.$confirm('Tem certeza que deseja apagar a questão ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => this.deleteChallengeQuestion(challenge).then(() => this.$emit('changed')))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada!'
          })
        })
    },
    onEnable(challenge) {
      this.$confirm('Tem certeza que deseja habilitar a questão ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      }).then(() => this.enableChallengeQuestion(challenge.id)
        .then(() => {
          this.$emit('changed')
          this.$message({
            type: 'success',
            message: 'Questão habilitada com sucesso.'
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Erro ao habilitar questão.'
          })
        }))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada.'
          })
        })
    },
    onDisable(challenge) {
      const {
        count_module_available_challenge_questions: acq,
        default_student_challenge_questions_per_module_qty: qpm
      } = challenge
      let message = 'Tem certeza que deseja habilitar a questão ?'
      const will_have_not_enoght_questions = acq == qpm
      const h = this.$createElement
      this.$msgbox({
        title: 'Atenção',
        message: h('p', null, [
          h('span', null, message),
          h('p', { style: 'color: red' }, will_have_not_enoght_questions ? `O módulo ficará com ${acq - 1} de ${qpm} questões e não será sugerido para os alunos.` : ''),
          h('p', { style: 'color: red' }, will_have_not_enoght_questions ? `Os alunos que já selecionaram o módulo, ao realizarem o desafio, poderão ter menos questões.` : '')
        ]),
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCancelButton: true,
        confirmButtonClass: 'bg-danger',
        type: 'danger'
      }).then(() => this.disableChallengeQuestion(challenge.id)
        .then(() => {
          this.$emit('changed')
          this.$message({
            type: 'success',
            message: 'Questão desabilitada com sucesso.'
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Erro ao desabilitar questão.'
          })
        }))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada.'
          })
        })
    },
    onInvalidate(challenge) {
      this.$confirm('Tem certeza que deseja anular a questão ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'danger'
      }).then(() => this.invalidateChallengeQuestion(challenge.id)
        .then(() => {
          this.$emit('changed')
          this.$message({
            type: 'success',
            message: 'Questão anulada com sucesso.'
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Erro ao anular questão.'
          })
        }))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada.'
          })
        })
    }
  }
}
</script>

