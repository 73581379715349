export default {
  module: 'módulo',
  theme: 'tema',
  question: 'questionário',
  challenge: 'questões de desafio',
  challenge_question: 'questões de desafio',
  video: 'video',
  block: 'blocos',
  scorm: 'scorm',
  link: 'links',
  ebook: 'ebook',
  cycle: 'ciclo',
  network: 'redes',
  week: 'semanas',
  school: 'escolas',
  trail: 'carreiras',
  file: 'arquivos',
  report: 'relatórios',
  suport: 'suporte',
  role: 'perfis',
  permission: 'permissões',
  warning: 'alertas',
  user: 'Usuário',
  ticket: 'Ticket',
  student: 'Alunos',
}
