import { mapActions, mapGetters } from 'vuex'
import RequestErrors from '@/admin/utils/RequestErrors'
import Contents from '@/admin/Pages/theme/components/Contents.vue'
import ManageContentsMixin from '@/admin/Pages/theme/components/ManageContentsMixin.js'
import AddContents from "@/admin/components/contents/AddContents"


export default {
    components: {
      Contents,
      AddContents,
    },
    mixins: [RequestErrors, ManageContentsMixin],
    computed: {
        ...mapGetters(['theme_blocks','theme','subjects']),
        form: {
          get() {
              return this.$store.state.theme.form
          },
          set(value) {
              this.$store.state.theme.form = value
          }
        }
    },
    methods: {
        ...mapActions([
            'getTheme',
            'createTheme',
            'updateTheme',
            'createTheme',
            'getTrails',
            'getThemeConfigs',
            'getThemeBlocks',
            'getSubjects',
        ]),
    }
}
