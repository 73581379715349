<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :md="4">
        <el-form-item label="Título">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar"
            clearable
          />
        </el-form-item>
      </el-col>
      <!-- <el-col :md="4">
        <el-form-item label="Disciplinas">
          <el-select
            v-model="form.subject_id"
            placeholder="Selecione a disciplina"
            filterable
            clearable
          >
            <el-option
              v-for="(s, index) in subjects"
              :key="index"
              :label="s.name"
              :value="s.id"
            />
          </el-select>
        </el-form-item>
      </el-col> -->
      <el-col :md="4">
        <el-form-item
          label="Tipo de módulo"
          prop="module_type"
        >
          <el-select
            v-model="form.module_type"
            clearable
            placeholder="Selecione o tipo de módulo"
            filterable
          >
            <el-option
              v-for="i in module_types"
              :key="i.value+1"
              :label="i.name"
              :value="i.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Módulos">
          <el-select
            v-model="form.module_id"
            clearable
            placeholder="Selecione o módulo"
            filterable
          >
            <el-option
              v-for="(m, index) in modules_list.filter(m => form.subject_id ? m.subject_id == form.subject_id : true)"
              :key="index"
              :label="`${m.name} (${m.type == 'theme' ? 'carreira' : 'desempenho'})`"
              :value="m.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="10">
        <el-form-item label="Status do vídeo">
        <el-radio-group
          v-model="form.used"
          class="mt-2"
        >
          <el-radio label="true">
            Utilizado
          </el-radio>
          <el-radio label="false">
            Não utilizado
          </el-radio>
          <el-radio label="">
            Todos
          </el-radio>
        </el-radio-group>
      </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>


import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      module_types:[
        {value: 'theme', name: 'Módulo de carreira'},
        {value: 'default', name: 'Módulo de desempenho'},
        {value: 'pre_cycle', name: 'Módulos pré-ciclo'},
        {value: '', name: 'Todos'},
      ],
      form: {
        search:'',
        subject_id: '',
        module_id: '',
        module_type: '',
        used: '',
      },
    }
  },
  computed:{
    ...mapGetters(['modules_and_themes','subjects','modules','themes','pre_cycle_modules']),
    filters(){
      return `search=${this.form.search}&subject_id=${this.form.subject_id}&module_id=${this.form.module_id}${this.form.used?`&used=${this.form.used}`:''}`
    },
    modules_list(){
      if(this.form.module_type == 'theme'){
        return this.themes
      }
      if(this.form.module_type == 'pre_cycle'){
        return this.pre_cycle_modules
      }
      if(this.form.module_type == 'default'){
        return this.modules
      }

      return this.modules_and_themes
    }
  },
  watch:{
    filters: {
      handler(){
       this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted(){
    this.getSubjects();
    this.getModules();
    this.getPreCycleModules();
    this.getThemes();
  },
  methods:{
    ...mapActions(['getModules','getSubjects','getThemes','getPreCycleModules']),
  }
}

</script>

