<template>
  <div>
    <el-row
      :gutter="4"
      class="px-4 mt-4"
    >
      <el-col :md="4">
        <el-input
          v-model="search"
          placeholder="Pesquisar"
          clearable
        />
      </el-col>
      <el-col :md="4">
        <el-select
          v-model="grade_id"
          placeholder="Série"
          clearable
          filterable
        >
          <el-option
            v-for="grade in grades"
            :key="grade.id"
            :label="grade.name"
            :value="grade.id"
          />
        </el-select>
      </el-col>
      <el-col :md="4">
        <el-select
          v-model="school_id"
          placeholder="Escola"
          clearable
          filterable
        >
          <el-option
            v-for="school in schools.filter(s=>s.count_active_students>0)"
            :key="school.id"
            :label="school.name"
            :value="school.id"
          />
        </el-select>
      </el-col>
      <el-col :md="4">
        <el-select
          v-model="cycle_config_id"
          placeholder="Ciclo"
          filterable
        >
          <el-option
            v-for="cycle in cycle_configs"
            :key="cycle.id"
            :label="cycle.name"
            :value="cycle.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row class="px-4 mt-4">
      <el-col :md="12">
        <el-radio-group v-model="withChallenge">
          <el-radio label="&with-challenge">
            Tem desafio atribuído
          </el-radio>
          <el-radio label="">
            Todos
          </el-radio>
        </el-radio-group>
      </el-col>
      <el-col :md="12">
        <el-radio-group v-model="status">
          <el-radio label="active">
            Ativos
          </el-radio>
          <el-radio label="inactive">
            Inativos
          </el-radio>
          <el-radio label="">
            Todos
          </el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>


<script>

import { mapActions, mapGetters } from 'vuex'

export default{
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          status: 'active',
          school_id: '',
          grade_id: '',
          cycle_config_id: 28,
          withChallenge: '&with-challenge',
          meta : {
              current_page: 1,
              total: 10
          },
          showSelectionModal: false,
        }
    },
    computed:{
      ...mapGetters(['student','students','schools','cycle_configs','grades']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}&school_id=${this.school_id}&status=${this.status}&cycle_config_id=${this.cycle_config_id}&grade_id=${this.grade_id}${this.withChallenge}`
      }
    },
    watch: {
      filters: {
        handler(){
          clearTimeout(this._timerId)
          this._timerId = setTimeout(() => {
            this.$emit('input', this.filters)
          }, 500)
        },
        immediate: true,
    }
    },
    mounted(){
      if(this.schools.length == 0 ) {
        this.getSchools('&order_by=name&order_direction=asc');
      }
      if(this.grades.length == 0 ) {
        this.getGrades();
      }
      if(this.cycle_configs.length == 0 ) {
        this.getCycleConfigs('&year=2024');
      }
    },
    methods:{
        ...mapActions(['getSchools','getCycleConfigs','getGrades']),
    },
}
</script>
