<template>
  <el-row :gutter="4">
    <el-col :md="6">
      <el-input
        v-model="search"
        placeholder="Pesquisar por Nome, ID Evolucional ou ID Nitro"
        clearable
        suffix-icon="el-icon-search"
      />
    </el-col>
    <el-col
      :md="12"
      class="p-2"
    >
      <el-radio-group v-model="active_students">
        <el-radio label="has-active">
          Ativos
        </el-radio>
        <el-radio label="has-not-active">
          Inativos
        </el-radio>
        <el-radio label="">
          Todos
        </el-radio>
      </el-radio-group>
    </el-col>
  </el-row>
</template>


<script>

export default{
    data() {
      return {
        search: '',
        active_students: 'has-active',
      }
    },
    computed:{
      filters(){
        return `&search=${this.search}&${this.active_students ? `&${this.active_students}`:''}`
      }
    },
    watch: {
      filters:
      {
        handler(){
          this.$emit('input', this.filters)
        },
        immediate: true,
      },
  },
}
</script>
