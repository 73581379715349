<!-- eslint-disable vue/no-parsing-error -->
<template>
  <span>
    <el-button
      type="primary"
      icon="el-icon-unlock"
      @click="showModal = true"
    >Configurar Blocos </el-button>
    <el-dialog
      title="Editar configuração de quantidade de blocos de módulos de carreira"
      :visible.sync="showModal"
      append-to-body
      width="70%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="result = null"
    >
      <el-row
        v-for="(grade, key2) in grades"
        :key="key2"
      >
        <p class="font-weight-bold mt-2">
          {{ grade.name }}
        </p>
        <el-col 
          v-for="(semester, key) in [1,2]"
          :key="key"
          :md="6"
        >
          
          <ConfigCycleBlocks 
            :semester="semester"
            :grade="grade"
            :school="school"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </span>
</template>
<script>

import ConfigCycleBlocks from './ConfigCycleBlocks'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ConfigCycleBlocks
  },
  props:{
    school:{
      required:true,
      type: Object,
    }
  },
  data(){
    return {
      showModal: false,
    }
  },
  computed:{
    ...mapGetters(['grades', 'isLoadingGrades']),
  },
  mounted() {
    if(!this.isLoadingGrades){
      this.getGrades()
    }
  },
  methods:{
    ...mapActions(['getGrades']),
  }
}
</script>
