<template>
  <div class="block mt-1">
    <span class="font-weight-bold">Liberar a partir: </span>{{ getConfigDescription(slide) }}
    <div class="px-2">
      <el-slider
        v-model="slide"
        :format-tooltip="getConfigDescription"
        :step="1"
        :min="0"
        :max="options.length-1"
        show-stops
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

  export default {
    props:{
      module: {
        type: Object,
        required: true,
      }
    },
    data(){
      return {
        slide: null,
        options: [
          {
            grade_order: 1,
            semester:1,
            cycle_order: 1,
          },
          {
            grade_order: 1,
            semester:2,
            cycle_order: 5,
          },
          {
            grade_order: 2,
            semester:1,
            cycle_order: 1,
          },
          {
            grade_order: 2,
            semester:2,
            cycle_order: 5,
          },
          {
            grade_order: 3,
            semester:1,
            cycle_order: 1,
          },
          {
            grade_order: 3,
            semester:2,
            cycle_order: 5,
          },
          {
            grade_order: 4,
            semester:1,
            cycle_order: 1,
          },
          {
            grade_order: 4,
            semester:2,
            cycle_order: 5,
          },
        ]
      }
    },

    computed:{
      ...mapGetters(['grades']),
      current_option(){
        return this.options[this.slide] ? this.options[this.slide] :  {
            grade_order: 1,
            semester:1,
            cycle_order: 1,
          }
      },
      current_module_option(){
        return this.options.find(option =>
          option.grade_order == this.module.released_at_grade_order &&
          option.semester == (this.released_at_cycle_order < 5 ? 1: 2) &&
          option.cycle_order == this.module.released_at_cycle_order)
        }
    },

    watch: {
      slide: {
        handler(value, old){
          if(old !== null) {
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
              this.updateReleasedPeriod({
                module_id: this.module.id,
                released_at_grade_order: this.current_option.grade_order,
                released_at_cycle_order: this.current_option.cycle_order,
              })
              .then(()=> {
                this.$message({ message: 'Salvo com sucesso.', type: 'success', showClose: true})
              })
            }, 1500)
          }
        },
        deep: true,
      }
    },
    mounted(){
      this.slide = this.options.indexOf(this.options.find(o => o.grade_order == this.module.released_at_grade_order && o.cycle_order == this.module.released_at_cycle_order))
    },
    methods:{
      ...mapActions(['updateReleasedPeriod']),
      getConfigDescription(){

        const  grade = this.grades.find(g=>g.order == this.current_option.grade_order)

          return `Semestre ${this.current_option.semester} - ${grade ? grade.name: ''}`
      }
    },

  }
</script>
