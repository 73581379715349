<template>
  <el-row class="mx-4">
    <el-col>
      <router-link
        v-if="ticket.ticketable_type != 'quiz' && ticket.ticketable_type != 'link'"
        :to="`/administrativo/${ticket.ticketable_type}`"
        class="text-blue font-weight-bold"
      >
        Ir para o item
      </router-link>

      <p class="mb-1">
        <span class="font-weight-bold">Tipo do item:</span> {{ ticket.ticketable_type }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Título do item:</span> {{ ticket.title }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Status:</span> {{ ticket.solved ? 'Concluído' : 'Em análise' }}
      </p>
    </el-col>
    <el-col>
      <p class="mb-1">
        <span class="font-weight-bold">Criado em:</span> {{ ticket.created_at }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Criado por:</span> {{ ticket.user.name }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Email:</span> {{ ticket.user.email }}
      </p>
      <p class="mb-1">
        <span class="font-weight-bold">Mensagem:</span> {{ ticket.message }}
      </p>
      <p
        v-if="ticket.file"
        class="mb-1"
      >
        <img
          :src="ticket.file.url"
          class="w-100"
        >
      </p>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    }
  },
}
</script>
