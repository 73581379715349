<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="100"
            placeholder="Título do link"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Url"
          class="w-100"
          prop="url"
          :error="errors.url"
        >
          <el-input
            v-model="form.url"
            clearable
            name="url"
            show-word-limit
            maxlength="190"
            placeholder="https://link-para-o-conteudo.com.br"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Descrição"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="2"
            clearable
            name="description"
            show-word-limit
            maxlength="325"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Trilha"
          prop="trail_id"
          :error="errors.trail_id"
        >
          <el-select
            v-model="form.trail_id"
            v-loading="!trails.length"
            filterable
            placeholder="Selecione a trilha"
            class="w-100"
          >
            <el-option
              v-for="item in trails"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Módulo"
          prop="module_id"
          :error="errors.module_id"
        >
          <el-select
            v-model="form.module_id"
            v-loading="!modules.length"
            filterable
            placeholder="Selecione o módulo"
            class="w-100"
          >
            <el-option value="" />
            <el-option
              v-for="item in modules"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules
    }
  },
  computed: {
    ...mapGetters(['link','modules', 'trails',]),
    form: {
      get() {
        return this.link;
      },
      set(link) {
        this.SET_LINK(link);
      }
    }
  },
  
  methods: {
    ...mapMutations(['SET_LINK']),
    ...mapActions(['createLink','updateLink']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.link.id ? this.updateLink({data: this.form, id: this.link.id}) : this.createLink({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Cadastro de link salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
  },
}
</script>
