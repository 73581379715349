<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader />
        <FilterList @onEdit="getConfig" />
        <EditConfig
          v-if="config"
          :config="config"
        />
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import FilterList from "./FilterList.vue"
import EditConfig from "./EditConfig.vue"

export default{
  components: {FilterList, EditConfig},
    data() {
        return {
          config: null,
        }
    },
    methods:{
      getConfig(params){
        this.config = null;
        this.$loading({
            text: 'Carregando configuracão'
        });
        axios.get(`/admin/school/application-period?${params}`)
        .then(({data:{data: config}})=> {
          this.config = config
        })
        .finally(()=> this.$loading().close())
      }
    },
}
</script>
