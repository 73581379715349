<template>
  <main class="wrapper">
    <section
      id="section-logo-nitro"
      class="bg-transparent"
    >
      <div
        id="container-logo-nitro"
        class="p-4"
      >
        <img
          id="icon"
          src="/images/logo_nitro_mobile.svg"
          alt="Logo Nitro"
        >
      </div>
    </section>
    <section id="section-form">
      <el-form
        id="form"
        ref="form"
        label-position="top"
        label-width="120px"
        class="text-left w-100"
      >
        <el-form-item
          label="Email"
          prop="email"
        >
          <el-input
            v-model="form.email"
            clearable
            placeholder="Email"
          />
        </el-form-item>
        <el-form-item
          label="Senha"
          prop="password"
        >
          <el-input
            v-model="form.password"
            type="password"
            clearable
            placeholder="Senha"
          />
        </el-form-item>
        <div class="d-flex justify-content-center">
          <el-button
            type="primary"
            class="w-100"
            @click="onSubmit"
          >
            Entrar
          </el-button>
        </div>
      </el-form>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex'
import { redirect } from '@/utils/misc'
export default {
    data(){
        return { 
            form : {
                email: this.isInDevelop ? 'pc4@pc4.com.br':'',
                password: this.isInDevelop ? '123123123123':'',
            }
        }
    },
    methods: {
      ...mapActions(['login']),
      onSubmit(){
        this.login({data: this.form})
        .then((r)=> {
          const { data: {data : user }} = r
          if(user.is_admin){
            this.$router.push('/administrativo')
          }else{
            redirect('/')
          }
        })
        .catch((e) => {
            this.$message({
                type: 'error',
                message: 'Erro ao realizar o login'
            })
            return e
        })
      }
    }
}
</script>

<style lang="scss">
    .wrapper{
        .el-form-item {
            .el-form-item__label {
                color: white;
            }
        }
    }
</style>
<style lang="scss" scoped>
    .el-form-item {
        .el-form-item__label {
            color: white;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        min-height: 100vh;
        padding: 20px;
        background: linear-gradient(to right, #060181, #F74A4B) !important;
    }
    
    #section-logo-nitro, #section-form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 430px;
        min-height: 100%;
    }

    #container-logo-nitro{
        height: 212px;
        > img {
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width: 1000px) {
        .wrapper{
            flex-direction: column;
            justify-content: center;
        }
    }

    @media screen and (max-width: 767px) {
        #section-form, #section-logo-nitro{
            width: 300px;
        }
    }

    @media screen and (max-width: 600px) {
        #section-form, #section-logo-nitro{
            width: 283.83px;
        }
    }

    @media screen and (max-width: 314px) {
        #section-form, #section-logo-nitro{
            width: 80vw;
        }
    }
</style>