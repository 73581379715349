<template>
  <span>
    <el-button
      type="primary"
      icon="el-icon-unlock"
      @click="showModal = true"
    >Liberar acesso ao ciclo </el-button>
    <el-dialog
      :title="result ? 'Resultado' : 'Escolher ciclo'"
      :visible.sync="showModal"
      append-to-body
      :width="result ? '70%' : '30%'"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="result = null"
    >
      <div v-if="result">
        <el-alert
          :title="`${result.released.length} alunos com ciclo ${cycle.order + 1 } liberado `"
          type="success"
          :closable="false"
        />
        <el-table :data="result.released">
          <el-table-column
            label="RA"
            prop="identifier"
            width="120"
          />
          <el-table-column
            label="Nome"
            prop="name"
          />
          <el-table-column
            label="Email"
            prop="email"
          />
        </el-table>
        <el-alert
          v-show="result.non_released.length > 0"
          :title="`${result.non_released.length} alunos que já tem módulos no ciclo ${cycle.order + 1 }`"
          type="danger"
          :closable="false"
        />
        <el-table
          v-show="result.non_released.length > 0"
          :data="result.non_released"
        >
          <el-table-column
            label="RA"
            prop="identifier"
            width="120"
          />
          <el-table-column
            label="Nome"
            prop="name"
          />
          <el-table-column
            label="Email"
            prop="email"
          />
        </el-table>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            type="primary"
            @click="result = null; showModal = false"
          >Entendido
          </el-button>
        </span>

      </div>
      <div v-else>
        <span>Escolha o ciclo a ser liberado para os {{ students_selection_list.length }} alunos selecionados </span>
        <div>
          <el-select
            v-model="cycle_config_id"
            placeholder="Selecionar o ciclo"
          >
            <el-option
              v-for="item in cycle_configs"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="!item.finished"
            />
          </el-select>
          <el-button
            type="primary"
            :disabled="!cycle_config_id"
            @click="releaseCycleForStudents"
          >Confirmar</el-button>
        </div>
      </div>

    </el-dialog>
  </span>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data(){
    return {
      showModal: false,
      cycle_config_id: null,
      result: null,
    }
  },
  computed:{
    ...mapGetters(['cycle_configs','students_selection_list']),
    cycle(){
      return this.cycle_configs.find(c=>c.id == this.cycle_config_id)
    }
  },
  watch:{
    showModal(value){
      if(value && this.cycle_configs.length == 0){
        this.getCycleConfigs(`&year=${new Date().getFullYear()}`)
      }
    }
  },
  methods:{
    ...mapActions(['getCycleConfigs']),
    releaseCycleForStudents(){
      this.$loading()
      axios.post(`/admin/student/release-cycle`, {
        users: this.students_selection_list.map(s => s.id),
        cycle_config_id: this.cycle_config_id
      })
      .then(response => {
        this.result = response.data.data;
      })
      .finally(()=> this.$loading().close())
    }
  }
}
</script>
