<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col :md="6">
        <el-form-item
          class="w-100"
          label="Ciclo"
          prop="cycle_config_id"
          :error="errors.cycle_config_id"
        >
          <el-select
            v-model="form.cycle_config_id"
            filterable
            placeholder="Selecione um ciclo"
          >
            <el-option
              v-for="(cc, index) in cycle_configs"
              :key="index"
              :label="`${cc.cycle_name} (${cc.begin_formatted} / ${cc.end_formatted})`"
              :value="cc.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        v-if="form.cycle_config_id"
        :md="6"
      >
        <el-form-item
          class="w-100"
          label="Nome"
          prop="order"
          :error="errors.order"
        >
          <el-select
            v-model="form.order"
            filterable
            placeholder="Selecione o nome da semana"
          >
            <el-option
              v-for="w in orders"
              :key="w.value"
              :label="w.name"
              :value="w.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      v-if="form.cycle_config_id && form.order"
      :gutter="20"
    >
      <el-col :md="6">
        <el-form-item
          class="w-100"
          label="Um Módulo"
          prop="one_module_points"
          :error="errors.one_module_points"
        >
          <el-input
            v-model="form.one_module_points"
            clearable
            placeholder="50.00"
            name="one_module_points"
          />
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item
          class="w-100"
          label="Dois Módulo"
          prop="two_module_points"
          :error="errors.two_module_points"
        >
          <el-input
            v-model="form.two_module_points"
            clearable
            placeholder="50.00"
            name="two_module_points"
          />
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item
          class="w-100"
          label="Três Módulos"
          prop="three_module_points"
          :error="errors.three_module_points"
        >
          <el-input
            v-model="form.three_module_points"
            clearable
            placeholder="60.00"
            name="three_module_points"
          />
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item
          class="w-100"
          label="Quatro Módulos"
          prop="four_module_points"
          :error="errors.four_module_points"
        >
          <el-input
            v-model="form.four_module_points"
            clearable
            placeholder="50.00"
            name="four_module_points"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          class="w-100"
          label="Início"
          prop="begin"
          :error="errors.begin"
        >
          <el-date-picker
            v-model="form.begin"
            :disabled="showDates"
            :clearable="false"
            name="begin"
            class="w-100"
            type="datetime"
            placeholder="Data de início da Semana"
            format="dd/MM/yyyy HH:mm:ss"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          class="w-100"
          label="Fim"
          prop="end"
          :error="errors.end"
        >
          <el-date-picker
            v-model="week.end"
            :disabled="showDates"
            :clearable="false"
            name="end"
            class="w-100"
            type="datetime"
            placeholder="Data de fim da Semana"
            format="dd/MM/yyyy HH:mm:ss"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-button
          ref="saveButton"
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules";
const orders = [
  {name: "Semana 1" , value: 1},
  {name: "Semana 2" , value: 2},
  {name: "Semana 3" , value: 3},
  {name: "Semana 4" , value: 4},
  {name: "Semana 5" , value: 5},
  {name: "Semana 6" , value: 6},
]
export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      showDates: false,
      showButton: false,
      datePickerOptionsInitial: {
        disabledDate: this.disabledDueDateInitial
      },
      datePickerOptionsEnd: {
        disabledDate: this.disabledDueDateEnd
      },
      rules,
      orders,
    }
  },
  computed: {
    ...mapGetters(['week','cycle_configs']),
    form: {
      get() {
        return this.week;
      },
      set(week) {
        this.SET_WEEK(week);
      }
    },
    cycle_config(){
      return this.week.cycle_config
    },
  },
  methods: {
    ...mapActions(['createWeek','updateWeek']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.week.id ? this.updateWeek({data: this.form, id: this.week.id}) : this.createWeek({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Cadastro de semana salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=> {
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
  }
}
</script>
