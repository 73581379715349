<template>
  <el-form label-position="top">
    <el-row
      :gutter="4"
      class="px-4 mt-4"
    >
      <el-col :md="4">
        <el-input
          v-model="search"
          placeholder="Pesquisar"
          clearable
        />
      </el-col>
      <el-col :md="3">
        <el-select
          v-model="grade_id"
          placeholder="Série"
          filterable
        >
          <el-option
            v-for="grade in grades"
            :key="grade.id"
            :label="grade.name"
            :value="grade.id"
          />
        </el-select>
      </el-col>
      <el-col :md="3">
        <el-select
          v-model="school_id"
          placeholder="Escola"
          filterable
        >
          <el-option
            v-for="loop_school in schools.filter(s => s.count_active_students>0)"
            :key="loop_school.id"
            :label="loop_school.name"
            :value="loop_school.id"
          />
        </el-select>
      </el-col>
      <el-col 
        v-if="school"
        :md="3"
      >
        <el-select
          v-model="trail_id"
          placeholder="Carreira"
          filterable
          clearable
        >
          <el-option
            v-for="trail in school.trails"
            :key="trail.id"
            :label="trail.name"
            :value="trail.id"
          />
        </el-select>
      </el-col>
      <el-col :md="3">
        <el-select
          v-model="cycle_config_id"
          placeholder="Ciclo"
        >
          <el-option
            v-for="cycle in cycle_configs.filter(c => c.order > 0)"
            :key="cycle.id"
            :label="cycle.name"
            :value="cycle.id"
            :disabled="!!cycle.is_disabled"
          />
        </el-select>
      </el-col>
      <el-col :md="6">
        <el-button 
          type="primary"
          :disabled="disableMassButton"
          @click="setupModulesForStudents"
        >
          Definir módulos na série, escola e ciclo selecionados
        </el-button>
      </el-col>
    </el-row>
    <el-divider class="my-2" />
    <el-row
      class="mx-4"
      :gutter="4"
    > 
      <el-col :md="12">
        <el-form-item label="Carreira">
          <el-radio-group v-model="has_themes">
            <el-radio 
              v-for="item in [
                {
                  name: 'Escolheu',
                  value: 'has-themes'
                },
                {
                  name: 'Não escolheu',
                  value: 'has-not-themes'
                },
                {
                  name: 'Todos',
                  value: ''
                },
              ]"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item label="Desempenho">
          <el-radio-group v-model="has_modules">
            <el-radio 
              v-for="item in [
                {
                  name: 'Escolheu',
                  value: 'has-modules'
                },
                {
                  name: 'Não escolheu',
                  value: 'has-not-modules'
                },
                {
                  name: 'Todos',
                  value: ''
                },
              ]"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider class="my-2" />
    <el-row
      v-if="school_cycle_grade_config"
      class="m-4"
    >
      <el-col>
        <el-alert
          v-if="school && selected_cycle_config && selected_grade"
          :title="
            school_cycle_grade_config.count_students_without_modules == 0 ? `Todos os alunos estão com módulos atribuídos na escola ${school.name} em ${selected_grade.name} no ${selected_cycle_config.cycle_name}` : `Contagens de alunos sem módulos na escola ${school.name} em ${selected_grade.name} no ${selected_cycle_config.cycle_name}`
          "
          :type="school_cycle_grade_config.count_students_without_modules == 0 ? 'success' : 'info'"
          show-icon
        >
          <p>
            Alunos sem módulos de carreira: 
            {{ school_cycle_grade_config.count_students_without_themes }} 
            ({{ school_cycle_grade_config.count_students_on_grade > 0 ? Math.trunc((school_cycle_grade_config.count_students_without_themes*100)/school_cycle_grade_config.count_students_on_grade) : '0' }}%)
          </p>
          <p>
            Alunos sem módulos de desempenho: 
            {{ school_cycle_grade_config.count_students_without_modules }} 
            
            ({{ school_cycle_grade_config.count_students_on_grade ? Math.trunc((school_cycle_grade_config.count_students_without_modules*100)/school_cycle_grade_config.count_students_on_grade) : '0' }}%)
          </p>
          <p>Total de alunos na série: {{ school_cycle_grade_config.count_students_on_grade }}</p>
        </el-alert>
      </el-col>
    </el-row>
    <el-row
      v-if="school_cycle_grade_config && school_cycle_grade_config.is_generating_modules"
      class="m-4"
    >
      <el-col>
        <el-alert
          :title="`No momento há módulos de desempenho sendo atribuídos em massa para a ${school ? school.name: ''} no ${selected_cycle_config.cycle_name} na ${selected_grade.name} e não é possível realizar atribuições nesse momento.`"
          type="warning"
          show-icon
        />
      </el-col>
    </el-row>
    <slot />
  </el-form>
</template>


<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default{
    data() {
        return {
          search: '',
          status: 'active',
          school_id: null,
          grade_id: null,
          has_themes: 'has-not-themes',
          has_modules: 'has-not-modules',
          trail_id: '',
          cycle_config_id: 32,
          showSelectionModal: false,
        }
    },
    computed:{
      ...mapGetters(['students','schools','cycle_configs','grades','school_cycle_grade_config']),
      filters(){

        let trail_id = '';

        if(this.trail_id >= 1 && this.trail_id <= 5 ){
          trail_id = `&trail_id=${this.trail_id}`
        }
        if(this.trail_id === 0){
          trail_id = '&without-trail'
        }
        let url_params = `&search=${this.search}${trail_id}&school_id=${this.school_id}&status=${this.status}&grade_id=${this.grade_id}&not_selected_modules&cycle_config_id=${this.cycle_config_id}&${this.has_themes}&${this.has_modules}`
        return url_params
      },
      school(){
        return this.school_id && this.schools.length > 0 ? this.schools.find(s => s.id == this.school_id) : null
      },
      selected_grade(){
        return this.grade_id && this.grades.length > 0 ? this.grades.find(g=>g.id == this.grade_id) : null
      },
      selected_cycle_config(){
        return this.cycle_config_id && this.cycle_configs.length > 0 ? this.cycle_configs.find(c=>c.id == this.cycle_config_id) : null
      },
      disableMassButton(){
        return !!(
          !this.school_id 
          || !this.grade_id 
          || !this.cycle_config_id 
          || !this.school_cycle_grade_config 
          || (!!this.school_cycle_grade_config && (this.school_cycle_grade_config.is_generating_modules || this.school_cycle_grade_config.count_students_without_modules == 0 || this.school_cycle_grade_config.count_students_without_themes > 0)))
      }
    },
    watch: {
      filters: {
        handler(){
          clearTimeout(this._timerId)
          this._timerId = setTimeout(() => {
            if(this.school_id && this.cycle_config_id && this.grade_id){
              this.$emit('input', this.filters)
              this.getSchoolCycleGradeConfigs({school_id: this.school_id, params: `?&grade_id=${this.grade_id}&cycle_config_id=${this.cycle_config_id}`})
            }
          }, 1000)
        },
        immediate: true,
    }
    },
    mounted(){
      if(this.schools.length == 0 ) {
        this.getSchools('&order_by=name&order_direction=asc&platformId=4');
      }
      if(this.grades.length == 0 ) {
        this.getGrades();
      }
      if(this.cycle_configs.length == 0 ) {
        this.getCycleConfigs('&year=2024');
      }
    },
    methods:{
        ...mapActions(['getSchools','getCycleConfigs','getGrades','getSchoolCycleGradeConfigs']),
        ...mapMutations(['SET_SCHOOL_CYCLE_GRADE_CONFIG']),
        setupModulesForStudents()
        {

          this.$confirm(`
            Essa ação vai iniciar o processo de atribuição de todos os módulos de desempenho para alunos que ainda não tem módulos de desempenho 
            na ${this.selected_grade.name} no ${this.selected_cycle_config.cycle_name} na escola ${this.school.name}.
            Durante o processo não será possível atribuir módulos de desempenho individualmente para a serie e ciclo selecionado.
          `, 'Warning', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.$loading({
            text: 'Solicitando geração de módulos.'
          })
          axios.post(`/admin/maintenance/setup-modules-for-students`, {
            grade_id: this.grade_id,
            cycle_config_id: this.cycle_config_id,
            school_id: this.school_id,
          })
          .then(() => {
            this.SET_SCHOOL_CYCLE_GRADE_CONFIG({
                ...this.school_cycle_grade_config,
                is_generating_modules: true,
            })
            this.$message({
              message: 'Solicitação realizada com sucesso.',
              type: 'success'
            })
          })
          .catch(() => {
            this.$message({
              message: 'Erro ao processar solicitação',
              type: 'error'
            })
          })
          .finally(() => {
            this.$loading().close();
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
          
        }
    },
}
</script>
