<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="file && file.id ? 'Editar arquivo' : 'Cadastrar arquivo'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getFiles(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <el-row :gutter="4">
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
        </el-row>

        <List
          :deleted-file-id="deletedFileId"
          @changed="getFiles(filters); onCancel()"
        >
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-file')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                v-if="permissions.includes('delete-file')"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="onDelete(row)"
              >
                Apagar
              </el-button>
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/file/components/List"
import Register from "@/admin/Pages/file/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
          deletedFileId: -1,
        }
    },
    computed:{
      ...mapGetters(['permissions','file']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getFiles(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getFiles','getFile', 'deleteFile']),
        ...mapMutations(['SET_FILE']),
        onEdit(page){
          this.registering = true;
          this.SET_FILE(page)
        },
        onCreate(){
            this.SET_FILE({
              title: '',
              description: '',
              file: '',
            });
            this.registering = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_FILE(null);
        },
        onDelete(file){
          this.$confirm('Tem certeza que deseja apagar o arquivo ?', 'Atenção', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning'
          })
           .then(() => {
          this.deletedFileId = file.id;
          return this.deleteFile(file);
        })
            .then(() => {
            this.$message({
            message: 'Arquivo excluído com sucesso! ',
            type: 'success',
          });
          this.$emit('changed')
        })
          .catch(() => {
            this.$message({
                type: 'info',
                message: 'Arquivo não foi removido!'
            });
          });
        },
    },
}
</script>
