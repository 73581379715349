<template>
  <div class="mx--4">
    <el-skeleton
      :loading="isLoadingTickets && tickets.length === 0"
      :rows="10"
      animated
    >
      <el-table
        :data="tickets"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <Details :ticket="props.row" />
          </template>
        </el-table-column>

        <el-table-column
          label="Título do conteúdo"
          prop="title"
        />

        <el-table-column
          label="Mensagem"
          prop="message"
        />

        <el-table-column
          label="Dispositivo"
          prop="device"
        >
          <template slot-scope="{row}">
            {{ row.device ? row.device : 'Não identificado' }}
          </template>
        </el-table-column>

        <el-table-column
          label="Tipo"
          width="70"
          prop="ticketable_type"
        />

        <el-table-column
          label="Status"
          width="100"
        >
          <template slot-scope="props">
            <el-tag
              :type="props.row.solved ? 'success' : 'danger'"
              size="mini"
            >
              {{ props.row.solved ? 'Concluído' : 'Em análise' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="Ações"
          width="400"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Details from "../../ticket/components/Details";

export default {
  components: {Details},
  computed: {
    ...mapGetters(['tickets','isLoadingTickets']),
  },
}
</script>

