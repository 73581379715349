import moment from 'moment';
export default {
    state: {
        list: [],
        current: null,
        isLoadingCycleConfigs: false,
    },
    actions: {
        createCycleConfig({commit}, params) {
            return axios.post(`/admin/cycle-config/`, params.data);
        },
        getCycleConfigs({commit}, params = ''){
          return axios.get(`/admin/cycle-config?${params}`)
          .then(response => {
            const  {data: { data : cycle_configs }} = response;
            commit("SET_CYCLE_CONFIGS", cycle_configs.map(c=> {
              c.name = `${c.cycle_name} (${c.year})`
              c.begin_formatted = moment(c.begin).format('DD/MM/YYYY hh:mm')
              c.end_formatted = moment(c.end).format('DD/MM/YYYY hh:mm')
              return c
            }))
            return response
          })
        },
        getCycleConfig({commit}, params) {
            return axios.get(`/admin/cycle-config/${params.data.id}`).then(r => {
                commit("SET_CYCLE_CONFIG", r.data.data);
            });
        },
        updateCycleConfig({commit}, params) {
            return axios.put(`/admin/cycle-config/${params.id}`, params.data);
        },
        deleteCycleConfig({commit}, params){
            return axios.delete(`/admin/cycle-config/${params.id}`);
        }
    },
    mutations: {
        SET_CYCLE_CONFIGS(state, payload) {
          state.list = payload;
        },
        SET_CYCLE_CONFIG(state, payload) {
            state.current = payload;
        }
    },
    getters:{
      cycle_configs: state => state.list,
      cycle_config: state => state.current,
      isLoadingCycleConfigs: state => state.isLoadingCycleConfigs
    }
};
