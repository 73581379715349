<template>
  <div class="d-flex">
    <el-select
      v-model="answer"
      size="mini"
      :disabled="!editing"
      style="width:100px"
    >
      <el-option
        v-for="item in options"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
    <el-button 
      :type="editing ? 'primary':'warning'"
      :disabled="saving"
      size="mini"
      :icon="saving ? 'el-icon-loading' : editing ? 'fa fa-save':'el-icon-edit'"
      @click="editing ? save() : editing = true"
    />
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
    props: {
        challengeQuestion: {
            type: Object,
            required: true,
        }
    },
    data(){
        return {
            answer: '',
            editing: false,
            options:['a','b', 'c','d','e'],
            saving: false,
        }
    },
    computed:{
        ...mapGetters(['challenges']),
    },
    mounted() {
      if (this.challengeQuestion.user_answer) {
          this.answer = this.challengeQuestion.user_answer.toLowerCase();
      } else {
          this.answer = '';
      }
    },
    methods:{
        ...mapMutations(['SET_CHALLENGES']),
        save(){
            this.saving = true;
             axios
             .put(`/admin/challenge/${this.challengeQuestion.challenge_question_id}`, {
                answer: this.answer
             })
             .then(({data: { data: challenge }})=>{
              this.$message({
                    message: 'Resposta editada com sucesso',
                    type: 'success'
                })
                this.editing = false;
                this.$store.state.challenge.list.forEach(c=> {
                  if(c.id == challenge.id){
                    c.questions.forEach(cq=> {
                      if(cq.challenge_question_id == this.challengeQuestion.challenge_question_id) {
                        cq.user_answer = _.clone(this.answer)
                      } 
                      return cq
                    })
                  }
                  return c;
                })
            })
            .catch(()=>{
              this.$message({
                  message: 'Erro ao alterar resposta',
                  type: 'error'
              })
            })
            .finally(()=> {this.saving = false
            })
        }
    },
}
</script>