<template>
  <div class="mx--4">
    <el-skeleton
      :loading="isLoadingProcessPackages && process_packages.length === 0"
      :rows="10"
      animated
    >
      <el-table
        :data="process_packages"
        style="width: 100%"
      >
        <el-table-column 
          type="expand"
        >
          <template slot-scope="props">
            <Details :process="props.row.process" />
          </template>
        </el-table-column>
        <el-table-column
          label="Id Escola"
          prop="school_id"
          width="100"
        />
        <el-table-column
          label="Escola"
          prop="school_name"
          width="350"
        />
        <el-table-column
          label="Série"
          prop="grade_name"
        />
        <el-table-column
          v-if="process_packages.length && process_packages[0].platformId == 4"
          label="Ciclo"
          prop="cycle_name"
          width="100"
        />
        <el-table-column
          label="Id do pacote"
          prop="id"
          width="150"
        />
        <el-table-column
          label="Status"
          prop="status"
        />
        <el-table-column
          label="Alunos"
          prop="count_process"
          width="100"
        />
        <el-table-column
          label="Data criação"
          prop="created_at"
        >
          <template slot-scope="{row}">
            {{ $moment(row.created_at).format('DD/MM/YY HH:mm') }}
          </template>
        </el-table-column>
        
        <el-table-column
          fixed="right"
          label="Ações"
          prop="actions"
        >
          <template slot-scope="{row}">
            <BtnDeletePackage 
              v-if="row.platformId == 4"
              :process-package="row"
            />
            <BtnRestartPackage 
              v-else
              :process-package="row"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BtnDeletePackage from './BtnDeletePackage.vue';
import BtnRestartPackage from './BtnRestartPackage.vue';
import Details from './Details.vue';

export default {
  components:{ BtnDeletePackage, Details, BtnRestartPackage },
  computed: {
    ...mapGetters(['process_packages','isLoadingProcessPackages','process_package_selection_list','permissions']),
  },
}
</script>

