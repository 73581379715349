<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />
        <div>
          <el-dialog
            title="Editar alerta"
            :visible.sync="editing"
            center
          >
            <Register
              v-if="warning && editing"
              :warning="warning"
              @onSave="getWarnings(); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
          <el-dialog
            title="Nova alerta"
            :visible.sync="creating"
            center
          >
            <Register
              @onSave="getWarnings(filters); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
        </div>

        <List @changed="getWarnings(filters)">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-warning')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/warning/components/List"
import Register from "@/admin/Pages/warning/components/Register.vue"
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          editing: false,
          showing: false,
          creating: false,
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','warning']),
      filters(){
        return `&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getWarnings(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      }
    },
    methods:{
        ...mapActions(['getWarnings']),
        ...mapMutations(['SET_WARNING']),
        onEdit(warning){
          this.SET_WARNING(_.clone(warning));
          this.editing = true;
        },
        onCreate(){
          this.SET_WARNING(null);
          this.creating = true;
        },
        onCancel(){
          this.editing = false;
          this.creating = false;
          this.SET_WARNING(null);
        },
    },
}
</script>
