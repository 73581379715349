export default {
    state: {
        list: [],
        current: null
    },
    actions: {
        getSubtopics({ commit }) {
            return axios.get("/admin/subtopic").then(r => {
                commit("SET_SUBTOPICS", r.data.data);
            });
        },
    },
    mutations: {
        SET_SUBTOPICS(state, payload) {
            state.list = payload;
        },
    },
    getters: {
      subtopics: state => state.list,
    }
};
