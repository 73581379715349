<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
        >
          <template #right>
            <el-button
              type="primary"
              :loading="is_exporting"
              @click="exportData()"
            >
              Baixar relatório <i class="fa fa-download" />
            </el-button>
          </template>  
        </PageHeader>
        <el-row class="mx-4">
          <el-col :md="6">
            <el-input
              v-model="search"
              clearable
              :disabled="is_loading"
              suffix-icon="el-icon-search"
              placeholder="Pesquisar por Nome, ID Evolucional ou ID Nitro"
            />
          </el-col>
          <el-col
            :md="12"
            class="p-2"
          >
            <el-radio-group v-model="active_students">
              <el-radio label="has-active">
                Ativos
              </el-radio>
              <el-radio label="has-not-active">
                Inativos
              </el-radio>
              <el-radio label="">
                Todos
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-skeleton
          :rows="10"
          :loading="is_loading"
          animated
          class="mx--4"
        >
          <el-table
            :data="schools"
            style="width: 100%"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="p-4">
                  <div
                    v-for="cycle in props.row.cycles"
                    :key="cycle.cycle_order"
                  >
                    <p>{{ `Desafios no ciclo ${cycle.cycle_order}:` }}  : {{ cycle.count_challenges }}</p>
                    <p>{{ `Alunos selecionaram módulos no ciclo ${cycle.cycle_order}:` }}  : {{ cycle.count_students_selected_modules }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="ID Nitro"
              prop="id"
              width="80"
            />
            <el-table-column
              label="ID Evolucional"
              prop="schoolid"
              width="120"
            />
            <el-table-column
              label="Nome"
              prop="name"
            />
            <el-table-column
              label="Total alunos"
              prop="count_students"
              width="130"
            />
            <el-table-column
              label="Alunos ativos"
              prop="count_active_students"
              width="130"
            />
            <el-table-column
              label="Alunos inativos"
              prop="count_deactivated_students"
              width="130"
            />
          </el-table>
          <div class="d-flex justify-content-center p-2">
            <el-pagination
              background
              :page-sizes="[10, 30, 50, 100, 200, 500]"
              layout="total,sizes, prev, pager, next"
              :total="meta.total"
              :current-page="meta.current_page"
              :page-size.sync="meta.per_page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-skeleton>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import downloadFile from "@/utils/downloadFile.js"

export default{
    data() {
        return {
          is_loading: true,
          is_exporting: false,
          search: '',
          active_students: 'has-active',
          meta : {
            current_page: 1,
            total: 0,
            per_page: 50
          },
          schools: [],
        }
    },
    computed:{
      filters() {
        return `&search=${this.search}&page=${this.meta.current_page}&per_page=${this.meta.per_page}&${this.active_students ? `&${this.active_students}`:''}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
              this.is_loading= true;
              this.getSchoolCycleReports();
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
      handleCurrentChange(page) {
        this.meta.current_page = page;
        this.is_loading = true;
        this.getSchoolCycleReports();
      },
      getSchoolCycleReports(){
        axios.get(`/admin/report/school-cycle?${this.filters}`)
        .then(({data: { data: schools , meta }}) => {
          this.schools = schools;
          this.meta.per_page = Number(meta.per_page);
          this.meta.current_page = meta.current_page;
          this.meta.total = meta.total;
          this.is_loading = false;
        })
      },
      exportData(){
        if(this.is_exporting){
          this.$message({ message: 'Em download, aguarde.', type: 'warning', showClose: true})
        } else {
          this.is_exporting = true;
          downloadFile(`/admin/report/school-cycle/export?${this.filters}`, this.$moment() + "-relatorio-escola-ciclo.xlsx")
          .finally(() => this.is_exporting = false)
        }
      }
    },
}
</script>
