export default {
    state: {
        list: [],
        current: null,
        isLoadingWarnings: false,
    },
    actions: {
        getWarnings({ commit, state }, filters) {
            state.isLoadingWarnings = true
            return axios.get(`/admin/warning?${filters}`).then(r => {
                commit("SET_WARNINGS", r.data.data);
                return r
            })
                .finally(()=>state.isLoadingWarnings = false);
        },
        getWarning({commit}, params) {
            return axios.get(`/admin/warning/${params.data.id}`)
            .then(r => {
                commit("SET_WARNING", r.data.data);
                return r;
            });
        },
        createWarning({commit}, params) {
            return axios.post(`/admin/warning/`, params.data);
        },
        updateWarning({commit}, params) {
            return axios.put(`/admin/warning/${params.id}`, params.data);
        },
        deleteWarning({commit}, {id}){
            return axios.delete(`/admin/warning/${id}`);
        }
    },
    mutations: {
        SET_WARNINGS(state, payload) {
            state.list = payload;
        },
        SET_WARNING(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        warnings: state => state.list,
        warning: state => state.current,
        isLoadingWarnings: state => state.isLoadingWarnings,
    }
  };
  