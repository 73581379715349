<template>
  <div class="px--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingVideos && videos.length == 0"
      animated
    >
      <el-table
        :data="videos"
        style="width: 100%"
        row-key="id"
      >
        <el-table-column
          label="Id"
          prop="id"
          width="80"
        />
        <el-table-column
          label="Título"
          prop="title"
        />
        <el-table-column
          label="Módulo"
          prop="module_name"
        />
        <el-table-column
          label="Tipo de módulo"
          prop="module_type_text"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="320"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name:'VideoListComponent',
  computed:{
    ...mapGetters(['videos', 'isLoadingVideos']),
  },
}
</script>

