<template>
  <el-skeleton
    :rows="10"
    :loading="isLoadingThemeBlocks"
    animated
  >
    <el-table
      :data="theme_blocks"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="p-4">
            <Detail :theme-block="props.row" />
            <el-button-group>
              <el-button
                v-if="permissions.includes('delete-block')"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="onDelete(props.row)"
              >
                Apagar
              </el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Id"
        prop="id"
        width="80"
      />
      <el-table-column
        label="Nome"
        prop="name"
      />
      <el-table-column
        label="Descrição"
        prop="description"
      />
      <el-table-column
        label="Progresso"
        prop="cycle_percentage_completion"
      >
        <template slot-scope="props">
          <el-progress 
            :percentage="props.row.cycle_percentage_completion"
            :status="props.row.cycle_percentage_completion >= 100 ? 'success' : 'warning'"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Módulos"
        prop="count_themes"
        width="100"
      >
        <template slot-scope="{ row }">
          {{ row.count_themes }} / 4
        </template>
      </el-table-column>
      
      <el-table-column
        label="Área de interesse"
        prop="trail.name"
      />
      
      <el-table-column
        fixed="right"
        label="Ações"
        width="220"
      >
        <template slot-scope="{ row }">
          <slot :row="row" />
        </template>
      </el-table-column>
    </el-table>
  </el-skeleton>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Detail from "@/admin/Pages/theme_block/components/Detail"
export default {
  components: { Detail },
  computed:{
    ...mapGetters(['theme_blocks','isLoadingThemeBlocks','permissions'])
  },
  methods:{
    ...mapActions(['deleteThemeBlock']),
    onDelete(theme_block){
      this.$confirm('Tem certeza que deseja apagar o bloco temático ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteThemeBlock(theme_block).then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

