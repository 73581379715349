export default {
  name: [
    { required: true, message: 'O nome é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O nome deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  description: [
    { max: 190, message: 'O campo descrição deve ter no máximo 190 caracteres.', trigger: 'blur' }
  ],
  file: [
    { required: true, message: 'O arquivo é obrigatório.', trigger: 'blur' },
  ],
}
