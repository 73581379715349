import { Message } from 'element-ui';

export default {
    state: {
        list: [],
        isLoadingUsers: false,
    },
    actions: {
        getRepeatedUsers({commit, state}, params = '') {
            state.isLoadingUsers = true;
            return axios.get(`/admin/maintenance/repeated-users?${params}`)
            .then(r => {
                commit("SET_REPEATED_USERS", r.data.data);
                return r;
            })
                .finally(() => state.isLoadingUsers = false);
        },
        deleteRepeatedUser(state, { user_id }) {
            return axios.delete(`/admin/maintenance/repeated-users/${user_id}`)
            .then(r => {
                Message({
                    type: 'success',
                    message: 'Usuário apagado com sucesso.'
                });
                return r
            })
            .catch(() => {
                Message({
                    type: 'danger',
                    message: 'Erro ao apagar usuário.'
                });
            })
        },
    },
    mutations: {
        SET_REPEATED_USERS(state, payload) {
            state.list = payload;
        },
    },
    getters: {
        repeatedUsers: state => state.list,
        isLoadingRepeatedUsers: state => state.isLoadingUsers
    }

};
