import ptBrLocale from "element-ui/lib/locale/lang/pt-br";

/* TODO
* A IDEIA É QUE CASO AUMENTE MUITO, ESSAS TRADUÇÕES POSSAM VIR DO SERVIDOR SEJAM
* E SEREM GUARDADAS DE FORMA DINÂMICA OU ESTÁTICA.
* */
export const messages = {
    ptBr: {
        fields: {
            account: 'conta',
            user: 'usuário',
            quiz: 'questionário',
            address: 'endereço',
        },
        words: {
            next: 'próximo',
            previous: 'anterior',
            back: 'voltar',
            ambassador: 'embaixador | embaixadores',
            member: 'membro | membros',
            admin: 'administrador | administradores',
            yes: 'sim',
            no: 'não',
            approve: 'aprovar',
            approved: 'aprovado',
            not_approved: 'Não aprovados',
            all: 'todos',
            featured: 'destacado | destacados',
            not_featured: 'sem destaque',
            activated: 'ativado',
            deactivated: 'desativado',
            name: 'nome',
            guest: 'convidado | convidados',
            date: 'data',
            action: 'ação | ações',
            delete: 'apagar',
            registration: 'matrícula',
            degree: 'formação',
            company: 'empresa | empresas',
            role: 'função | funções',
            administrator: 'administrador | administradores',
            cancel: 'cancelar',
            save: 'salvar',
            profile: 'perfil',
            exit: 'sair',
            program: 'programa | programas',
            draft: 'rascunho | rascunhos',
            new: 'novo',
            resume: 'resumo',
            group: 'grupo | grupos',
            cycle: 'ciclo | ciclos',
            video: 'vídeo | vídeos',
            schedule: 'cronograma | cronogramas',
            gallery: 'galeria | galerias',
            file: 'arquivo | arquivos',
            financialAnalytics: 'Análise financeira',
            implementation: 'implementação',
            author: 'autor',
            participant: 'participante | participantes',
            undefined: 'indefinida',
            category: 'categoria | categorias',
            project: 'projeto | projetos',
            about: 'sobre',
            subscription: 'inscrição | inscrições',
            media: 'mídia | mídias',
            news: 'notícias',
            password: 'senha',
            apply: 'aplicar',
            edit: 'editar',
            search: 'pesquisar',
            gender: 'gênero',
            birthdate: 'data de nascimento',
            shirt_size: 'tamanho da camisa',
            telephone: 'telefone',
            work_state: 'estado de trabalho',
            work_city: 'cidade de trabalho',
            address: 'rua',
            number: 'número',
            complement: 'complemento',
            neighborhood: 'bairro',
            state: 'estado',
            city: 'cidade'
        },
        messages: {
            defaultError500: 'Erro no servidor, tente novamente mais tarde ou entre em contato com o suporte.',
            defaultError422: 'Erro de validação, verifique se tudo foi preenchido corretamente e tente novamente.',
            defaultError403: 'Ação não autorizada.',
            defaultError405: 'Erro no servidor, método não permitido',
            defaultLoading: 'Carregando ...',
            defaultLoadingSaving: 'Enviando ...',
            defaultLoadingDeleting: 'Apagando ...',
            defaultLoadingSuccess: 'Sucesso ...',
            defaultLoadingError: 'Erro ...',
            defaultErrorRequest: 'Erro na requisição.',
            defaultItemCreateTitle: 'Cadastro realizado com sucesso.',
            defaultItemUpdated: 'Cadastro editado com sucesso.',
            defaultDeleteItemQuestion: 'Tem certeza que deseja apagar o item?',
            defaultUpdateItemQuestion: 'Tem certeza que deseja alterar o item?',
            defaultItemUpdateTitle:"Item atualizado com sucesso",
            defaultDeleteItemSuccess: 'Item apagado com sucesso.',
            registerAmbassador: "Seu cadastro foi realizado com sucesso porém a aprovação encontra-se em análise.",
            createAmbassadorSuccess: "",
            cancelAction: "não, obrigado.",
            actionCanceled: 'ação cancelada.',
            /*EMBAIXADORES*/
            confirmAmbassadorApprove: "Tem certeza que deseja aprovar esse cadastro de embaixador?",
            approveAmbassadorInfo: "Clique para aprovar esse embaixador",
            holdingApprove: "Aguardando aprovação",
            ambassadorApproved: "Cadastro de embaixador aprovado com sucesso.",
            errorActivateCycle: "Erro ao mudar o status do ciclo",
            successActivatedCycle: "Ciclo ativado com sucesso.",
            successDeactivatedCycle: "Ciclo desativado com sucesso.",
            personalData: 'dados pessoais',
            /*PROJECTS*/
            myProjects: 'meus projetos',
            approveProjectQuestion: 'Tem certeza que deseja aprovar esse projeto?',
            toggleProjectActiveQuestion: 'Tem certeza que deseja alterar a visibilidade do projeto ?',
            projectChangeStatusSuccess: 'Status do projeto atualizado com sucesso.',
            beAmbassador: 'seja um embaixador',
            changePassword: 'alterar senha'
        },
        ...ptBrLocale,
    },
    en: {
        fields: {
            account: 'account',
            user: 'user',
            quiz: 'quiz',
            address: 'address',
        },
        words: {
            next: 'next',
            previous: 'previous',
            back: 'back',
            ambassador: 'ambassador | ambassadors',
            member: 'member | members',
            admin: 'admin | admins',
            yes: 'yes',
            no: 'no',
            approve: 'approve',
            approved: 'approved',
            not_approved: 'not approved',
            all: 'all',
            featured: 'featured | featured',
            not_featured: 'not featured',
            activated: 'activated',
            deactivated: 'deactivated',
            name: 'name',
            guest: 'guest | guests',
            date: 'date',
            action: 'action | actions',
            delete: 'delete',
            registration: 'registration',
            degree: 'degree',
            company: 'company | companies',
            role: 'role | roles',
            administrator: 'administrator | administrators',
            cancel: 'cancel',
            save: 'save',
            profile: 'profile',
            exit: 'exit',
            program: 'program | programs',
            draft: 'draft | drafts',
            new: 'new',
            resume: 'resume',
            group: 'group | groups',
            cycle: 'cycle | cycles',
            video: 'video | videos',
            schedule: 'schedule | schedules',
            gallery: 'gallery | galleries',
            file: 'file | files',

            implementation: 'implementation',
            author: 'author',
            participant: 'participant | participants',
            undefined: 'undefined',
            category: 'category | categories',
            project: 'project | projects',
            about: 'about',
            subscription: 'subscription | subscriptions',
            media: 'media | medias',
            news: 'news',
            password: 'password',
            apply: 'apply',
            edit: 'edit',
            search: 'search',
            gender: 'gender',
            birthdate: 'birthdate',
            shirt_size: 'shirt size',
            telephone: 'telephone',
            work_state: 'work state',
            work_city: 'work city',
            address: 'address',
            number: 'number',
            complement: 'complement',
            neighborhood: 'neighborhood',
            direction: 'direction',
            specialties: 'specialties',
            state: 'state',
            city: 'city'
        },
        messages: {
            defaultError500: 'Server errors, please try again later ou contact our support',
            defaultError422: 'Validation error, please check if your answer correctly all fields and try again.',
            defaultError403: 'This action was unauthorized.',
            defaultError405: 'Server error, method not allowed',
            defaultLoading: 'Loading ...',
            defaultLoadingSaving: 'Sending ...',
            defaultLoadingDeleting: 'Deleting ...',
            defaultLoadingSuccess: 'Success ...',
            defaultLoadingError: 'Errors ...',
            defaultErrorRequest: 'Requisition error.',
            defaultItemCreateTitle: 'Success, item created.',
            defaultItemUpdated: 'Success, item saved.',
            defaultDeleteItemQuestion: 'Are you sure you want to delete this item?',
            defaultUpdateItemQuestion: 'Are you sure you want to update this item?',
            defaultItemUpdateTitle:"Item successfully updated",
            defaultDeleteItemSuccess: 'Item successfully deleted.',
            registerAmbassador: "Your register was successfully done, and now is in analysis.",
            createAmbassadorSuccess: "",
            cancelAction: "no, thanks.",
            actionCanceled: 'action canceled.',
            /*EMBAIXADORES*/
            confirmAmbassadorApprove: "Are you sure you want to approve this ambassador registration?",
            approveAmbassadorInfo: "Click to approve this ambassador registration.",
            holdingApprove: "waiting for approval",
            ambassadorApproved: "Ambassador registration successfully approved.",
            errorActivateCycle: "Error on change cycle state",
            successActivatedCycle: "Cycle has been activated",
            successDeactivatedCycle: "Cycle has been deactivated",
            personalData: 'personal data',
            /*PROJECTS*/
            myProjects: 'my projects',
            approveProjectQuestion: 'Are you sure you want to approved this project?',
            toggleProjectActiveQuestion: 'Are you sure you want to change project visibility?',
            projectChangeStatusSuccess: 'Project status successfully updated',
            beAmbassador: 'be an ambassador',
            changePassword: 'change password'
        },
        ...ptBrLocale,
    }
};

export default messages;
