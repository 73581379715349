export default {
  name: [
      {
          required: true,
          message: 'O campo descrição é obrigatório',
      }
  ],
  email: [
      {
          required: true,
          message: 'O campo email é obrigatório'
      },
      {
          type: 'email', 
          message: 'Insira um endereço de e-mail válido', 
      },
  ],
  role_id: [
      {
          required: true,
          message: 'O campo tipo de usuário é obrigatório'
      }
  ]
}