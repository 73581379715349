<template>
  <el-card
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="15"
          :lg="15"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            TOTAL DE ALUNOS QUE REALIZOU O DESAFIO ATUAL E O ANTERIOR
          </p>
          <p class="mb-0 font-weight-light text-sm">
            Alunos que realizaram o desafio do ciclo atual e do ciclo anterior
          </p>
        </el-col>
        <el-col
          :md="9"
          :lg="9"
        >
          <el-button-group>
            <el-button
              size="mini"
              :type="avg ? 'primary' : 'default'"
              @click="avg = true"
            >
              Porcentagem
            </el-button>
            <el-button
              size="mini"
              :type="!avg ? 'primary' : 'default'"
              @click="avg = false"
            >
              Total somado
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <div class="p-3">
      <el-row
        v-for="(cycle, key) in count_students_with_finished_challenge_in_current_and_next_cycle_list"
        :key="key"
        :gutter="10"
      >
        <el-col :span="3">
          <el-tooltip
            :content="`${cycle.begin_formatted} - ${cycle.end_formatted}`"
            placement="top"
            effect="light"
          >
            <span class="pointer-on-hover font-weight-bold">Ciclo {{ cycle.cycle_order }}</span>
          </el-tooltip>
        </el-col>
        <el-col
          :span="17"
          class="pt-1"
        >
          <el-progress
            :text-inside="false"
            :show-text="false"
            :stroke-width="16"
            :percentage="avg ? cycle.percentage : getPercentage(cycle.count_students_finished_all, greater_percentage)"
          />
        </el-col>
        <el-col :span="4">
          <p class="text-right">
            <span v-if="avg">
              <el-tooltip
                :content="`Porcentagem de alunos que concluíram desafios no ciclo ${key+1} e ${key+2}`"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ Number(cycle.percentage).toFixed(2) }}</span>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                content="Alunos ativos fizeram os desafios"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_students_finished_all }}</span>
              </el-tooltip> /
              <el-tooltip
                content="Total de alunos ativos"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_active_students }}</span>
              </el-tooltip>
            </span>
          </p>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>


import { mapActions, mapGetters } from 'vuex';
import getPercentage from '@/admin/utils/getPercentageGreater'


export default {
  data(){
    return {
      avg: true,
    }
  },

  computed: {
    ...mapGetters(['count_students_with_finished_challenge_in_current_and_next_cycle_list']),
    greater_percentage() {
      return _.orderBy(this.count_students_with_finished_challenge_in_current_and_next_cycle_list, [this.avg ? 'percentage' : 'count_students_finished_all' ], ['desc'])[0][this.avg ? 'percentage' : 'count_students_finished_all' ];
    },
  },
  created(){
    if(this.count_students_with_finished_challenge_in_current_and_next_cycle_list.length == 0) {
      this.getCountCountStudentsWithChallengeInCurrentAndNextCycle()
    }
  },
    methods: {
    ...mapActions(['getCountCountStudentsWithChallengeInCurrentAndNextCycle']),
    getPercentage,
  },

  }
  </script>

