import moment from "moment";

export default {
    state: {
        list: [],
        is_loading_count_schools_without_modules_on_cycle: false
    },
    actions: {
        getCountSchoolsWithoutModulesOnCycle({commit, state}) {
            state.is_loading_count_schools_without_modules_on_cycle = true
            return axios.get(`/admin/report/count-schools-without-modules-on-cycle`)
            .then(response => {1

              commit('SET_COUNT_SCHOOLS_WITHOUT_MODULES_ON_CYCLE', response.data.data.map(c => ({
                ...c,
                begin_formatted : moment(c.begin).format('DD/MM/YYYY hh:mm'),
                end_formatted: moment(c.end).format('DD/MM/YYYY hh:mm'),
              })))

              return response

            })
            .finally(()=> state.is_loading_count_schools_without_modules_on_cycle = true);
        },

    },
    mutations: {
        SET_COUNT_SCHOOLS_WITHOUT_MODULES_ON_CYCLE(state, payload) {
            state.list = payload;
        },
    },
    getters: {
        count_schools_without_modules_on_cycle_list: state => state.list,
        is_loading_count_schools_without_modules_on_cycle: state => state.isLoadingGlobalCycleProgress,
    }
};
